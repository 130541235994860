import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import PostAddIcon from '@material-ui/icons/PostAdd';

import MyPaper from '../../Display/MyPaper';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    buttonContainer: {
      paddingTop: 15,
      paddingBottom: 15,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    button: {
      background: theme.secondaryColor,
      color: '#fff',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 'bold',
      boxShadow: theme.boxShadow,
    },
  };
});

const Tab6 = (props) => {
  const batch = props.batch || {};
  const classes = useStyles();
  return (
    <>
      <div className={classes.buttonContainer}>
        <Button href="/dashboard/add-paper" className={classes.button} startIcon={<PostAddIcon />}>
          Create New Paper
        </Button>
      </div>
      <MyPaper
        batch={batch._id}
        users={batch.users.map((obj) => { return obj._id; })}
        standard={batch.standard?._id || batch.standard}
      />
    </>
  );
};

export default Tab6;
