import React from 'react';

import Grid from '@material-ui/core/Grid';

import ContentEditor from '../../Editor/ContentEditor';
import AppButton from '../../App/AppButton';

// eslint-disable-next-line import/no-cycle
import ShowFunctions from '../Display/ShowFunction';

import { getCombineValue } from '../../../utils/util';

const Metrics = (props) => {
  const {
    handleChange, name, closeModal, row, column, getHTML,
  } = props;
  const [data, setData] = React.useState({});
  const [focusedElement, setFocusedElement] = React.useState('');
  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const initialize = () => {
    setData((prevData) => {
      const newData = {};
      for (let i = 1; i <= row; i += 1) {
        for (let j = 1; j <= column; j += 1) {
          const key = `${i}${j}`;
          newData[key] = prevData[key] || '';
        }
      }
      return newData;
    });
    if (!focusedElement) setFocusedElement('11');
  };

  const handleContentChange = (event) => {
    const dataName = event.target.name;
    const value = event.target.value;
    setData((preData) => {
      const newData = { ...preData };
      newData[dataName] = value || '';
      return newData;
    });
  };

  const handleSubmit = () => {
    const value = getHTML(data);
    closeModal();
    handleChange({ target: { name, value } });
  };

  const setFocus = (elementName) => {
    setFocusedElement(elementName);
  };

  const getData = (event) => {
    const elName = event.target.name;
    const value = event.target.value;
    setData((prevData) => {
      const newElement = getCombineValue(prevData[elName], value, position);
      return { ...prevData, [elName]: newElement };
    });
  };

  React.useEffect(() => {
    if (row && column) initialize();
    // eslint-disable-next-line
  }, [row, column]);

  return (
    <>
      {
        row && column
          ? (
            <>
              <ShowFunctions handleChange={getData} name={focusedElement} />
              {
                new Array(row).fill(null).map((rnv, rindex) => {
                  return (
                  // eslint-disable-next-line react/no-array-index-key
                    <Grid key={rindex} container>
                      {
                        new Array(column).fill(null).map((cnv, cindex) => {
                          const key = `${rindex + 1}${cindex + 1}`;
                          const factor = Math.floor(12 / column);
                          return (
                            <Grid key={key} item xs={factor} sm={factor} md={factor}>
                              <ContentEditor
                                name={key}
                                html={data[key]}
                                handleChange={handleContentChange}
                                setParentFocus={setFocus}
                                setPosition={setPosition}
                              />
                            </Grid>
                          );
                        })
                        }
                    </Grid>
                  );
                })
            }
              <Grid container justifyContent="center">
                <AppButton onClick={handleSubmit} />
              </Grid>
            </>
          ) : null
      }
    </>
  );
};

export default Metrics;
