const initialState = {
};

export default function exampaperReducer(currentState = initialState, action) {
  switch (action.type) {
    case 'INSERT_SNACK_DATA':
      return { ...currentState, data: action.payload.data };
    default:
      return currentState;
  }
}
