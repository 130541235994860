import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      background: theme.secondaryColor,
      color: '#fff',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 'bold',
      boxShadow: theme.boxShadow,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
  };
});

const IconButton = (props) => {
  const {
    onClick, title, disabled, icon, href, dataTour,
  } = props;
  const classes = useStyles();
  const styles = {
    background: 'linear-gradient(103.37deg, #99179F 4.77%, #2068A8 95.46%)',
    color: '#fff',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  };
  return (
    <>
      <Button
        href={href}
        data-tour={dataTour}
        onClick={onClick}
        disabled={disabled || false}
        startIcon={icon}
        className={classes.button}
        style={styles}
      >
        {title || props.children}
      </Button>
    </>
  );
};

export default IconButton;
