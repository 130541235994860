import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

// eslint-disable-next-line import/no-cycle
import RowColEntry from './FunctionModels/RowColEntry';

const TransparentTable = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const [row, setRow] = React.useState('');
  const [column, setColumn] = React.useState('');

  const handleRowChange = (e) => {
    const value = e.target.value;
    setRow((value && parseInt(value, 10)) || '');
  };

  const handleColumnChange = (e) => {
    const value = e.target.value;
    setColumn((value && parseInt(value, 10)) || '');
  };

  const getHTML = (data) => {
    let htmlForParent = `
       <table style="display: inline-table; border: none; vertical-align: middle;"
       cellspacing="0" cellpadding="0">
       <tbody>`;
    for (let i = 1; i <= row; i += 1) {
      htmlForParent = `${htmlForParent} <tr>`;
      for (let j = 1; j <= column; j += 1) {
        htmlForParent = `${htmlForParent}
        <td style="padding: 3px 5px; border: none; text-align: left; vertical-align: top;">
         ${data[`${i}${j}`]}
        </td>`;
      }
      htmlForParent = `${htmlForParent} </tr>`;
    }
    htmlForParent = `${htmlForParent}   </tbody>
   </table>&nbsp;`;

    return htmlForParent;
  };

  return (
    <>
      <Grid container style={{ marginBottom: 30 }} justifyContent="center">
        <Grid container item xs={6} justifyContent="center">
          <TextField
            id="standard-number"
            label="Rows"
            name="row"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            onChange={handleRowChange}
            value={row}
            autoFocus
            placeholder="Enter Row Size"
          />
        </Grid>
        <Grid container item xs={6} justifyContent="center">
          <TextField
            id="standard-number"
            label="Columns"
            name="column"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            onChange={handleColumnChange}
            value={column}
            placeholder="Enter Column Size"
          />
        </Grid>
      </Grid>
      <RowColEntry
        handleChange={handleChange}
        row={row}
        column={column}
        name={name}
        closeModal={closeModal}
        getHTML={getHTML}
      />
    </>
  );
};

export default TransparentTable;
