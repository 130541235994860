import React from 'react';

import Grid from '@material-ui/core/Grid';

import LoginComponent from '../components/Authentication/Login';

const Login = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <LoginComponent />
      </Grid>
    </>
  );
};

export default Login;
