import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../../assets/images/intelliclick-logo.svg';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    innerContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      fontWeight: 'bold',
      fontSize: 12,
      color: '#464E5F',
    },
    link: {
      color: theme.primaryColor,
      fontSize: 12,
      fontWeight: 'bold',
    },
    logo: {
      height: '24px',
      width: 'auto',
      marginRight: '6px',
    },
    table: {
      padding: 0,
      width: '100%',
    },
    tableFooter: {
      height: 0,
      padding: 0,
      '@media print': {
        height: '48px',
      },
    },
    tableBody: {
      width: '100%',
      padding: 0,
    },
  };
});

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <img
            src={logo}
            className={classes.logo}
            alt="logo"
          />
          <span className={classes.text}>Intelliclick</span>
        </div>
        <div>
          <a className={classes.link} href="https://app.intelliclick.in">
            app.intelliclick.in
          </a>
        </div>
      </div>
    </>
  );
};

const PrintPage = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableFooter}>{' '}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tableBody}>{children}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td className={classes.tableFooter}>{' '}</td>
          </tr>
        </tfoot>
      </table>
      <div className="print-footer">
        <Footer />
      </div>
    </>
  );
};

export default PrintPage;
