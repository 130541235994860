import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import OtpInput from 'react-otp-input';
import Grid from '@material-ui/core/Grid';

import Img from '../../assets/images/parth_verify.png';
import AppButton from '../App/AppButton';

const useStyles = makeStyles((theme) => {
  return {
    otpContainer: {
      marginTop: 30,
      marginBottom: 30,
    },
    verify: {
      background: theme.secondaryColor,
      color: '#fff',
      width: 250,
      height: 50,
      fontWeight: 'bold',
      '&:hover': {
        background: theme.secondaryColor,
        color: '#fff',
      },
      marginBottom: 15,
      fontSize: 18,
      borderRadius: 25,
    },
    inputStyle: {
      margin: 15,
      height: 45,
      minWidth: 45,
      fontSize: 18,
      fontWeight: 'bold',
    },
  };
});

const OTP = (props) => {
  const { data, verify } = props;
  const { email, phone } = data || {};
  const classes = useStyles();
  const [otp, setOtp] = React.useState('');

  const handleChange = (value) => { return setOtp(value); };

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     verify('2105');
  //   }, 5000);
  // }, []);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <img src={Img} alt="lock_img" style={{ margin: 15, width: 'auto', height: '100px' }} />
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <p style={{ textAlign: 'center' }}>
          Please enter the verification code
          <br />
          we send to your
          {' '}
          {phone ? 'mobile number' : 'email address'}
          <br />
          <b>{phone || email}</b>
        </p>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" className={classes.otpContainer}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          inputStyle={classes.inputStyle}
          isInputNum
          shouldAutoFocus
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(inProps) => { return <input {...inProps} />; }}
        />
      </Grid>
      <Grid container className={classes.submit} justifyContent="center">
        <AppButton
          // variant="contained"
          // className={classes.verify}
          onClick={() => { return verify(otp); }}
        >
          Verify
        </AppButton>
      </Grid>
    </>
  );
};

export default OTP;
