import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// import PostAddIcon from '@material-ui/icons/PostAdd';

import Invite from "../App/Invite";
// import IconButton from '../App/IconButton';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 8,
        marginRight: 8,
      },
    },
  };
});

const NavigationButton = (props) => {
  const { refreshPage } = props;
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="stretch"
        style={{ marginBottom: 15 }}
      >
        <Grid item style={{ marginBottom: 15 }}>
          {/* <IconButton
            icon={<PostAddIcon />}
            href="/dashboard/add-paper"
            dataTour="create-paper"
            title="Create Paper"
          /> */}
        </Grid>
        <Grid item style={{ marginBottom: 15 }}>
          <Invite userRole="student" refreshPage={refreshPage} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavigationButton;
