import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CollectionsIcon from '@material-ui/icons/Collections';

import Actions from '../../../actions';

import AutoCompleteSelect from '../../App/AutoCompleteSelect';
import Select from '../../Select';
import IconButton from '../../App/IconButton';

import Snack from '../../Progress/Snack';
import { contentValidationSchema } from '../../../utils/util';
import AppButton from '../../App/AppButton';
// import Loader from '../../Progress/Loader';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: 32,
      width: 460,
      padding: 25,
      paddingTop: 10,
      [theme.breakpoints.down('sm')]: {
        width: 360,
      },
      boxShadow: theme.boxShadow,
    },
    submit: {
      marginTop: 30,
      marginBottom: 30,
    },
    heading: {
      fontWeight: 600,
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 15,
    },
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    button: {
      background: theme.primaryColor,
      color: '#fff',
      fontWeight: 'bold',
    },
  };
});

function Step1(props) {
  const {
    standards, loadingStandards, fetchStandards, setContent, handleNext, location, history,
  } = props;
  const classes = useStyles();
  const state = location.state || {};
  const topic = state.topic;

  const initializeData = (obj) => {
    const dataObj = obj || {};
    const standardObj = standards && standards.find((o) => { return o.name === dataObj.standardName; });
    const subjects = (standardObj && standardObj.subjects);
    return {
      standard: dataObj.standard || '',
      standardName: dataObj.standardName || '',
      subject: dataObj.subject || '',
      subjectName: dataObj.subjectName || '',
      subjects: subjects || [],
    };
  };
  const [snack, setSnack] = React.useState({});
  const [data, setData] = React.useState(initializeData());
  const [shouldMoveNext, setShouldMoveNext] = React.useState(false);

  const closeSnack = () => {
    setSnack({});
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleStandardChange = (e, newObj) => {
    const standardName = (newObj && newObj.name) || '';
    setData({
      ...data,
      standard: (newObj && newObj._id) || '',
      standardName,
      subjects: (newObj && newObj.subjects) || [],
    });
  };

  const handleSubmit = () => {
    contentValidationSchema.validate(data)
      .then(() => {
        setContent(data);
        handleNext();
      }).catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  React.useEffect(() => {
    if (!standards && !loadingStandards) fetchStandards();
  }, [standards, fetchStandards, loadingStandards]);

  React.useEffect(() => {
    if (topic) {
      setData(topic);
      setShouldMoveNext(true);
      history.replace({ ...location, state: { ...state, topic: null } });
    }
  }, [topic]);

  React.useEffect(() => {
    if (shouldMoveNext) handleSubmit();
  }, [shouldMoveNext]);

  return (
    <>
      <Snack
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      {/* <Loader
        loading={loading}
      /> */}
      <div className={classes.container}>
        <Grid container justifyContent="center" alignItems="center">
          <Paper elevation={0} className={classes.paper}>
            <Typography
              variant="h6"
              className={classes.heading}
            >
              Select Content Details
            </Typography>
            <AutoCompleteSelect
              title="Standard"
              data={standards}
              name="standard"
              value={data.standard}
              handleChange={handleStandardChange}
            />
            <Select
              title="Subject"
              data={data.subjects}
              name="subjectName"
              value={data.subjectName}
              handleChange={handleChange}
              isArray
            />
            <Grid container justifyContent="center">
              <AppButton
                onClick={handleSubmit}
                title="Next"
              />
            </Grid>
          </Paper>
        </Grid>
        <br />
        <br />
        <Grid container justifyContent="flex-end">
          <IconButton href="/dashboard/all-contents" title="My All Study Materials" icon={<CollectionsIcon />} />
        </Grid>
        <br />
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    subjects: state.subjects.data,
    standards: state.standards.data,
    loadingSubjects: state.subjects.fetching,
    loadingStandards: state.standards.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
