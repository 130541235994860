const initialState = {
  fetching: false,
};

export default function dailyActivityReducer(currentState = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'INSERTING_OR_UPDATING_DAILY_ACTIVITY':
      return { ...currentState, fetching: true };
    case 'INSERTED_OR_UPDATED_DAILY_ACTIVITY':
      return { ...currentState, data: payload, fetching: false };
    case 'FETCHING_DAILY_ACTIVITY':
      return { ...currentState, fetching: true };
    case 'FETCHED_DAILY_ACTIVITY':
      return { ...currentState, data: payload, fetching: false };
    case 'RESET_DAILY_ACTIVITY':
      return { ...currentState, data: null, fetching: false };
    default:
      return currentState;
  }
}
