/* eslint-disable no-console */
/* eslint-disable comma-dangle */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { JitsiMeeting } from "@jitsi/react-sdk";

import { makeStyles } from "@material-ui/core/styles";

import logo from "../assets/images/intelliclick-logo.svg";

import Actions from "../actions";

// const jwt = localStorage.getItem("token_intelliclick");
// subject: data?.name || '',

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      display: "inline",
    },
    container: {
      background: "#fff",
      minHeight: "100vh",
      paddingLeft: 60,
      paddingRight: 60,
      paddingTop: 30,
      paddingBottom: 30,
    },
    headerContainer: {
      paddingBottom: 15,
      marginBottom: 15,
      borderBottom: "1px solid #000",
    },
    subTitle: {
      marginTop: 15,
      marginBottom: 5,
    },
    link: {
      color: theme.primaryColor,
      fontSize: 12,
      fontWeight: "bold",
    },
    meeting: {
      height: "100vh",
    },
  };
});

const PrintPaper = () => {
  const classes = useStyles();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const encodedName = queryParams.get("name");

  const name = encodedName
    ? decodeURIComponent(encodedName.replace(/\+/g, " "))
    : "";

  const { meetingId } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) return <>User Not Found</>;

  return (
    <>
      <div className={classes.meeting}>
        <JitsiMeeting
          domain="meet.intelliclick.in"
          roomName={meetingId}
          configOverwrite={{
            subject: name,
            startWithAudioMuted: true,
            disableDeepLinking: true,
            disableModeratorIndicator: false,
            startScreenSharing: true,
            enableEmailInStats: false,
            prejoinPageEnabled: false,
            lobbyModeEnabled: true,
            toolbarButtons: [
              'camera',
              'chat',
              'closedcaptions',
              'desktop',
              'download',
              // 'embedmeeting',
              'etherpad',
              'feedback',
              'filmstrip',
              'fullscreen',
              'hangup',
              'help',
              'highlight',
              // 'invite',
              'linktosalesforce',
              'livestreaming',
              'microphone',
              'noisesuppression',
              'participants-pane',
              'profile',
              'raisehand',
              'recording',
              'security',
              'select-background',
              'settings',
              'shareaudio',
              'sharedvideo',
              // 'shortcuts',
              'stats',
              'tileview',
              'toggle-camera',
              'videoquality',
              'whiteboard',
            ],
          }}
          interfaceConfigOverwrite={{
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
            BRAND_WATERMARK_LINK: logo,
            SHOW_JITSI_WATERMARK: false,
            // DEFAULT_BACKGROUND: '#798',
            DEFAULT_WELCOME_PAGE_LOGO_URL: logo,
          }}
          userInfo={{
            displayName: user.name,
            email: user.email,
          }}
          // onApiReady={handleApiReady}
          getIFrameRef={(iframeRef) => {
            // eslint-disable-next-line no-param-reassign
            iframeRef.style.height = "100%";
          }}
          onReadyToClose={() => {
            window.location.replace("/");
          }}
          onApiReady={(externalApi) => {
            externalApi.addListener("participantRoleChanged", (event) => {
              if (event.role === "moderator") {
                externalApi.executeCommand("toggleLobby", true);
                externalApi.executeCommand("setFollowMe", true);
                // externalApi.executeCommand("overwriteConfig", {
                //   toolbarButtons: [
                //     "microphone",
                //     "camera",
                //     "chat",
                //     "whiteboard",
                //     "desktop",
                //     "hangup",
                //     "participants-pane",
                //     // "profile",
                //     "sharedvideo",
                //     // "security",
                //     // "stats",
                //     "recording",
                //     "options",
                //   ],
                // });
              }
            });
          }}
        />
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    paper: state.paper.printPaper,
    fetching: state.paper.fetching,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrintPaper)
);
