const initialState = {
  fetching: false,
  studentMapFetching: false,
  teacherMapFetching: false,
};

export default function userReducer(currentState = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'FETCHING_STUDENT_MAPPINGS':
      return { ...currentState, fetching: true };
    case 'FETCHED_STUDENT_MAPPINGS':
      return { ...currentState, studentMappings: payload, fetching: false };
    case 'FETCHING_TEACHER_MAPPINGS':
      return { ...currentState, fetching: true };
    case 'FETCHED_TEACHER_MAPPINGS':
      return { ...currentState, teacherMappings: payload, fetching: false };
    case 'DELETING_USER_MAPPING':
      return { ...currentState, fetching: true };
    case 'DELETED_USER_MAPPING':
      return { ...currentState, fetching: false };
    default:
      return currentState;
  }
}
