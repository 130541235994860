const initialState = {
  fetching: false,
};

export default function exampaperReducer(currentState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'INSERTING_OR_UPDATING_PAPER':
      return { ...currentState, fetching: true };
    case 'INSERTED_OR_UPDATED_PAPER':
      return { ...currentState, data: payload.data, fetching: false };
    case 'FETCHING_PAPER':
      return { ...currentState, fetching: true };
    case 'FETCHED_PAPER':
      return { ...currentState, data: payload.data, fetching: false };
    case 'RESET_FETCHED_PAPER':
      return { ...currentState, data: null, fetching: false };
    case 'FETCHING_PAPERS':
      return { ...currentState, fetching: true };
    case 'FETCHED_PAPERS':
      return { ...currentState, papers: payload.data, fetching: false };
    case 'UPDATING_PAPER':
      return { ...currentState, fetching: true };
    case 'UPDATED_PAPER':
      return { ...currentState, paperData: payload, fetching: false };
    case 'FETCHING_PAPER_FOR_PRINT':
      return { ...currentState, fetching: true };
    case 'FETCHED_PAPER_FOR_PRINT':
      return { ...currentState, printPaper: payload.data, fetching: false };
    default:
      return currentState;
  }
}
