const initialState = {
  fetching: false,
  studentMapFetching: false,
  teacherMapFetching: false,
};

export default function userReducer(currentState = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'INVITING':
      return { ...currentState, fetching: true };
    case 'INVITED':
      return { ...currentState, data: payload, fetching: false };
    case 'SET_STUDENT':
      return { ...currentState, student: payload };
    case 'SET_TEACHER':
      return { ...currentState, teacher: payload };
    case 'FETCHING_TEST_RESULTS':
      return { ...currentState, fetching: true };
    case 'FETCHED_TEST_RESULTS':
      return { ...currentState, results: payload, fetching: false };
    case 'FETCHING_WORKSPACE_DATA':
      return { ...currentState, fetching: true };
    case 'FETCHED_WORKSPACE_DATA':
      return { ...currentState, workspace: payload, fetching: false };
    default:
      return currentState;
  }
}
