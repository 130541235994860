import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      boxShadow: theme.boxShadow2,
      padding: '15px 30px',
      width: '100%',
      marginTop: 30,
      marginBottom: 30,
      textAlign: 'left',
      position: 'relative',
      backgroundImage: 'linear-gradient(to right, #fff, #b5bcca)',
    },
    bar: {
      margin: 0,
      '&:before': {
        top: 0,
        left: 0,
        width: 5,
        height: '100%',
        content: '\'\'',
        position: 'absolute',
        background: '#99179F',
      },
    },
  };
});

function TitleBar(props) {
  const {
    title, size, textAlign,
  } = props;
  const classes = useStyles();
  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography align={textAlign || 'left'} variant={size || 'h6'} className={classes.bar}><b>{title}</b></Typography>
      </Paper>
    </>
  );
}

export default TitleBar;
