import React from 'react';

// eslint-disable-next-line import/no-cycle
import SingleInput from './FunctionModels/SingleInput';

const Superscript = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const NAME = 'superscript';

  const getHTML = (data) => {
    const top = data && data.startsWith('<') ? 'top: -0.8em;' : '';
    const align = data && data.startsWith('<') ? 'vertical-align: top;' : '';
    const html = `<sup style="${align} position: relative; ${top}">${data}</sup>&nbsp;`;
    return html;
  };

  return (
    <>
      <SingleInput
        contentName={NAME}
        name={name}
        getHTML={getHTML}
        handleChange={handleChange}
        closeModal={closeModal}
        isAutoFocus
      />
    </>
  );
};

export default Superscript;
