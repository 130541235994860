import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
    },
    stepper: {
      padding: 0,
      paddingTop: 30,
      paddingBottom: 15,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 30,
      paddingBottom: 30,
    },
    container: {
      width: '100%',
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  };
});

function getSteps() {
  return [
    'Enter Paper Details',
    'Add Question',
    'Add Solution',
  ];
}

export default function CustomizedSteppers() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => { return prevActiveStep + 1; });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => { return prevActiveStep - 1; });
  };

  const handleReset = () => {
    setActiveStep(1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 handleNext={handleNext} handleBack={handleBack} />;
      case 1:
        return <Step2 handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        return <Step3 handleBack={handleBack} handleReset={handleReset} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>{getStepContent(activeStep)}</div>
          </div>
        )}
      </div>
    </div>
  );
}
