import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import { groupBy, ALL } from '../utils/util';

import Actions from '../actions';

import AppBar from '../components/AppBar';
import Doubt from '../components/App/Doubt';
import Filter from '../components/App/Filter';
import TitleBar from '../components/TitleBar';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginBottom: 15,
      boxShadow: theme.boxShadow,
    },
    itemAligment: {
      '@media (max-width:460px)': {
        justifyContent: 'center',
      },
      marginBottom: 30,
      paddingLeft: 14,
      paddingRight: 14,
    },
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    paperContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    standard: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 15px',
      color: '#333',
      fontSize: 16,
    },
    titleIcon: {
      color: theme.primaryColor,
      marginRight: 8,
      marginTop: 4,
    },
    subtitle: {
      fontWeight: 'bold',
    },
  };
});

const StudentDoubts = (props) => {
  const {
    doubts, loading, fetchStudentDoubts, insertOrUpdateSolution,
  } = props;
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(false);
  const [groupedDoubts, setGroupedDoubts] = React.useState({});
  const [standards, setStandards] = React.useState([]);
  const [filterStandard, setFilterStandard] = React.useState(ALL);
  const [filterSubject, setFilterSubject] = React.useState(ALL);
  const [filterYear, setFilterYear] = React.useState(ALL);
  const [years, setYears] = React.useState([]);

  const standardNames = groupedDoubts && Object.keys(groupedDoubts);

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const getGroupedDoubts = (doubtData = []) => {
    const grouped = groupBy(doubtData, (obj) => { return `${obj.standard?.name} (${obj.subjectName}) doubts`; });
    return grouped;
  };

  const setData = (currentDoubts) => {
    let doubtYears = currentDoubts.map((paper) => { return paper.year; });
    doubtYears = [...new Set(doubtYears)];
    doubtYears = doubtYears.filter((year) => { return !!year; });
    setYears(doubtYears.sort());
    setGroupedDoubts(getGroupedDoubts(currentDoubts));
  };

  React.useEffect(() => {
    fetchStudentDoubts();
  }, [refresh]);

  React.useEffect(() => {
    if (doubts) {
      const unique = {};
      const uniqueStandards = [];
      doubts.forEach((doubt) => {
        const standardId = doubt.standard?._id;
        if (!unique[standardId]) {
          unique[standardId] = standardId;
          uniqueStandards.push(doubt.standard);
        }
      });
      setStandards(uniqueStandards);
    }
  }, [doubts]);

  React.useEffect(() => {
    if (doubts) {
      if (filterStandard && filterStandard !== ALL) {
        const filterDoubts = doubts.filter((doubt) => {
          return (doubt.standard?._id === filterStandard
            && (!filterSubject || filterSubject === ALL || doubt.subjectName === filterSubject)
            && (!filterYear || filterYear === ALL || doubt.year === parseInt(filterYear, 10)));
        });
        setData(filterDoubts);
      } else {
        setData(doubts);
      }
    }
  }, [filterStandard, filterSubject, filterYear, doubts]);

  return (
    <>
      <AppBar>
        <div className={classes.container}>
          <TitleBar title="Student Doubts" />
          <Grid container className={classes.paperContainer}>
            <Filter
              standards={standards}
              years={years}
              setStandard={setFilterStandard}
              setSubjectName={setFilterSubject}
              setYear={setFilterYear}
              standard={filterStandard}
              subjectName={filterSubject}
              year={filterYear}
              isYears
            />
            {
                standardNames && standardNames.map((title) => {
                  return (
                    <Grid item xs={12} key={title}>
                      <Paper elevation={0} className={classes.standard}>
                        <Grid container alignItems="flex-start">
                          <Grid item>
                            <QuestionAnswerIcon className={classes.titleIcon} />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                              {title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Grid container className={classes.itemAligment} spacing={3}>
                        {
                          groupedDoubts[title] && groupedDoubts[title].map((obj) => {
                            return (
                              <Grid key={obj._id} item md={4} sm={6} xs={12}>
                                <Grid className={classes.deviceItem}>
                                  <Doubt
                                    doubt={obj}
                                    insertOrUpdateSolution={insertOrUpdateSolution}
                                    refreshPage={refreshPage}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })
                        }
                      </Grid>
                    </Grid>
                  );
                })
              }
            {
              !loading && standardNames && standardNames.length === 0
                ? (
                  <Grid container justifyContent="center">
                    <Paper elevation={0} className={classes.standard}>
                      <Typography align="center">
                        No doubt has been asked yet by any of your students.
                      </Typography>
                    </Paper>
                  </Grid>
                ) : null
            }
          </Grid>
        </div>
      </AppBar>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    doubts: state.question.doubts,
    loading: state.question.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentDoubts));
