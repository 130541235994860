import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '../components/AppBar';
import DailyActivity from '../components/App/DailyActivity';
import TitleBar from '../components/TitleBar';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    paperContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  };
});

const AddDailyActivity = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar>
        <div className={classes.container}>
          <TitleBar title="Add Your Daily Activity" />
          <div className={classes.paperContainer}>
            <DailyActivity />
          </div>
        </div>
      </AppBar>
    </>
  );
};

export default (AddDailyActivity);
