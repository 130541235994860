import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Modal from './Modal';
import AppButton from '../App/AppButton';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginBottom: 15,
    },
    loaderContainer: {
      minHeight: 150,
    },
    itemAligment: {
      '@media (max-width:460px)': {
        justifyContent: 'center',
      },
      marginBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
    },
    deviceItem: {
      width: '50%',
      '@media (max-width:460px)': {
        width: '100%',
      },
    },
    userCard: {
      minHeight: 100,
      textAlign: 'left',
      width: '100%',
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: '#555',
      padding: 15,
      '&:hover': {
        color: '#fff',
        background: theme.secondaryColor,
      },
    },
    standard: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 15px',
      color: '#555',
    },
    subtitle: {
      fontWeight: 'bold',
    },
    container: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    text: {
      textAlign: 'center',
    },
  };
});

function GroupSelect(props) {
  const {
    loading, data, mappings, handleCheckbokClick, open, handleClose,
  } = props;
  const classes = useStyles();

  const titles = (mappings && Object.keys(mappings)) || [];
  const mapObjs = (mappings && Object.values(mappings)) || [];

  const isChecked = (userId) => {
    const found = data.find((o) => { return o.value === userId; });
    if (found) return true;
    return false;
  };

  return (
    <Modal handleClose={handleClose} open={open} title="Select Attendees">
      {
        loading ? <Grid container justifyContent="center" alignItems="center"><CircularProgress /></Grid>
          : (
            <Grid container className={classes.container}>
              {
                titles.map((title, index) => {
                  return (
                    <Grid item xs={12} key={title}>
                      <Paper elevation={0} className={classes.standard}>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                          {title}
                        </Typography>
                      </Paper>
                      <Grid container className={classes.itemAligment} spacing={3}>
                        {
                            mapObjs[index] && mapObjs[index].map((obj) => {
                              const user = obj;
                              return (
                                <Grid key={user._id} item className={classes.deviceItem}>
                                  <FormControlLabel
                                    control={(
                                      <Checkbox
                                        checked={isChecked(user._id)}
                                        onChange={() => {
                                          handleCheckbokClick({
                                            label: user.name,
                                            value: user._id,
                                          });
                                        }}
                                        color="secondary"
                                      />
                                        )}
                                    label={user.name}
                                  />
                                </Grid>
                              );
                            })
                        }
                      </Grid>
                    </Grid>
                  );
                })
              }
              {
                !loading && titles.length === 0
                  ? (
                    <Grid container justifyContent="center">
                      <Paper elevation={0} className={classes.standard}>
                        <Typography className={classes.text}>
                          No one assigned/invited yet. Please invite.
                        </Typography>
                      </Paper>
                    </Grid>
                  ) : null
              }
              <Grid container justifyContent="center">
                <AppButton onClick={handleClose} title="Done" />
              </Grid>
            </Grid>
          )
      }
    </Modal>
  );
}

export default GroupSelect;
