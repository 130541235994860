import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      background: '#ddd',
      '&:hover': {
        background: theme.secondaryColor,
        color: '#fff',
      },
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 12,
      fontWeight: 'bold',
      color: '#888',
      boxShadow: theme.boxShadow,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    background: {
      background: theme.secondaryColor,
      color: '#fff',
    },
  };
});

const IconButton = (props) => {
  const {
    onClick, title, disabled, icon, href, dataTour, isBackground,
  } = props;
  const classes = useStyles();
  return (
    <>
      <Button
        href={href}
        data-tour={dataTour}
        onClick={onClick}
        disabled={disabled || false}
        startIcon={icon}
        className={[classes.button, isBackground ? classes.background : ''].join(' ')}
      >
        {title}
      </Button>
    </>
  );
};

export default IconButton;
