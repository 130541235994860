import { ENV } from '../utils/constants';

let config = {};

if (process.env.REACT_APP_ENV === ENV.PRODUCTION.KEY) {
  config = require('./config.prod'); // eslint-disable-line global-require
} else if (process.env.REACT_APP_ENV === ENV.DEVELOPMENT.KEY) {
  config = require('./config.dev'); // eslint-disable-line global-require
} else {
  config = require('./config.local'); // eslint-disable-line global-require
}

export default config = {
  debugMode: config.config.debugMode,
  api_key: config.config.api_key,
  serverURL: config.config.serverURL,
  fileUrl: 'https://intelliclick-images.s3.amazonaws.com',
  clientId: '555538254572-mv3bq829kaarqpauluaus2kchtab8hda.apps.googleusercontent.com', // My Project parth-web
};
