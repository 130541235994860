import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'auto',
      minHeight: '100vh',
    },
    content: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
    },
    paper: {
      minWidth: 460,
      [theme.breakpoints.down('sm')]: {
        minWidth: 320,
      },
    },
    title: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
      marginLeft: 30,
    },
    closeIcon: {
      fontWeight: 'bold',
      fontSize: 30,
      color: '#555',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
  };
});

export default function TransitionsModal(props) {
  const {
    children, open, handleClose, title,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        className={classes.modal}
        open={open}
        onClose={handleClose}
        // disableBackdropClick
        disableAutoFocus
        disableEnforceFocus
      >
        <Fade in={open}>
          <Paper elevation={0} className={classes.paper}>
            <Grid container justifyContent="flex-end">
              <Grid container item xs={11} justifyContent="center" alignItems="center">
                <Typography className={classes.title} variant="subtitle1">{title}</Typography>
              </Grid>
              <Grid item container xs={1} justifyContent="flex-end">
                <IconButton onClick={handleClose}>
                  <Close className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid className={classes.content}>
              {children}
            </Grid>
          </Paper>
        </Fade>
      </Dialog>
    </div>
  );
}
