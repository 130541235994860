import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import GroupModal from './Modal/GroupModal';

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 120,
      maxWidth: '100%',
      cursor: 'pointer',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  };
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect(props) {
  const {
    open, data, handleClose, handleCheckbokClick, onClick, loading, mappings,
  } = props;
  const classes = useStyles();

  return (
    <>
      <GroupModal
        open={open}
        data={data}
        handleClose={handleClose}
        handleCheckbokClick={handleCheckbokClick}
        mappings={mappings}
        loading={loading}
      />
      <FormControl fullWidth className={classes.formControl} onClick={onClick}>
        <InputLabel>Select Attendees</InputLabel>
        <Select
          value={data}
          disabled
          renderValue={(selected) => {
            return (
              <div className={classes.chips}>
                {selected.map((obj) => {
                  return (
                    <Chip key={obj.value} label={obj.label} className={classes.chip} />
                  );
                })}
              </div>
            );
          }}
          MenuProps={MenuProps}
        />
      </FormControl>
    </>
  );
}
