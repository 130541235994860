import React from 'react';
import AppBar from '../components/AppBar';
import Steper from '../components/ContentCreator/Stepper';

const Exampaper = () => {
  return (
    <>
      <AppBar>
        <Steper />
      </AppBar>
    </>
  );
};

export default Exampaper;
