import {
  TOKEN, TOTOKEN_GENERATION_TIME, TOUR_TYPES, TOUR_ITEMS,
} from '../utils/constants';

const initialState = {
  loading: false,
  isAuthenticated: false,
};

export default function userLogedIn(currentState = initialState, action) {
  const role = action.payload && action.payload.user && action.payload.user.role;
  switch (action.type) {
    case 'LOGGING_IN':
      localStorage.setItem(TOUR_ITEMS, JSON.stringify(Object.values(TOUR_TYPES)));
      return { ...currentState, loading: true };
    case 'LOGGED_IN':
      return { ...currentState, loading: false, data: action.payload };
    case 'LOADING_USER':
      return { ...currentState, loading: true };
    case 'LOADED_USER':
      return { ...currentState, loading: false, user: action.payload };
    case 'INSERTING_PROFILE':
      return { ...currentState, loading: true };
    case 'INSERTED_PROFILE':
      return { ...currentState, loading: false, data: action.payload };
    case 'SENDING_OTP':
      return { ...currentState, loading: true };
    case 'SENT_OTP':
      return { ...currentState, loading: false, data: action.payload };
    case 'VERIFYING':
      return { ...currentState, loading: true };
    case 'VERIFIED':
      localStorage.setItem(TOKEN, action.payload.token);
      localStorage.setItem('userName', action.payload.user && action.payload.user.name);
      localStorage.setItem('role', action.payload.user && action.payload.user.role);
      localStorage.setItem('user', action.payload.user && JSON.stringify(action.payload.user));
      localStorage.setItem(TOTOKEN_GENERATION_TIME, new Date());
      return {
        ...currentState,
        isAuthenticated: true,
        role,
        loading: false,
        data: action.payload,
      };
    case 'LOGOUT':
      localStorage.removeItem(TOKEN);
      localStorage.removeItem('userName');
      localStorage.removeItem('role');
      localStorage.removeItem(TOTOKEN_GENERATION_TIME);
      return {
        ...currentState,
        isAuthenticated: false,
        role: null,
      };
    default: return currentState;
  }
}
