import { createTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export default createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto Condensed',
      'sans-serif',
    ].join(','),
  },
  primaryColor: '#0b214f',
  darkBackground: '#050C15',
  secondaryColor: '#609cec',
  darkSecondaryColor: '#3596db',
  lightSecondaryColor: '#1da1f2',
  successColor: '#0bb84c',
  errorColor: '#E53935',
  warningColor: '#FFCC00',
  orangeColor: '#f26e1d',
  redColor: '#ff5f5f',
  greenColor: green[400],
  boxShadow: '0px 3px 16px #455b6320',
  boxShadow2: '0 1px 1px rgba(0, 0, 0, 0.01), 0 10px 30px rgba(0,0,0,0.08)',
  textSecondary: '#1976d2',
  lightBlue: '#e6eeff',
  lightRed: '#ffd9d6',
  lightGreen: '#c0efc5',
});
