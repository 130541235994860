import React from 'react';

import Container from '@material-ui/core/Container';

import AppBar from '../../components/AppBar';
import TitleBar from '../../components/TitleBar';
import Batches from '../../components/Display/Batches';
import NavigationButton from '../../components/Home/NavigationButton';
import SnackBar from '../../components/Progress/Snack';

const TeacherBatches = () => {
  const [refresh, setRefresh] = React.useState(false);
  const [snack, setSnack] = React.useState({});

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const closeSnack = () => {
    setSnack({});
  };

  return (
    <>
      <SnackBar
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <AppBar>
        <Container maxWidth={false}>
          <TitleBar title="My Batches" />
          <NavigationButton refreshPage={refreshPage} />
          <Batches refresh={refresh} setSnack={setSnack} refreshPage={refreshPage} />
        </Container>
      </AppBar>
    </>
  );
};

export default TeacherBatches;
