import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import Delete from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

import { groupBy } from '../../utils/util';
import Actions from '../../actions';

import DialogBox from '../Dialog/DialogBox';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginBottom: 15,
    },
    loaderContainer: {
      minHeight: 150,
    },
    itemAligment: {
      '@media (max-width:460px)': {
        justifyContent: 'center',
      },
      marginBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
    },
    deviceItem: {
      cursor: 'pointer',
      width: '25%',
      '@media (max-width:460px)': {
        width: '100%',
      },
    },
    userCard: {
      minHeight: 100,
      textAlign: 'left',
      width: '100%',
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: '#555',
      padding: 15,
      '&:hover': {
        color: '#fff',
        background: theme.secondaryColor,
      },
    },
    standard: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 15px',
      color: '#555',
    },
    subtitle: {
      fontWeight: 'bold',
    },
    container: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    text: {
      textAlign: 'center',
    },
    batchIcon: {
      marginRight: 8,
    },
  };
});

function StudentMapping(props) {
  const {
    refresh, batches, loading, setSnack, refreshPage, fetchBatches, setBatch, deleteBatch,
  } = props;
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});
  const [redirect, setRedirect] = React.useState(false);
  const [batchId, setBatchId] = React.useState();
  const [groupedBaches, setGroupedBatches] = React.useState({});

  const onCloseDialog = () => {
    setOpenDialog(false);
    setDeleteData({});
  };

  const confirmDelete = () => {
    setOpenDialog(false);
    const promise = deleteBatch(deleteData);
    promise
      .then(() => {
        setSnack({ open: true, severity: 'success', message: 'Removed batch successfully!' });
        setDeleteData({});
        refreshPage();
      })
      .catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Sorry! Could not remove batch as of now.' });
      });
  };

  const handleDelete = (_id) => {
    setDeleteData({ _id });
    setOpenDialog(true);
  };

  const setBatchData = (data) => {
    setBatch(data);
    setBatchId(data._id);
    setRedirect(true);
  };

  React.useEffect(() => {
    fetchBatches();
  }, [refresh]);

  const standardNames = (groupedBaches && Object.keys(groupedBaches)) || [];

  React.useEffect(() => {
    if (batches) {
      const grouped = groupBy(batches, (obj) => { return `${obj.standard?.name} Batches`; });
      setGroupedBatches(grouped);
    }
  }, [batches]);

  if (redirect) {
    return (<Redirect to={{ pathname: `/batch/?batchId=${batchId}` }} push />);
  }

  return (
    <>
      <DialogBox open={openDialog} onClose={onCloseDialog} title="Remove Batch" confirm={confirmDelete}>
        Are you sure want to remove this batch?
      </DialogBox>
      {
        loading ? <Grid container justifyContent="center" alignItems="center"><CircularProgress /></Grid>
          : (
            <Grid container className={classes.container} data-tour="my-batches">
              {
                standardNames.map((standardName) => {
                  return (
                    <Grid item xs={12} key={standardName}>
                      <Paper elevation={0} className={classes.standard}>
                        <Grid container alignItems="center">
                          <FilterNoneIcon className={classes.batchIcon} />
                          <Typography variant="subtitle1" className={classes.subtitle}>{standardName}</Typography>
                        </Grid>
                      </Paper>
                      <Grid container className={classes.itemAligment} spacing={3}>
                        {
                            groupedBaches[standardName] && groupedBaches[standardName].map((batch) => {
                              const createdBy = batch.createdBy || {};
                              return (
                                <Grid
                                  key={batch._id}
                                  item
                                  className={classes.deviceItem}
                                >
                                  <Paper className={classes.userCard}>
                                    <Grid container justifyContent="space-between">
                                      <Grid item xs={10} onClick={() => { setBatchData(batch); }}>
                                        <Typography variant="subtitle1" className={classes.subtitle} noWrap>
                                          {batch.year}
                                          {' '}
                                          {batch.name || `Batch - ${batch.id}`}
                                        </Typography>
                                      </Grid>
                                      <Grid container item xs={2} justifyContent="flex-end">
                                        <IconButton size="small" onClick={() => { handleDelete(batch._id); }}>
                                          <Delete fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                    <Grid onClick={() => { setBatchData(batch); }}>
                                      <Typography variant="caption" display="block" noWrap>
                                        Year:
                                        {' '}
                                        {batch.year}
                                      </Typography>
                                      <Typography variant="caption" display="block" noWrap>
                                        Standard:
                                        {' '}
                                        {batch.standardName}
                                      </Typography>
                                      <Typography variant="caption" display="block" noWrap>
                                        Created By:
                                        {' '}
                                        {createdBy.name}
                                      </Typography>
                                    </Grid>
                                  </Paper>
                                </Grid>
                              );
                            })
                        }
                      </Grid>
                    </Grid>
                  );
                })
              }
              {
                  !loading && standardNames.length === 0
                    ? (
                      <Grid container justifyContent="center">
                        <Paper elevation={0} className={classes.standard}>
                          <Typography className={classes.text}>
                            No batches. No student assigned/invited yet. Please invite student.
                          </Typography>
                        </Paper>
                      </Grid>
                    ) : null
              }
            </Grid>
          )
      }
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    batches: state.batch.batches,
    loading: state.batch.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentMapping));
