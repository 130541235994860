import React from 'react';

import Alert from '@material-ui/lab/Alert';

import Snackbar from '@material-ui/core/Snackbar';

function Snack(props) {
  const {
    open, handleClose, severity, message, duration,
  } = props;
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={duration || 5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Snack;
