import React from 'react';

import AppBar from '../../components/AppBar';
import BatchComponent from '../../components/Display/Batch';

const Batch = () => {
  return (
    <>
      <AppBar>
        <BatchComponent />
      </AppBar>
    </>
  );
};

export default Batch;
