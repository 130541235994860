const initialState = {
  fetching: false,
};

export default function accessReducer(currentState = initialState, action) {
  switch (action.type) {
    case 'FETCHING_ACCESS':
      return { ...currentState, fetching: true };
    case 'FETCHED_ACCESS':
      return { ...currentState, data: action.payload, fetching: false };
    default:
      return currentState;
  }
}
