import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import AppBar from '../../components/AppBar';
import TitleBar from '../../components/TitleBar';
import Invite from '../../components/App/Invite';
import TeacherMapping from '../../components/Display/TeacherMapping';
import Batches from '../../components/Display/Batches';
import SnackBar from '../../components/Progress/Snack';
import UpcomingMeeting from '../../components/Display/UpcomingMeeting';
import NavigationButton from '../../components/Home/NavigationButton';
import Tour from '../../components/Tour/Tour';
import { TOUR_TYPES } from '../../utils/constants';
import Workspace from '../../components/Display/Workspace';

const InstituteHome = () => {
  const [refresh, setRefresh] = React.useState(false);
  const [snack, setSnack] = React.useState({});

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const closeSnack = () => {
    setSnack({});
  };

  return (
    <>
      <SnackBar
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <AppBar>
        <Tour type={TOUR_TYPES.HOME} />
        <Container maxWidth={false}>
          <Grid>
            <TitleBar title="Today's Meetings" />
            <UpcomingMeeting />
          </Grid>
          <TitleBar title="My Workspace" />
          <Workspace />
          <TitleBar title="My Teachers" />
          <Grid container justifyContent="flex-end" style={{ marginBottom: 30 }}>
            <Invite userRole="teacher" refreshPage={refreshPage} />
          </Grid>
          <TeacherMapping refresh={refresh} setSnack={setSnack} refreshPage={refreshPage} />
          <TitleBar title="My Batches" />
          <NavigationButton refreshPage={refreshPage} />
          <Batches refresh={refresh} setSnack={setSnack} refreshPage={refreshPage} />
        </Container>
      </AppBar>
    </>
  );
};

export default InstituteHome;
