import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { SYMBOLS } from '../../utils/constants';

const Symbols = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;

  const handleSubmit = (value) => {
    closeModal();
    return handleChange({ target: { name, value } });
  };

  return (
    <>
      {
        SYMBOLS.map((item) => {
          return (
            <Tooltip title={item.name} key={item.code} arrow>
              <IconButton
                onClick={() => { handleSubmit(item.code); }}
                style={{ fontFamily: '"Times New Roman", Courier, Garamond, serif' }}
              >
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: item.code }}
                />
              </IconButton>
            </Tooltip>
          );
        })
      }
    </>
  );
};

export default Symbols;
