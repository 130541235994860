const initialState = {
  fetching: false,
};

export default function imageReducer(currentState = initialState, action) {
  switch (action.type) {
    case 'UPLOADING_FILE':
      return { ...currentState, fetching: true };
    case 'UPLOADED_FILE':
      return { ...currentState, data: action.payload, fetching: false };
    case 'RESET_FILE_DATA':
      return { ...currentState, data: action.payload, fetching: false };
    default:
      return currentState;
  }
}
