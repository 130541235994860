const tabStyle = {
  tab1: {
    fontWeight: 'bold',
  },
  tab2: {
    fontWeight: 'bold',
    marginLeft: '15px',
  },
  active: {
    color: '#1da1f2',
    background: '#e6eeff',
  },
  inactive: {
    '&:hover': {
      color: '#1da1f2',
      background: '#e6eeff',
    },
  },
};

export default tabStyle;
