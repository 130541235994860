import * as Axios from '../axios/axios';

const notifyUser = function (dispatch, error, type) {
  const message = error.response && ((error.response.data && error.response.data.message) || error.response.data);
  const newError = new Error(message);
  dispatch({
    type: type || 'SET_ERROR',
    payload: newError,
  });
  return Promise.reject(newError);
};

export function resetError() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_ERROR',
      payload: null,
    });
  };
}

export function resetQuestions() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_IMPORT_QUESTIONS',
      payload: [],
    });
  };
}

export function setSnack(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_SNACK',
      payload: data,
    });
  };
}

export function resetSnack() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_SNACK',
      payload: null,
    });
  };
}

export function setLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: bool,
    });
  };
}

export function resetLoading() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_LOADING',
    });
  };
}

export function resetDailyActivity() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_DAILY_ACTIVITY',
    });
  };
}

export function resetFetchedPaper() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_FETCHED_PAPER',
    });
  };
}

export function loadUser() {
  return (dispatch) => {
    dispatch({
      type: 'LOADING_USER',
    });
    return Axios.loadUser().then((resData) => {
      dispatch({
        type: 'LOADED_USER',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchStandards() {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_STANDARDS',
    });
    return Axios.fetchStandards().then((data) => {
      dispatch({
        type: 'FETCHED_STANDARDS',
        payload: data,
      });
    }, (error) => {
      notifyUser(dispatch, error);
    });
  };
}

export function uploadFile(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'UPLOADING_FILE',
    });
    return Axios.uploadFile(data).then((resData) => {
      dispatch({
        type: 'UPLOADED_FILE',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function publishPaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATING_PAPER',
    });
    return Axios.publishPaper(data).then((resData) => {
      dispatch({
        type: 'UPDATED_PAPER',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function markAvailability(data) {
  return (dispatch) => {
    dispatch({
      type: 'MARKING_AVAILABILITY',
    });
    return Axios.markAvailability(data).then((resData) => {
      dispatch({
        type: 'MARKED_AVAILABILITY',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchAvailability() {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_AVAILABILITY',
    });
    return Axios.fetchAvailability().then((resData) => {
      dispatch({
        type: 'FETCHED_AVAILABILITY',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function markTimeoff(data) {
  return (dispatch) => {
    dispatch({
      type: 'MARKING_TIMEOFF',
    });
    return Axios.markTimeoff(data).then((resData) => {
      dispatch({
        type: 'MARKED_TIMEOFF',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function resetFileData() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_FILE_DATA',
      payload: null,
    });
  };
}

export function insertQuestion(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_QUESTION',
    });

    return Axios.insertQuestion(data).then(() => {
      dispatch({
        type: 'INSERTED_QUESTION',
        payload: {},
      });
    }, (error) => {
      notifyUser(dispatch, error);
    });
  };
}

export function fetchSubjects() {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_SUBJECTS',
    });
    return Axios.fetchSubjects().then((data) => {
      dispatch({
        type: 'FETCHED_SUBJECTS',
        payload: data,
      });
    }, (error) => {
      notifyUser(dispatch, error);
    });
  };
}

export function insertOrUpdatePaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_OR_UPDATING_PAPER',
    });
    return Axios.insertOrUpdatePaper(data).then((resData) => {
      dispatch({
        type: 'INSERTED_OR_UPDATED_PAPER',
        payload: {
          data: resData,
        },
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchPapers(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_PAPERS',
    });
    return Axios.fetchPapers(data).then((resData) => {
      dispatch({
        type: 'FETCHED_PAPERS',
        payload: {
          data: resData,
        },
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchPaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_PAPER',
    });
    return Axios.fetchPaper(data).then((resData) => {
      dispatch({
        type: 'FETCHED_PAPER',
        payload: {
          data: resData,
        },
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function getPaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_PAPER_FOR_PRINT',
    });
    return Axios.getPaper(data).then((resData) => {
      dispatch({
        type: 'FETCHED_PAPER_FOR_PRINT',
        payload: {
          data: resData,
        },
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function deletePaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'DELETING_PAPER',
    });
    return Axios.deletePaper(data).then((resData) => {
      dispatch({
        type: 'DELETED_PAPER',
        payload: {
          data: resData,
        },
      });
      dispatch({
        type: 'INSERT_SNACK_DATA',
        payload: {
          data: resData,
        },
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function setSnackData(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERT_SNACK_DATA',
      payload: {
        data,
      },
    });
  };
}

export function setQuestionData(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_QUESTION',
      payload: {
        data,
      },
    });
  };
}

export function insertQuestionInPaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_QUESTION_IN_PAPER',
    });
    return Axios.insertQuestionInPaper(data).then((resData) => {
      dispatch({
        type: 'FETCHED_PAPER',
        payload: {
          data: resData,
        },
      });
      dispatch({
        type: 'INSERTED_QUESTION_IN_PAPER',
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function importQuestionsInPaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_QUESTION_IN_PAPER',
    });
    return Axios.importQuestionsInPaper(data).then((resData) => {
      dispatch({
        type: 'FETCHED_PAPER',
        payload: {
          data: resData,
        },
      });
      dispatch({
        type: 'INSERTED_QUESTION_IN_PAPER',
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function populateQuestionsOfPaper(paperId) {
  return (dispatch) => {
    dispatch({
      type: 'FATCHING_QUESTIONS',
    });
    return Axios.getAllQuestions(paperId).then((questions) => {
      dispatch({
        type: 'FATCHED_QUESTUINS',
        payload: { questions },
      });
    });
  };
}

export function createUserProfile(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_PROFILE',
    });
    return Axios.createUserProfile(data).then((resData) => {
      dispatch({
        type: 'INSERTED_PROFILE',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function sendInvite(data) {
  return (dispatch) => {
    dispatch({
      type: 'INVITING',
    });
    return Axios.sendInvite(data).then((resData) => {
      dispatch({
        type: 'INVITED',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function getStudentMappings(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_STUDENT_MAPPINGS',
    });
    return Axios.getStudentMappings(data).then((resData) => {
      dispatch({
        type: 'FETCHED_STUDENT_MAPPINGS',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function getTeacherMappings(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_TEACHER_MAPPINGS',
    });
    return Axios.getTeacherMappings(data).then((resData) => {
      dispatch({
        type: 'FETCHED_TEACHER_MAPPINGS',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function deleteUserMapping(data) {
  return (dispatch) => {
    dispatch({
      type: 'DELETING_USER_MAPPING',
    });
    return Axios.deleteUserMapping(data).then((resData) => {
      dispatch({
        type: 'DELETED_USER_MAPPING',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function login(data) {
  return (dispatch) => {
    dispatch({
      type: 'LOGGING_IN',
    });
    return Axios.login(data).then((resData) => {
      dispatch({
        type: 'LOGGED_IN',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: 'LOGOUT',
    });
  };
}

export function resumePaper(_id) {
  return (dispatch) => {
    const data = { _id };
    dispatch({
      type: 'INSERTED_PAPER',
      payload: {
        data,
      },
    });
  };
}

export function setStudent(user) {
  return (dispatch) => {
    dispatch({
      type: 'SET_STUDENT',
      payload: user,
    });
  };
}

export function setTeacher(user) {
  return (dispatch) => {
    dispatch({
      type: 'SET_TEACHER',
      payload: user,
    });
  };
}

export function removeQuestionFromPaper(data) {
  return (dispatch) => {
    dispatch({
      type: 'QUESTION_REMOVING',
    });
    return Axios.removeQuestionFromPaper(data)
      .then(() => {
        return dispatch({
          type: 'QUESTION_REMOVED',
        });
      }, (error) => {
        return notifyUser(dispatch, error);
      });
  };
}

export function getAllPaper() {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_ALL_EXAMPAPERS',
    });
    return Axios.getAllPaper().then((data) => {
      dispatch({
        type: 'FETCHED_EXAMPAPERS',
        payload: {
          data,
        },
      });
    }, (error) => {
      notifyUser(dispatch, error);
    });
  };
}

export function deactivateExampaper(paperId) {
  return (dispatch) => {
    dispatch({
      type: 'DEACTIVATING_PAPER',
    });
    const data = { paperId };
    return Axios.deactivateExampaper(data)
      .then(() => {
        dispatch({
          type: 'DEACTIVATED',
        });
      }, (error) => {
        notifyUser(dispatch, error);
      });
  };
}

export function updateQuestion(data) {
  return (dispatch) => {
    dispatch({
      type: 'QUESTION_UPDATING',
    });
    return Axios.updateQuestion(data).then(() => {
      dispatch({
        type: 'QUESTION_UPDATED',
      });
    }, (error) => {
      notifyUser(dispatch, error);
    });
  };
}

export function getAllQuestionsOfUser() {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_ALL_QUESTIONS',
    });
    return Axios.getAllQuestionsOfUser().then((data) => {
      dispatch({
        type: 'FETCHED_ALL_QUESTIONS',
        payload: {
          data,
        },
      });
    }, (error) => {
      notifyUser(dispatch, error);
    });
  };
}

export function importQuestion(data) {
  return (dispatch) => {
    dispatch({
      type: 'IMPORTING_QUESTION',
    });
    return Axios.importQuestion(data).then(() => {
      dispatch({
        type: 'IMPORTED',
      });
    });
  };
}

export function deleteQuestionFromStore(questions) {
  return (dispatch) => {
    dispatch({
      type: 'DELETING_QUESTION',
      payload: questions,
    });
  };
}

export function insertOrUpdateMeeting(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_OR_UPDATING_MEETING',
    });
    return Axios.insertOrUpdateMeeting(data).then((resData) => {
      dispatch({
        type: 'INSERTED_OR_UPDATING_MEETING',
        payload: resData,
      });
    }).catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function fetchMeetings(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_MEETINGS',
    });
    return Axios.fetchMeetings(data).then((resData) => {
      dispatch({
        type: 'FETCHED_MEETINGS',
        payload: resData,
      });
    }).catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function fetchOtherMeetings() {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_OTHER_MEETINGS',
    });
    return Axios.fetchOtherMeetings().then((data) => {
      dispatch({
        type: 'FETCHED_OTHER_MEETINGS',
        payload: data,
      });
    }).catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function fetchTestResults(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_TEST_RESULTS',
    });
    return Axios.fetchTestResults(data)
      .then((resData) => {
        dispatch({
          type: 'FETCHED_TEST_RESULTS',
          payload: resData,
        });
      }, (error) => {
        return notifyUser(dispatch, error);
      });
  };
}

export function sendOTP(data) {
  return (dispatch) => {
    dispatch({
      type: 'SENDING_OTP',
    });
    return Axios.sendOTP(data).then((resData) => {
      dispatch({
        type: 'SENT_OTP',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function verifyOTP(data) {
  return (dispatch) => {
    dispatch({
      type: 'VERIFYING',
    });
    return Axios.verifyOTP(data)
      .then((resData) => {
        dispatch({
          type: 'VERIFIED',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function verify(data) {
  return (dispatch) => {
    dispatch({
      type: 'VERIFYING',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.verify(data)
      .then((resData) => {
        dispatch({
          type: 'VERIFIED',
          payload: resData,
        });
        dispatch({
          type: 'RESET_LOADING',
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); })
      .catch(() => { return Promise.resolve(); });
  };
}

export function setContent(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_CONTENT',
      payload: data,
    });
  };
}

export function insertTopic(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_TOPIC',
    });
    return Axios.insertTopic(data)
      .then((resData) => {
        dispatch({
          type: 'INSERTED_TOPIC',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function getTopics(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_TOPICS',
    });
    return Axios.getTopics(data)
      .then((resData) => {
        dispatch({
          type: 'FETCHED_TOPICS',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function deleteTopic(data) {
  return (dispatch) => {
    dispatch({
      type: 'DELETING_TOPIC',
    });
    return Axios.deleteTopic(data)
      .then((resData) => {
        dispatch({
          type: 'DELETED_TOPIC',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function deleteMeeting(data) {
  return (dispatch) => {
    dispatch({
      type: 'DELETING_MEETING',
    });
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    return Axios.deleteMeeting(data)
      .then((resData) => {
        dispatch({
          type: 'DELETED_MEETING',
          payload: resData,
        });
        dispatch({
          type: 'SET_LOADING',
          payload: false,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function getChapters(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_CHAPTERS',
    });
    return Axios.getChapters(data)
      .then((resData) => {
        dispatch({
          type: 'FETCHED_CHAPTERS',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function insertOrUpdateChapter(data) {
  return (dispatch) => {
    dispatch({
      type: 'ADDING_CHAPTER',
    });
    return Axios.insertOrUpdateChapter(data)
      .then((resData) => {
        dispatch({
          type: 'ADDED_CHAPTER',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function fetchBatches() {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_BATCHES',
    });
    return Axios.fetchBatches()
      .then((resData) => {
        dispatch({
          type: 'FETCHED_BATCHES',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function deleteBatch(data) {
  return (dispatch) => {
    dispatch({
      type: 'DELETING_BATCH',
    });
    return Axios.deleteBatch(data)
      .then((resData) => {
        dispatch({
          type: 'DELETED_BATCH',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function fetchBatch(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
    });
    dispatch({
      type: 'FETCHING_BATCH',
    });
    return Axios.fetchBatch(data)
      .then((resData) => {
        dispatch({
          type: 'FETCHED_BATCH',
          payload: resData,
        });
        dispatch({
          type: 'RESET_LOADING',
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function setBatch(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_BATCH',
      payload: data,
    });
  };
}

export function addUsers(data) {
  return (dispatch) => {
    dispatch({
      type: 'ASSIGNING_USERS',
    });
    return Axios.addUsers(data)
      .then((resData) => {
        dispatch({
          type: 'ASSIGNED_USERS',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function fetchAttendees(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_ATTENDEES',
    });
    return Axios.fetchAttendees(data)
      .then((resData) => {
        dispatch({
          type: 'FETCHED_ATTENDEES',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function getAllTopics(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_CONTENTS',
    });
    return Axios.getAllTopics(data)
      .then((resData) => {
        dispatch({
          type: 'FETCHED_CONTENTS',
          payload: resData,
        });
      })
      .catch((err) => { return notifyUser(dispatch, err); });
  };
}

export function insertOrUpdateNote(data) {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_NOTE',
    });
    return Axios.insertOrUpdateNote(data).then((resData) => {
      dispatch({
        type: 'INSERTED_NOTE',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchNotes(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_NOTES',
    });
    return Axios.fetchNotes(data).then((resData) => {
      dispatch({
        type: 'FETCHED_NOTES',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchWorkspaceData(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_WORKSPACE_DATA',
    });
    return Axios.fetchWorkspaceData(data).then((resData) => {
      dispatch({
        type: 'FETCHED_WORKSPACE_DATA',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchStudentDoubts(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'FETCHING_DOUBTS',
    });
    return Axios.fetchStudentDoubts(data).then((resData) => {
      dispatch({
        type: 'FETCHED_DOUBTS',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function fetchQuestions(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_IMPORT_QUESTIONS',
    });
    return Axios.fetchQuestions(data).then((resData) => {
      dispatch({
        type: 'FETCHED_IMPORT_QUESTIONS',
        payload: resData,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function insertOrUpdateSolution(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'INSERTING_OR_UPDATING_SOLUTION',
    });
    return Axios.insertOrUpdateSolution(data).then((resData) => {
      dispatch({
        type: 'INSERTED_OR_UPDATED_SOLUTION',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function insertOrUpdateAnnouncement(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'INSERTING_OR_UPDATING_ANNOUNCEMENT',
    });
    return Axios.insertOrUpdateAnnouncement(data).then((resData) => {
      dispatch({
        type: 'INSERTED_OR_UPDATED_ANNOUNCEMENT',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function getUserAnnouncement() {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'FETCHING_USER_ANNOUNCEMENT',
    });
    return Axios.getUserAnnouncement().then((resData) => {
      dispatch({
        type: 'FETCHED_USER_ANNOUNCEMENT',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function deleteAnnouncement(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'DELETING_ANNOUNCEMENT',
    });
    return Axios.deleteAnnouncement(data).then((resData) => {
      dispatch({
        type: 'DELETED_ANNOUNCEMENT',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function insertOrUpdateDailyActivity(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'INSERTING_OR_UPDATING_DAILY_ACTIVITY',
    });
    return Axios.insertOrUpdateDailyActivity(data).then((resData) => {
      dispatch({
        type: 'INSERTED_OR_UPDATED_DAILY_ACTIVITY',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
      return resData;
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function getDailyActivity(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    dispatch({
      type: 'FETCHING_DAILY_ACTIVITY',
    });
    return Axios.getDailyActivity(data).then((resData) => {
      dispatch({
        type: 'FETCHED_DAILY_ACTIVITY',
        payload: resData,
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    }, (error) => {
      return notifyUser(dispatch, error);
    });
  };
}

export function getAccess(data) {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_ACCESS',
    });
    return Axios.getAccess(data)
      .then((resData) => {
        dispatch({
          type: 'FETCHED_ACCESS',
          payload: resData,
        });
      }, (error) => {
        return notifyUser(dispatch, error);
      });
  };
}
