/* eslint-disable no-console */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import DuoIcon from "@material-ui/icons/Duo";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { getFrequencyText } from "../../utils/util";

import DialogBox from "../Dialog/DialogBox";
import AppButton from "../App/AppButton";

const useStyles = makeStyles((theme) => {
  return {
    card: {
      minHeight: 0,
    },
    paper: {
      boxShadow: theme.boxShadow,
      marginBottom: 16,
      padding: "10px 15px",
    },
    join: {
      background: theme.secondaryColor,
      boxShadow: theme.boxShadow,
      paddingLeft: 20,
      paddingRight: 20,
      color: "#fff",
      "&:hover": {
        background: theme.darkSecondaryColor,
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      marginRight: 10,
    },
    joinText: {
      fontWeight: "bold",
      fontSize: 14,
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    time: {
      fontSize: 15,
    },
    frequencyText: {
      fontSize: 12,
    },
  };
});

export default function Meeting(props) {
  const { editMeeting, noEdit, deleteMeeting, setSnack, refreshPage } = props;
  let { meetingData } = props;
  meetingData = meetingData || {};
  const weekDaysInts =
    meetingData.days &&
    meetingData.days.map((day) => {
      return new Date(day).getDay();
    });
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const edit = () => {
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    }, 5);
    return editMeeting(meetingData);
  };

  const deletingMeeting = (_id) => {
    setOpenDeleteDialog(true);
    setDeleteId(_id);
  };

  const onCloseDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    deleteMeeting({ _id: deleteId })
      .then(() => {
        onCloseDialog();
        setSnack({
          open: true,
          severity: "success",
          message: "Meeting deleted Successfully!",
        });
        refreshPage();
      })
      .catch();
  };

  return (
    <>
      <DialogBox
        title="Delete Meeting"
        open={openDeleteDialog}
        onClose={onCloseDialog}
        confirm={confirmDelete}
      >
        Are you sure want to delete this meeting?
      </DialogBox>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.card}
          alignItems="center"
        >
          <Grid item md={3} sm={3} xs={3}>
            <Typography noWrap>
              <b>{meetingData.name}</b>
            </Typography>
            <Typography noWrap>
              <b style={{ fontSize: "0.8rem", fontWeight: "100" }}>
                {meetingData.standardName.name}
              </b>
            </Typography>
          </Grid>
          <Grid item md={2} sm={2} xs={3}>
            <Typography className={classes.time} noWrap>
              <b>
                {meetingData.startTime &&
                  new Date(meetingData.startTime).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
              </b>
            </Typography>
            <Typography className={classes.frequencyText} noWrap>
              {getFrequencyText(weekDaysInts, meetingData.startTime)}
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={4}
            sm={4}
            xs={noEdit ? 5 : 4}
            justifyContent="flex-end"
          >
            {/* <Button
              className={classes.join}
              href={`/meeting/${meetingData.meetingId}?name=${meetingData.name}`}
              startIcon={<DuoIcon />}
            >
              <Typography noWrap className={classes.joinText}>
                Join Class
              </Typography>
            </Button> */}
            <AppButton
              startIcon={<DuoIcon />}
              href={`/meeting/${meetingData.meetingId}?name=${meetingData.name}`}
              title="Join Class"
            />
          </Grid>
          {noEdit ? null : (
            <Grid container item md={3} sm={3} xs={2} justifyContent="flex-end">
              <IconButton onClick={edit} size="small">
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  deletingMeeting(meetingData._id);
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
}
