import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { DropzoneArea } from 'material-ui-dropzone';

import Actions from '../../actions';

const Upload = (props) => {
  const {
    uploadFile, acceptedFiles, maxFileSize, setSnack, showPreviewsInDropzone,
  } = props;
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleImageChange = (files) => {
    files.forEach((file) => {
      setSelectedFile(file);
    });
  };

  React.useEffect(() => {
    if (selectedFile) {
      setSnack({
        open: true,
        severity: 'info',
        message: 'Uploading file! Wait for a while.',
      });
      const formData = new FormData();
      formData.append('File', selectedFile);
      uploadFile(formData)
        .then(() => {
          setSnack({
            open: true,
            severity: 'success',
            message: 'File uploaded successfully!',
          });
          setSelectedFile(null);
        })
        .catch((err) => {
          setSnack({
            open: true,
            severity: 'error',
            message: err.message || 'Something went wrong!',
          });
        });
    }
  }, [selectedFile]);

  return (
    <>
      <div>
        <DropzoneArea
          showFileNames
          onChange={handleImageChange}
          acceptedFiles={acceptedFiles}
          maxFileSize={maxFileSize}
          filesLimit={1}
          showPreviewsInDropzone={showPreviewsInDropzone}
          dropzoneText="Click to add PDF file"
        />
      </div>
      {/* <img src={URL.createObjectURL(selectedFile)} alt="name" /> */}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    fileData: state.file.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
