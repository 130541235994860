import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import Actions from '../../../actions';

import Snack from '../../Progress/Snack';
import Loader from '../../Progress/Loader';

import TitleBar from '../../TitleBar';
import ContentEditor from '../../Editor/ContentEditor';
import ShowFunction from '../../Functions/Display/ShowFunction';
import Question from '../../Display/Question';
import AppButton from '../../App/AppButton';
import InputField from '../../InputField';
import ChapterSelect from '../../App/ChapterSelect';
import Select from '../../Select';

import { QUESTION_TYPES } from '../../../utils/constants';

import { questionValidationSchema, getCombineValue, FULL_TEST } from '../../../utils/util';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      width: 260,
      padding: 15,
      marginTop: 30,
      [theme.breakpoints.down('sm')]: {
        width: 260,
      },
    },
    submit: {
      marginTop: 30,
      marginBottom: 30,
    },
    heading: {
      fontWeight: 600,
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 15,
    },
    topContainer: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    paperContainer: {
      padding: '0 15px',
      boxShadow: theme.boxShadow,
    },
    optionContainer: {
      marginBottom: 30,
    },
    textField: {
      marginRight: 15,
      background: '#fff',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 15,
        marginRight: 0,
      },
    },
  };
});

function Step3(props) {
  const {
    handleBack, insertQuestionInPaper, handleReset, setSnackData, setQuestionData, standards,
  } = props;
  const propsQuestion = props.question || {};
  const paper = props.paper || {};
  const classes = useStyles();

  const [snack, setSnack] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('solution');
  const [question, setQuestion] = React.useState({
    _id: propsQuestion._id,
    question: propsQuestion.question || '',
    questionType: propsQuestion.questionType || paper.questionType || '',
    standard: propsQuestion.standard || paper.standard || '',
    subject: propsQuestion.subject || paper.subject || '',
    subjectName: propsQuestion.subjectName || paper.subjectName || '',
    standardName: propsQuestion.standardName || paper.standardName || '',
    year: propsQuestion.year || paper.year || '',
    options: propsQuestion.options || [],
    solutionId: propsQuestion.solutionId,
    solution: propsQuestion.solution || '',
    correctOptionIndexes: propsQuestion.correctOptionIndexes || [],
    answer: propsQuestion.answer || (propsQuestion.answers && propsQuestion.answers[0]) || '',
    answers: propsQuestion.answers || [],
    paperId: propsQuestion.paperId || paper._id,
    marksMapping: paper.marksMapping || propsQuestion.marksMapping,
    marks: propsQuestion.marks
      || {
        ...((paper.marksMapping || propsQuestion.marksMapping)
        || {})[propsQuestion.questionType || paper.questionType],
      },
    chapter: propsQuestion.chapter,
  });
  const [position] = React.useState({ start: 0, end: 0 });
  const [refresh, setRefresh] = React.useState(false);
  const [standard, setStandard] = React.useState(null);

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const handleIndexChange = (index, type = question.questionType) => {
    if (type === QUESTION_TYPES.SINGLE_CHOICE || type === QUESTION_TYPES.BOOLEAN) {
      setQuestion({ ...question, correctOptionIndexes: [index], answers: [question.options[index]] });
    } else if (type === QUESTION_TYPES.MULTIPLE_CHOICE) {
      setQuestion((prevQuestion) => {
        const currentIndex = prevQuestion.correctOptionIndexes.indexOf(index);
        const newIndexes = [...prevQuestion.correctOptionIndexes];
        const newAnswers = [...prevQuestion.answers];
        if (currentIndex === -1) {
          newIndexes.push(index);
          newAnswers.push(prevQuestion.options[index]);
          return { ...prevQuestion, correctOptionIndexes: newIndexes, answers: newAnswers };
        }
        newIndexes.splice(currentIndex, 1);
        newAnswers.splice(currentIndex, 1);
        return { ...prevQuestion, correctOptionIndexes: newIndexes, answers: newAnswers };
      });
    }
  };

  const handleRadioChange = (event) => {
    let index = event.target.value;
    index = index && parseInt(index, 10);
    handleIndexChange(index);
  };

  const handleAnswerChange = (event) => {
    const value = event.target.value;
    setQuestion((prevQue) => { return { ...prevQue, answer: value }; });
  };

  const handleChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setQuestion((prevQuestion) => {
      const newElement = value;
      return { ...prevQuestion, [elName]: newElement };
    });
  };

  const handleCombineChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setQuestion((prevQuestion) => {
      const preValue = prevQuestion[elName];
      return { ...prevQuestion, [elName]: getCombineValue(preValue, value, position) };
    });
  };

  const setFocus = (elName) => {
    setName(elName);
  };

  const handleSubmit = () => {
    const data = { ...question };
    if ([
      QUESTION_TYPES.SINGLE_CHOICE,
      QUESTION_TYPES.MULTIPLE_CHOICE,
      QUESTION_TYPES.BOOLEAN,
    ].includes(question.questionType) && question.correctOptionIndexes.length === 0) {
      setSnack({
        open: true,
        severity: 'warning',
        message: 'Please mark correct option!',
      });
      return;
    }
    if ([QUESTION_TYPES.INTEGER, QUESTION_TYPES.TEXT, QUESTION_TYPES.FILL_IN_THE_BLANK].includes(question.questionType)
    && !question.answer) {
      setSnack({
        open: true,
        severity: 'warning',
        message: 'Please add answer!',
      });
      return;
    }
    if ([QUESTION_TYPES.INTEGER, QUESTION_TYPES.TEXT, QUESTION_TYPES.FILL_IN_THE_BLANK].includes(question.questionType)
    && question.answer) {
      data.answers = [question.answer];
    }
    if ([
      QUESTION_TYPES.SINGLE_CHOICE,
      QUESTION_TYPES.MULTIPLE_CHOICE,
      QUESTION_TYPES.BOOLEAN,
    ].includes(question.questionType)) {
      data.answers = data.correctOptionIndexes.map((index) => { return data.options[index]; });
    }
    setLoading(true);
    questionValidationSchema.validate(data)
      .then((validatedData) => { return insertQuestionInPaper(validatedData); })
      .then(() => {
        setSnackData({
          open: true,
          severity: 'success',
          message: 'Question Added Successfully!',
        });
        setQuestionData({});
        setLoading(false);
        handleReset();
      })
      .catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  const closeSnack = () => {
    setSnack({});
  };

  const goBack = () => {
    setQuestionData(question);
    handleBack();
  };

  const handleMarksChange = (e) => {
    const ename = e.target.name;
    const value = e.target.value;
    setQuestion((prevData) => {
      const marks = prevData.marks;
      marks[ename] = (value === undefined || value === '') ? '' : parseFloat(value);
      return { ...prevData, marks };
    });
  };

  const handleMarksOnBlur = (e) => {
    const ename = e.target.name;
    const value = e.target.value;
    setQuestion((prevData) => {
      const marks = prevData.marks;
      marks[ename] = (value === undefined || value === '')
        ? question.marksMapping[question.questionType][ename] : parseFloat(value);
      return { ...prevData, marks };
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
  }, []);

  React.useEffect(() => {
    if (question.standard) {
      const stdObj = standards.find((std) => { return std._id === question.standard; });
      setStandard(stdObj);
    }
  }, [question.standard]);

  return (
    <>
      <Snack
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <Loader
        loading={loading}
      />
      <div className={classes.topContainer}>
        <TitleBar title="Add Answer" />
        <div className={classes.container}>
          <Paper elevation={0} className={classes.paperContainer}>
            <Question
              question={question}
              handleIndexChange={handleIndexChange}
              handleRadioChange={handleRadioChange}
              handleAnswerChange={handleAnswerChange}
              title="Que"
              justifyContent="center"
              noDivider
            />
          </Paper>
        </div>
        <TitleBar title="Add Marks" />
        <div className={classes.container}>
          <Grid container justifyContent="flex-start">
            <TextField
              name="correct"
              value={question.marks.correct}
              type="number"
              variant="outlined"
              label="Correct"
              size="small"
              className={classes.textField}
              onChange={handleMarksChange}
              onBlur={handleMarksOnBlur}
            />
            <TextField
              name="incorrect"
              value={question.marks.incorrect}
              type="number"
              variant="outlined"
              label="Incorrect"
              size="small"
              className={classes.textField}
              onChange={handleMarksChange}
              onBlur={handleMarksOnBlur}
            />
            <TextField
              name="unattempted"
              value={question.marks.unattempted}
              type="number"
              variant="outlined"
              label="Unattempted"
              size="small"
              className={classes.textField}
              onChange={handleMarksChange}
              onBlur={handleMarksOnBlur}
            />
          </Grid>
        </div>
        <TitleBar title="Add Subject & Chapter" />
        <div className={classes.container}>
          <Grid>
            <Select
              data={(standard && standard.subjects) || []}
              value={question.subjectName}
              handleChange={handleChange}
              title="Subject"
              name="subjectName"
              isArray
              disabled={paper.subjectName !== FULL_TEST}
            />
            {
              question.subjectName !== FULL_TEST
                ? (
                  <ChapterSelect
                    handleChange={handleChange}
                    value={question.chapter}
                    standard={question.standard}
                    standardName={question.standardName}
                    subjectName={question.subjectName}
                    refreshPage={() => { return setRefresh(!refresh); }}
                  />
                ) : null
            }
          </Grid>
        </div>
        <TitleBar
          title={
            [QUESTION_TYPES.TEXT, QUESTION_TYPES.FILL_IN_THE_BLANK].includes(question.questionType)
              ? 'Add Answer' : 'Add Solution'
          }
        />
        <div className={classes.container}>
          {
            QUESTION_TYPES.FILL_IN_THE_BLANK === question.questionType ? (
              <Grid>
                <InputField
                  title="Enter Answer"
                  name="answer"
                  value={question.answer}
                  handleChange={handleChange}
                />
              </Grid>
            )
              : (
                <>
                  <ShowFunction name={name} handleChange={handleCombineChange} />
                  <ContentEditor
                    name={question.questionType === QUESTION_TYPES.TEXT ? 'answer' : 'solution'}
                    html={question.questionType === QUESTION_TYPES.TEXT ? question.answer : question.solution}
                    handleChange={handleChange}
                    setParentFocus={setFocus}
                    setPosition={setPosition}
                    minHeight={100}
                  />
                </>
              )
          }
        </div>
        <Grid container justifyContent="center" alignItems="center">
          <Paper elevation={0} className={classes.paper}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <AppButton
                  onClick={goBack}
                  title="Back"
                  startIcon={<NavigateBeforeIcon />}
                />
              </Grid>
              <Grid item>
                <AppButton
                  onClick={handleSubmit}
                  title="Submit"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    question: state.question.data,
    standards: state.standards.data,
    paper: state.paper.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
