import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      cursor: 'pointer',
    },
    card: {
      backgroundColor: '#fff',
      borderRadius: '3px',
      textAlign: 'left',
      marginTop: '12px',
      padding: 16,
      width: '100%',
      boxShadow: theme.boxShadow,
    },
  };
});

const HtmlTooltip = withStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: '100%',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  };
})(Tooltip);

function DisplayNote(props) {
  const { note, setEditNote } = props;
  const createdBy = note.createdBy;
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.container} onClick={() => { setEditNote(note); }}>
        <HtmlTooltip title={note.note} arrow>
          <Paper elevation={0} className={classes.card}>
            <Typography variant="subtitle1" style={{ maxWidth: 500 }} noWrap><i>{note.note}</i></Typography>
            <Grid container justifyContent="space-between">
              {
                createdBy
                && (
                <Typography variant="caption" color="primary" display="block" noWrap>
                  <i>{createdBy.name}</i>
                </Typography>
                )
              }
              <Typography variant="caption" display="block" noWrap>
                <i>{note.lastUpdatedDate && new Date(note.lastUpdatedDate).toDateString()}</i>
              </Typography>
            </Grid>
          </Paper>
        </HtmlTooltip>
      </Grid>
    </>
  );
}

export default (DisplayNote);
