import { combineReducers } from 'redux';

import progressReducer from './progressReducer';
import standardReducer from './standardReducer';
import questionReducer from './questionReducer';
import subjectReducer from './subjectReducer';
import exampaperReducer from './exampaperReducer';
import authReducer from './authReducer';
import snackReducer from './snackReducer';
import fileReducer from './fileReducer';
import availabilityReducer from './availabilityReducer';
import userReducer from './userReducer';
import meetingReducer from './meetingReducer';
import contentReducer from './contentReducer';
import batchReducer from './batchReducer';
import userMappingReducer from './userMappingReducer';
import noteReducer from './noteReducer';
import announcementReducer from './announcementReducer';
import dailyActivityReducer from './dailyActivityReducer';
import accessReducer from './accessReducer';

export default combineReducers({
  progress: progressReducer,
  standards: standardReducer,
  question: questionReducer,
  subjects: subjectReducer,
  paper: exampaperReducer,
  auth: authReducer,
  snack: snackReducer,
  file: fileReducer,
  availability: availabilityReducer,
  user: userReducer,
  meeting: meetingReducer,
  content: contentReducer,
  batch: batchReducer,
  userMapping: userMappingReducer,
  note: noteReducer,
  announcement: announcementReducer,
  activity: dailyActivityReducer,
  access: accessReducer,
});
