import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import Actions from '../../actions';

import { getCombineValue } from '../../utils/util';

import ContentEditor from '../Editor/ContentEditor';
import ShowFunction from '../Functions/Display/ShowFunction';
import AppButton from './AppButton';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingRight: 15,
    },
    buttonContainer: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
    moveEnable: {
      textTransform: 'none',
      color: theme.secondaryColor,
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        background: theme.hoverColor,
        color: theme.secondaryColor,
      },
    },
    calButton: {
      color: '#fff',
      background: theme.secondaryColor,
      boxShadow: theme.boxShadow,
      '&:hover': {
        background: theme.secondaryColor,
      },
    },
    textButton: {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#fff',
    },
    active: {
      opacity: 1,
      marginTop: 16,
    },
    inactive: {
      opacity: 0,
      pointerEvents: 'none',
      marginTop: 16,
    },
  };
});

const DailyActivity = (props) => {
  const {
    userId, getDailyActivity, insertOrUpdateDailyActivity, dailyActivity, resetDailyActivity, loadUser, user, setSnack,
    isDisabled, placeholder,
  } = props;
  const classes = useStyles();
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const getInitialState = (obj = {}) => {
    return {
      _id: obj._id,
      user: obj.user,
      content: obj.content || '',
    };
  };
  const [position] = React.useState({ start: 0, end: 0 });
  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };
  const [name, setName] = React.useState('');
  const [data, setData] = React.useState(getInitialState());
  const [date, setDate] = React.useState(now);

  const handleChange = (e) => {
    const value = e.target.value;
    const elName = e.target.name;
    setData((prevData) => { return { ...prevData, [elName]: value }; });
  };

  const setFocus = (elName) => {
    setName(elName);
  };

  const handleCombineChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setData((prevContent) => {
      const preValue = prevContent[elName];
      return { ...prevContent, [elName]: getCombineValue(preValue, value, position) };
    });
  };

  const handleSubmit = () => {
    if (!data.content) {
      setSnack({
        message: 'Please type your activity!',
        severity: 'warning',
      });
      return null;
    }
    return insertOrUpdateDailyActivity({ ...data, date })
      .then(() => {
        setSnack({
          message: 'Your activity saved successfully!',
          severity: 'success',
        });
      });
  };

  const CustomInputDate = React.forwardRef(
    ({ value, onClick }, ref) => {
      return (
        <Button
          variant="contained"
          onClick={onClick}
          endIcon={<CalendarTodayIcon />}
          ref={ref}
          className={classes.calButton}
        >
          <Typography className={classes.textButton}>{value}</Typography>
        </Button>
      );
    },
  );

  const addDay = (day) => {
    const newDate = moment(date).add(day, 'day').toDate();
    setDate(newDate);
    if (data.content) setData(getInitialState({ content: ' ' }));
  };

  React.useEffect(() => {
    getDailyActivity({ user: userId, date });
  }, [date]);

  React.useEffect(() => {
    if (dailyActivity) {
      setData(getInitialState(dailyActivity));
      resetDailyActivity();
    }
  }, [dailyActivity]);

  React.useEffect(() => {
    if (!user) loadUser();
  }, [user]);

  return (
    <>
      <Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <DatePicker
              selected={date}
              onChange={(newDate) => { return setDate(newDate); }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dateFormat="EEE, MMMM d, yyyy"
              customInput={<CustomInputDate />}
              withPortal
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" className={classes.buttonContainer}>
          <Grid item>
            <Button startIcon={<NavigateBeforeIcon />} className={classes.moveEnable} onClick={() => { addDay(-1); }}>
              Prev Date
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<NavigateNextIcon />} className={classes.moveEnable} onClick={() => { addDay(1); }}>
              Next Date
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <div className={name === 'content' ? classes.active : classes.inactive}>
            <ShowFunction name={name} handleChange={handleCombineChange} />
          </div>
          <ContentEditor
            name="content"
            html={data.content}
            handleChange={handleChange}
            setParentFocus={setFocus}
            setPosition={setPosition}
            minHeight={200}
            isDisabled={isDisabled}
            placeholder={placeholder || 'Add daily activity'}
          />
        </Grid>
        {
            user && userId && user._id !== userId ? null
              : (
                <Grid container justifyContent="center">
                  <AppButton title="Save" onClick={handleSubmit} />
                </Grid>
              )
        }
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    dailyActivity: state.activity.data,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyActivity);
