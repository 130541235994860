import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Actions from '../../actions';

import Snackbar from './Snack';
import Loader from './BackdropLoader';

function Progress(props) {
  const {
    snackData, error, resetError, resetSnack, loading, resetLoading,
  } = props;
  const [snack, setSnack] = React.useState({});

  const closeSnack = () => {
    setSnack({});
  };

  React.useEffect(() => {
    if (error) {
      setSnack({
        open: true,
        severity: 'error',
        message: (error.message) || 'Server Error!',
      });
      if (loading) resetLoading();
    } else if (!error && snackData) {
      setSnack({
        open: true,
        severity: snackData.severity || 'success',
        message: snackData.message || 'Success!',
      });
    }
    if (error) resetError();
    if (snackData) resetSnack();
  }, [resetSnack, resetError, error, snackData, resetLoading, loading]);

  return (
    <>
      <Snackbar
        open={snack.open}
        handleClose={closeSnack}
        severity={snack.severity}
        message={snack.message}
      />
      <Loader loading={loading} />
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    error: state.progress.error,
    snackData: state.progress.snack,
    loading: state.progress.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
