/* eslint-disable no-console */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import EventIcon from "@material-ui/icons/Event";

import CircularLoader from "../Progress/CircularLoader";
import EmptyCard from "./EmptyCard";
import Meeting from "./Meeting";

import Actions from "../../actions";

import { filterMeeting } from "../../utils/util";

import AppIconButton from "../App/IconButton";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 8,
        marginRight: 8,
      },
    },
    container: {
      marginBottom: 45,
    },
  };
});

const UpcomingMeeting = (props) => {
  const { fetchMeetings, meetings, fetchingMeetings, batch } = props;
  const classes = useStyles();

  const filteredMeetings = filterMeeting(meetings);

  console.log(filteredMeetings);
  console.log(meetings);

  React.useEffect(() => {
    fetchMeetings({ batch });
  }, [batch]);

  return (
    <>
      <Grid className={classes.root}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="stretch"
          style={{ marginBottom: 15 }}
        >
          <Grid item style={{ marginBottom: 15 }}>
            <AppIconButton
              icon={<EventIcon />}
              href="/dashboard/schedule-meeting"
              dataTour="schedule"
              title="Schedule Class"
            />
          </Grid>
        </Grid>
        <div className={classes.container} data-tour="today-class">
          {fetchingMeetings ? (
            <CircularLoader loading={fetchingMeetings} />
          ) : (
            <>
              {!meetings || meetings.length === 0 ? (
                <EmptyCard text="No Classes scheduled today yet. Please schedule a Class." />
              ) : null}
              {meetings &&
                meetings.map((meeting) => {
                  return (
                    <Meeting key={meeting._id} meetingData={meeting} noEdit />
                  );
                })}
            </>
          )}
        </div>
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    fetchingMeetings: state.meeting.fetchingMeetings,
    fetchingOtherMeetings: state.meeting.fetchingOtherMeetings,
    meetings: state.meeting.meetings,
    otherMeetings: state.meeting.otherMeetings,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingMeeting);
