import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import ContentEditor from '../Editor/ContentEditor';
import AppButton from '../App/AppButton';

// eslint-disable-next-line import/no-cycle
import ShowFunctions from './Display/ShowFunction';

import { getCombineValue } from '../../utils/util';

const NAMES = {
  LEFT: 'left',
  RIGHT: 'right',
  DATA: 'data',
};

const useStyles = makeStyles(() => {
  return {
    table: {
      margin: 0,
      padding: 0,
    },
    cell: {
      border: 'none',
      padding: 0,
      margin: 0,
    },
    tends: {
      fontSize: 20,
      height: 50,
    },
    text: {
      verticalAlign: 'top',
      textAlign: 'center',
      width: '25%',
    },
    symbol: {
      marginTop: 10,
    },
  };
});

const Limit = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const classes = useStyles();
  const [data, setData] = React.useState({
    [NAMES.LEFT]: '',
    [NAMES.RIGHT]: '',
    [NAMES.DATA]: '',
  });
  const [focusedElement, setFocusedElement] = React.useState(NAMES.DATA);
  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const handleContentChange = (event) => {
    const dataName = event.target.name;
    const value = event.target.value;
    setData((preData) => {
      const newData = { ...preData };
      newData[dataName] = value;
      return newData;
    });
  };

  const handleSubmit = () => {
    const value = `<table style="display: inline-table; border-collapse: collapse; vertical-align: middle;">
    <tr>
          <td>&nbsp;</td>
        </tr>
    <tr>
          <td style="line-height: 1.0;"><i>lim</i> &nbsp;&nbsp; ${data[NAMES.DATA]}</td>
        </tr>
    <tr>
      <td style="vertical-align: top;">
     <i>${data[NAMES.LEFT] || 'x'}</i><span>&nbsp;&#8594;&nbsp;</span><i>${data[NAMES.RIGHT] || '0'}</i>
      </td>
    </tr>
    </table>&nbsp;`;
    closeModal();
    handleChange({ target: { name, value } });
  };

  const setFocus = (elementName) => {
    setFocusedElement(elementName);
  };

  const getData = (event) => {
    const elName = event.target.name;
    const value = event.target.value;
    setData((prevData) => {
      const newElement = getCombineValue(prevData[elName], value, position);
      return { ...prevData, [elName]: newElement };
    });
  };

  return (
    <>
      <ShowFunctions handleChange={getData} name={focusedElement} />
      <Grid>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={[classes.cell, classes.text].join(' ')}>
                <Grid className={classes.symbol}>
                  <Typography variant="h5"><i>lim</i></Typography>
                </Grid>
              </TableCell>
              <TableCell className={classes.cell}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs>
                    <ContentEditor
                      name={NAMES.DATA}
                      html={data[NAMES.DATA]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                      isAutoFocus
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan="2" className={classes.cell}>
                <Grid container justifyContent="center">
                  <Grid item md={5} sm={5} xs={5}>
                    <ContentEditor
                      name={NAMES.LEFT}
                      html={data[NAMES.LEFT]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                      placeholder="x"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    md={2}
                    sm={2}
                    xs={2}
                    className={classes.tends}
                    justifyContent="center"
                    alignItems="center"
                  >
                    &nbsp; &#8594; &nbsp;
                  </Grid>
                  <Grid item md={5} sm={5} xs={5}>
                    <ContentEditor
                      name={NAMES.RIGHT}
                      html={data[NAMES.RIGHT]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                      placeholder="0"
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid container justifyContent="center">
        <AppButton onClick={handleSubmit} />
      </Grid>
    </>
  );
};

export default Limit;
