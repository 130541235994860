/* eslint-disable no-console */
import React from "react";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
// import Link from '@material-ui/core/Link';

import AppBar from "../../components/AppBar";
import TitleBar from "../../components/TitleBar";
import Batches from "../../components/Display/Batches";
import UpcomingMeeting from "../../components/Display/UpcomingMeeting";
import SnackBar from "../../components/Progress/Snack";
import NavigationButton from "../../components/Home/NavigationButton";
// import CalendarAuth from '../components/Authentication/CalendarAuth';
import Tour from "../../components/Tour/Tour";
import Workspace from "../../components/Display/Workspace";
import { TOUR_TYPES } from "../../utils/constants";

const Home = () => {
  const [refresh, setRefresh] = React.useState(false);
  const [snack, setSnack] = React.useState({});

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const closeSnack = () => {
    setSnack({});
  };

  return (
    <>
      <SnackBar
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <AppBar>
        <Tour type={TOUR_TYPES.TEACHER_HOME} />
        <Container maxWidth={false}>
          <Grid>
            {/* <Link href="/meeting" style={{ color: 'red', forntWeight: 'bold', fontSize: 16 }}>
              Start Live Class
            </Link> */}
          </Grid>
          <Grid>
            <TitleBar title="Today's Classes" />
            {/* <CalendarAuth handleOpen={handleOpen} handleClose={handleClose} open={open} /> */}
            <UpcomingMeeting />
          </Grid>
          <TitleBar title="My Workspace" />
          <Workspace />
          <TitleBar title="My Batches" />
          <NavigationButton refreshPage={refreshPage} />
          <Batches
            refresh={refresh}
            setSnack={setSnack}
            refreshPage={refreshPage}
          />
        </Container>
      </AppBar>
    </>
  );
};

export default Home;
