/* eslint-disable import/no-cycle */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import Fraction from '../Fraction';
import SpecialSymbols from '../SpecialSymbols';
import SVG from '../SVG';
import SqureRoot from '../SquareRoot';
import Determinant from '../Determinant';
import Metrics from '../Metrics';
import Table from '../Table';
import TransparentTable from '../TransparentTable';
import Brackets from '../Brackets';
import Limit from '../Limit';
import Integration from '../Integration';
import Sum from '../Sum';
import UnitVector from '../UnitVector';
import Vector from '../Vector';
import ChemicalEquation from '../ChemicalEquation';
import UploadImage from '../UploadImage';
import Superscript from '../Superscript';
import Subscript from '../Subscript';
import CubeRoot from '../CubeRoot';
import Modal from '../../Modal/Modal';

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      minWidth: 200,
      height: 55,
    },
    bookmarksCotainer: {
      minHeight: 15,
    },
    bookmarkItem: {
      margin: 8,
    },
    bookmarkButton: {
      paddingTop: 0,
      paddingBottom: 0,
      background: theme.secondaryColor,
      color: '#fff',
      fontSize: 12,
      fontWeight: 'bold',
      paddingLeft: 15,
      '&:hover': {
        background: theme.lightSecondaryColor,
        color: '#fff',
      },
      borderRadius: 0,
      height: 30,
    },
    bookmarkRemoveButton: {
      background: theme.secondaryColor,
      color: '#fff',
      fontSize: 12,
      fontWeight: 'bold',
      '&:hover': {
        background: theme.lightSecondaryColor,
        color: '#fff',
      },
      height: 30,
      borderRadius: 0,
      paddingLeft: 5,
      paddingRight: 5,
    },
    select: {
      boxShadow: theme.boxShadow,
      background: '#fff',
    },
    iconButton: {
    },
    starIcon: {
      color: theme.warningColor,
    },
    item: {
      height: 50,
    },
    label: {
    },
  };
});

const ShowFunction = (props) => {
  const { name, handleChange } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState([]);
  const [openIndex, setOpenIndex] = React.useState(-1);
  const [selectFunction, setSelectFunction] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);

  const closeModal = () => {
    setOpen([]);
    setSelectFunction('');
  };

  const getBookmarkFuntions = () => {
    let bookmarkedFunctions = localStorage.getItem('bookmark_functions');
    if (bookmarkedFunctions) bookmarkedFunctions = JSON.parse(bookmarkedFunctions);
    else bookmarkedFunctions = [];
    return bookmarkedFunctions;
  };

  let bookMarks = getBookmarkFuntions();

  const openModal = (index) => {
    setOpenIndex(index);
    setOpen((preOpen) => {
      const newOpen = [...preOpen];
      newOpen[index] = true;
      return newOpen;
    });
  };

  const handleSelectFunction = (event) => {
    setSelectFunction(event.target.value);
  };

  const FUNCTIONS = [
    {
      name: 'Fraction',
      component: <Fraction name={name} closeModal={closeModal} handleChange={handleChange} />,
      example: '(A/B)',
    },
    {
      name: 'Symbols',
      component: <SpecialSymbols name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Draw Image',
      component: <SVG name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Square Root',
      component: <SqureRoot name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Determinant',
      component: <Determinant name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Metrics',
      component: <Metrics name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Table',
      component: <Table name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Transparent Table',
      component: <TransparentTable name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Brackets',
      component: <Brackets name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Limit',
      component: <Limit name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Integration',
      component: <Integration name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Sum',
      component: <Sum name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Unit Vector',
      component: <UnitVector name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Vector',
      component: <Vector name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Chemical Equation',
      component: <ChemicalEquation name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Upload Image',
      component: <UploadImage name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Superscript',
      component: <Superscript name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Subscript',
      component: <Subscript name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
    {
      name: 'Cube Root',
      component: <CubeRoot name={name} closeModal={closeModal} handleChange={handleChange} />,
    },
  ];

  const handleBookMark = (itemName) => {
    const bookmarkFunctions = getBookmarkFuntions();
    const index = bookmarkFunctions.indexOf(itemName);
    if (index > -1) bookmarkFunctions.splice(index, 1);
    else bookmarkFunctions.push(itemName);
    bookMarks = bookmarkFunctions;
    localStorage.setItem('bookmark_functions', JSON.stringify(bookmarkFunctions));
    setRefresh(!refresh);
  };

  return (
    <>
      <Grid container justifyContent="center" style={{ marginBottom: 15 }}>
        <FormControl variant="outlined" className={classes.formControl} data-tour="function">
          <InputLabel id="simple-select-outlined-label" className={classes.label}>Select Function</InputLabel>
          <Select
            labelId="simple-select-outlined-label"
            id="simple-select-outlined"
            value={selectFunction}
            onChange={handleSelectFunction}
            label="Select Function"
            className={classes.select}
          >
            <MenuItem value="" className={classes.item}>
              <em>Select Function</em>
            </MenuItem>
            {
              FUNCTIONS.sort((a, b) => { return a.name.localeCompare(b.name); }).map((item, index) => {
                return (
                  <MenuItem
                    value={item.name}
                    key={item.name}
                    onClick={() => { openModal(index); }}
                    className={classes.item}
                  >
                    <Grid container justifyContent="space-between" alignItems="center">
                      {item.example ? `${item.name} ${item.example}` : item.name}
                      <Tooltip title={`Add ${item.name} as Favourite`} arrow>
                        <IconButton
                          size="small"
                          onClick={() => { handleBookMark(item.name); }}
                          className={classes.iconButton}
                        >
                          {
                            bookMarks.includes(item.name) ? <StarIcon fontSize="small" className={classes.starIcon} />
                              : <StarBorderIcon fontSize="small" />
                          }
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
        <Modal
          open={open[openIndex] || false}
          handleClose={closeModal}
          title={FUNCTIONS[openIndex] && FUNCTIONS[openIndex].name}
        >
          {FUNCTIONS[openIndex] && FUNCTIONS[openIndex].component}
        </Modal>
        <Grid container justifyContent="center" className={classes.bookmarksCotainer}>
          {
            FUNCTIONS.map((item, index) => {
              return (
                <Grid item key={item.name}>
                  {
                    bookMarks.includes(item.name)
                      ? (
                        <Grid className={classes.bookmarkItem}>
                          <Button
                            className={classes.bookmarkButton}
                            onClick={() => { openModal(index); }}
                          >
                            {item.example ? `${item.name} ${item.example}` : item.name}
                          </Button>
                          <Tooltip title={`Remove ${item.name}`} arrow>
                            <IconButton
                              onClick={() => { handleBookMark(item.name); }}
                              size="small"
                              className={classes.bookmarkRemoveButton}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ) : null
                  }
                </Grid>
              );
            })
          }
        </Grid>
      </Grid>
    </>
  );
};

export default ShowFunction;
