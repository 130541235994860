/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */

import PeopleIcon from '@material-ui/icons/People';
// import EventIcon from '@material-ui/icons/EventAvailable';
import LibraryIcon from '@material-ui/icons/LocalLibrary';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PostAddIcon from '@material-ui/icons/PostAdd';

import Tab1 from './Tab1';
// import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab5 from './Tab5';
import Tab6 from './Tab6';

import { ROLES } from '../../../utils/constants';

const getTabs = (props = {}) => {
  const role = localStorage.getItem('role');
  const BATCH_TABS = {
    BATCH_STUDENTS: { title: 'Batch Students', tab: <Tab1 {...props} />, icon: <PeopleIcon /> },
    // MANAGE_ATTENDENCE: { title: 'Manage Attendence', tab: <Tab2 {...props} />, icon: <EventIcon /> },
    ASSIGN_TEACHERS: { title: 'Assign Teachers', tab: <Tab3 {...props} />, icon: <LibraryIcon /> },
    SCHEDULE_CLASSES: { title: 'Schedule Classes', tab: <Tab4 {...props} />, icon: <DateRangeIcon /> },
    ADD_STUDY_MATERIALS: { title: 'Add Study Materials', tab: <Tab5 {...props} />, icon: <ListAltIcon /> },
    ADD_ASSIGNMETS: { title: 'Add Test Papers', tab: <Tab6 {...props} />, icon: <PostAddIcon /> },
  };
  const ROLE_WISE_BATCH_TABS = {
    [ROLES.INSTITTUTE]: [
      BATCH_TABS.BATCH_STUDENTS,
      // BATCH_TABS.MANAGE_ATTENDENCE,
      BATCH_TABS.ASSIGN_TEACHERS,
      BATCH_TABS.SCHEDULE_CLASSES,
      BATCH_TABS.ADD_STUDY_MATERIALS,
      BATCH_TABS.ADD_ASSIGNMETS,
    ],
    [ROLES.SCHOOL]: [
      BATCH_TABS.BATCH_STUDENTS,
      // BATCH_TABS.MANAGE_ATTENDENCE,
      BATCH_TABS.ASSIGN_TEACHERS,
      BATCH_TABS.SCHEDULE_CLASSES,
      BATCH_TABS.ADD_STUDY_MATERIALS,
      BATCH_TABS.ADD_ASSIGNMETS,
    ],
    [ROLES.TEACHER]: [
      BATCH_TABS.BATCH_STUDENTS,
      // BATCH_TABS.MANAGE_ATTENDENCE,
      BATCH_TABS.SCHEDULE_CLASSES,
      BATCH_TABS.ADD_STUDY_MATERIALS,
      BATCH_TABS.ADD_ASSIGNMETS,
    ],
  };

  return ROLE_WISE_BATCH_TABS[role];
};

export default getTabs;
