import React from 'react';
import { useSvgDrawing } from 'react-hooks-svgdrawing';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import AppButton from '../App/AppButton';
import Resize from '../App/Resize';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      border: '1px solid #ddd',
      marginBottom: 15,
      [theme.breakpoints.down('sm')]: {
        width: 310,
      },
    },
  };
});

function SVG(props) {
  const classes = useStyles();
  const { name, handleChange, closeModal } = props;
  const [maxHeight, setMaxHeight] = React.useState(250);
  const [renderRef, draw] = useSvgDrawing({
    penWidth: 2, // pen width
    penColor: '#000', // pen color
    close: false, // Use close command for path. Default is false.
    curve: true, // Use curve command for path. Default is true.
    delay: 10, // Set how many ms to draw points every.
    // fill: '#fff', // Set fill attribute for path. default is `none`
  });

  const print = () => {
    const svgHtml = draw.getSvgXML();
    const value = `&nbsp;<table cellspacing="0" cellpadding="0" border="0"
        style="display: inline-table; border-collapse: collapse; vertical-align: middle;">
          <tbody>
            <tr>
              <td>${svgHtml}</td>
            </tr>
          </tbody>
        </table>&nbsp;`;
    closeModal();
    handleChange({ target: { name, value } });
  };

  const undo = () => {
    draw.undo();
  };

  // Drawing area will be resized to fit the rendering area
  return (
    <>
      <Grid container justifyContent="center">
        <div className={classes.container} ref={renderRef} style={{ height: maxHeight, width: maxHeight }} />
      </Grid>
      <Resize setMaxHeight={setMaxHeight} />
      <Grid container justifyContent="space-evenly">
        <AppButton variant="contained" color="primary" onClick={undo} title="undo" />
        <AppButton variant="contained" color="primary" onClick={print} />
      </Grid>
    </>
  );
}

export default SVG;
