/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import KeyIcon from "@mui/icons-material/Key";

import Delete from "@material-ui/icons/Delete";
import GroupIcon from "@material-ui/icons/Group";
import { Button, Modal } from "@material-ui/core";

import axios from "axios";
import Actions from "../../actions";

import DialogBox from "../Dialog/DialogBox";
import { groupBy } from "../../utils/util";
import AutoCompleteSelect from "../App/AutoCompleteSelect";
import AccessForm from "../App/AccessForm";
import { fetchStandards } from "../../axios/axios";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginBottom: 15,
    },
    loaderContainer: {
      minHeight: 150,
    },
    itemAligment: {
      "@media (max-width:460px)": {
        justifyContent: "center",
      },
      marginBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
    },
    deviceItem: {
      cursor: "pointer",
      width: "25%",
      "@media (max-width:460px)": {
        width: "100%",
      },
    },
    userCard: {
      minHeight: 100,
      textAlign: "left",
      width: "100%",
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: "#555",
      padding: 15,
      "&:hover": {
        color: "#fff",
        background: theme.secondaryColor,
      },
    },
    standard: {
      marginBottom: 30,
      width: "100%",
      boxShadow: theme.boxShadow,
      padding: "10px 15px",
      color: "#555",
    },
    subtitle: {
      fontWeight: "bold",
    },
    container: {
      // paddingLeft: 15,
      // paddingRight: 15,
    },
    text: {
      textAlign: "center",
    },
    loadingContainer: {
      minHeight: 200,
    },
    studentIcon: {
      marginRight: 8,
    },
    formContainer: {
      textAlign: "center",
      maxWidth: 460,
    },
  };
});

function StudentMapping(props) {
  const {
    getStudentMappings,
    refresh,
    mappings,
    loading,
    deleteUserMapping,
    setSnack,
    refreshPage,
    setStudent,
    batch,
    hideTitleBar,
    title,
  } = props;
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});
  const [redirect, setRedirect] = React.useState(false);
  const [groupedMappings, setGroupedMappings] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [standards, setStandards] = useState([]);
  const [accessFormData, setAccessFormData] = useState({
    standardId: "",
    userId: "",
  });

  const handleClick = (user, standard) => {
    setAccessFormData({
      standardId: standard,
      userId: user,
    });
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
    setDeleteData({});
  };

  const confirmDelete = () => {
    setOpenDialog(false);
    deleteUserMapping(deleteData)
      .then(() => {
        setSnack({
          open: true,
          severity: "success",
          message: "Removed student successfully!",
        });
        setDeleteData({});
        refreshPage();
      })
      .catch((err) => {
        setSnack({
          open: true,
          severity: "error",
          message: err.message || "Something went wrong",
        });
      });
  };

  const handleDelete = (_id) => {
    setDeleteData({ _id });
    setOpenDialog(true);
  };

  const setStudentData = (user) => {
    setStudent(user);
    setRedirect(true);
  };

  const handleGrant = async () => {
    const res = await axios.post(
      "http://localhost:8080/api/access/write/give-access",
      {
        user: "668be838f6fde6345cafe1d9",
        productId: "6676717cbfa5ec0986b79c50",
      }
    );

    console.log(res);
  };

  React.useEffect(() => {
    getStudentMappings({ batch });

    const getStandards = async () => {
      const data = await fetchStandards();
      setStandards(data);
      console.log(data);
    };

    getStandards();
  }, [refresh]);

  const standardNames = (groupedMappings && Object.keys(groupedMappings)) || [];

  React.useEffect(() => {
    if (mappings) {
      const grouped = groupBy(mappings, (obj) => {
        return `${obj.standard?.name} (${obj.year} - Batch ${obj.batchId})`;
      });
      setGroupedMappings(grouped);
    }
  }, [mappings]);

  if (redirect) {
    return <Redirect push to="/student" />;
  }

  return (
    <>
      <Button onClick={handleGrant}>Grant</Button>
      <DialogBox
        open={openDialog}
        onClose={onCloseDialog}
        title="Remove Student"
        confirm={confirmDelete}
      >
        Are you sure want to remove this student?
      </DialogBox>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.loadingContainer}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container className={classes.container}>
          {standardNames.map((standard) => {
            return (
              <Grid item xs={12} key={standard}>
                {hideTitleBar ? null : (
                  <Paper elevation={0} className={classes.standard}>
                    <Grid container alignItems="center">
                      <GroupIcon className={classes.studentIcon} />
                      <Typography
                        variant="subtitle1"
                        className={classes.subtitle}
                      >
                        {title || standard}
                      </Typography>
                    </Grid>
                  </Paper>
                )}
                <Grid container className={classes.itemAligment} spacing={3}>
                  {groupedMappings[standard]?.map((obj, i) => {
                    const user = obj.user;
                    console.log(user);
                    console.log(obj);
                    return (
                      <Grid key={user._id} item className={classes.deviceItem}>
                        <Paper className={classes.userCard}>
                          <Grid container justifyContent="space-between">
                            <Grid
                              item
                              xs={10}
                              onClick={() => {
                                setStudentData(user);
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                className={classes.subtitle}
                                noWrap
                              >
                                {user.name}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={2}
                              justifyContent="flex-end"
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  handleDelete(obj._id);
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  handleClick(user._id, obj.standard._id);
                                }}
                              >
                                <KeyIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid
                            onClick={() => {
                              setStudentData(user);
                            }}
                          >
                            <Typography
                              variant="caption"
                              display="block"
                              noWrap
                            >
                              Roll No. {i + 1}
                            </Typography>
                            <Typography
                              variant="caption"
                              display="block"
                              noWrap
                            >
                              {user.phone}
                            </Typography>
                            <Typography
                              variant="caption"
                              display="block"
                              noWrap
                            >
                              {user.email}
                            </Typography>
                            <Typography
                              variant="caption"
                              display="block"
                              noWrap
                            >
                              Role: {user.role}
                            </Typography>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
          {!loading && standardNames.length === 0 ? (
            <Grid container justifyContent="center">
              <Paper elevation={0} className={classes.standard}>
                <Typography className={classes.text}>
                  No student assigned/invited yet. Please invite student.
                </Typography>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      )}
      <AccessForm
        open={openModal}
        handleClose={closeModal}
        standards={standards}
        standardId={accessFormData.standardId}
        userId={accessFormData.userId}
        setSnack={setSnack}
      />
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    mappings: state.userMapping.studentMappings,
    loading: state.userMapping.fetching,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StudentMapping)
);
