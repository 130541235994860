/* eslint-disable max-len */
import React from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Tour from 'reactour';

import { TOUR_CONFIGS, TOUR_TYPES, TOUR_ITEMS } from '../../utils/constants';

const useStyles = makeStyles((theme) => {
  return {
    tour: {
      color: theme.textSecondary,
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'none',
    },
    icon: {
      color: theme.textSecondary,
      fontWeight: 'bold',
      fontSize: 16,
    },
    container: {
      minWidth: 60,
    },
  };
});

const getTourItems = () => {
  const items = localStorage.getItem(TOUR_ITEMS);
  return JSON.parse(items);
};

const setTourItems = (newItems) => {
  localStorage.setItem(TOUR_ITEMS, JSON.stringify(newItems));
};

function AddQuestionTour(props) {
  const { isOpen, title, type } = props;
  const classes = useStyles();
  const [openTour, setOpenTour] = React.useState(false);
  const [initialTourStep, setInitialTourStep] = React.useState(0);
  const [isReady, setIsReady] = React.useState(false);

  const disableBody = (target) => { return disableBodyScroll(target); };

  const enableBody = (target) => { return enableBodyScroll(target); };

  const closeTour = () => {
    setOpenTour(false);
    setInitialTourStep(0);
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
    const items = getTourItems();
    const index = items.indexOf(type);
    if (index > -1) {
      items.splice(index, 1);
      setTourItems(items);
    }
    enableBody('#body');
  };

  const startTour = () => {
    setOpenTour(true);
  };

  React.useEffect(() => {
    if (isOpen) setOpenTour(true);
  }, [isOpen]);

  React.useEffect(() => {
    let items = getTourItems();
    if (!items) {
      items = Object.values(TOUR_TYPES);
      setTourItems(items);
    }
    if (items.includes(type)) setOpenTour(true);
  }, [type]);

  React.useEffect(() => {
    if (!isReady) {
      setTimeout(() => {
        setIsReady(true);
      }, 1500);
    }
  }, [isReady]);

  const accentColor = '#1da1f2';
  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Button onClick={startTour} className={classes.tour}>{title || 'How it works?'}</Button>
      </Grid>
      {
        isReady ? (
          <Tour
            onRequestClose={closeTour}
            steps={TOUR_CONFIGS[type] || []}
            isOpen={openTour}
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            lastStepNextButton={(
              <Grid container alignItems="center" className={classes.container}>
                <span className={classes.tour}>Let&#39;s Start</span>
              </Grid>
            )}
            maskSpace={8}
            disableInteraction
            startAt={initialTourStep}
            scrollDuration={200}
            closeWithMask={false}
            disableFocusLock
            prevButton={(
              <Grid container alignItems="center" className={classes.container}>
                <ArrowBackIcon className={classes.icon} />
                <span className={classes.tour}>
                  {' '}
                  &nbsp;
                  Prev
                </span>
              </Grid>
            )}
            nextButton={(
              <Grid container alignItems="center" className={classes.container}>
                <span className={classes.tour}>
                  Next
                  {' '}
                  &nbsp;
                </span>
                <ArrowForwardIcon className={classes.icon} />
              </Grid>
            )}
          />
        ) : null
      }
    </div>
  );
}

export default AddQuestionTour;
