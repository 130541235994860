import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/images/intelliclick-logo.svg';

const useStyles = makeStyles((theme) => {
  return {
    parth: {
      fontSize: '28px',
      fontWeight: 'bold',
      color: theme.primaryColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
      },
    },
    hub: {
      fontSize: '28px',
      fontWeight: 'bold',
      color: theme.darkSecondaryColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
      },
    },
    logo: {
      background: 'none',
      height: '100%',
      width: 200,
    },
  };
});

const Name = () => {
  const classes = useStyles();
  return (
    <>
      {/* <span className={classes.parth}>PARTH </span>
      <span className={classes.hub}>HUB</span> */}
      <img
        src={logo}
        className={classes.logo}
        alt="logo"
      />
    </>
  );
};

export default Name;
