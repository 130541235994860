import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FilterListIcon from '@material-ui/icons/FilterList';

import { ALL, FULL_TEST } from '../../utils/util';

import Select from '../Select';

const useStyles = makeStyles(() => {
  return {
    root: {
      marginTop: 16,
      marginBottom: 16,
    },
  };
});

const Filter = (props) => {
  const {
    standard, setStandard, subjectName, setSubjectName, standards, years, isFullTest, isYears, setYear, year,
  } = props;
  const [subjectNames, setSubjectNames] = React.useState([]);
  const classes = useStyles();

  const handleStandardChange = (e) => {
    const value = e.target.value || ALL;
    setStandard(value);
    localStorage.setItem('filterStandard', value);
    setSubjectName(ALL);
    if (setYear) setYear(ALL);
  };

  const handleSubjectChange = (e) => {
    setSubjectName(e.target.value || ALL);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value || ALL);
  };

  React.useEffect(() => {
    if (standard && standard !== ALL && standards) {
      const findStandard = standards.find((obj) => { return obj._id === standard; });
      const standardSubjects = findStandard?.subjects || [];
      setSubjectNames(isFullTest ? [FULL_TEST, ...standardSubjects] : standardSubjects);
    }
  }, [standards, standard]);

  return (
    <Grid container style={{ marginRight: 8 }}>
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2} className={classes.root}>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <FilterListIcon />
            </Grid>
            <Grid item>
              <Typography>Filter</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Select
            data={[{ _id: ALL, name: ALL }, ...standards]}
            value={standard}
            handleChange={handleStandardChange}
            title="Standard"
          />
        </Grid>
        <Grid item>
          <Select
            data={[ALL, ...subjectNames]}
            value={subjectName}
            handleChange={handleSubjectChange}
            title="Subject"
            isArray
          />
        </Grid>
        {
          isYears
            ? (
              <Grid item>
                <Select
                  data={[ALL, ...years]}
                  value={year}
                  handleChange={handleYearChange}
                  title="Year"
                  isArray
                />
              </Grid>
            ) : null
        }
      </Grid>
    </Grid>
  );
};

export default Filter;
