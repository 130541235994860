import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import LinkIcon from '@material-ui/icons/Link';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import config from '../../../config/config';

import Actions from '../../../actions';

import Loader from '../../Progress/Loader';

import Modal from '../../Modal/Modal';
import TitleBar from '../../TitleBar';
import Select from '../../Select';
import InputField from '../../InputField';
import ContentEditor from '../../Editor/ContentEditor';
import ShowFunction from '../../Functions/Display/ShowFunction';
import AppButton from '../../App/AppButton';
import TopicAccordion from '../../App/TopicAccordion';
import ChapterSelect from '../../App/ChapterSelect';
import Upload from '../../App/Upload';
import Tour from '../../Tour/Tour';

import {
  topicValidationSchema, getCombineValue, capitalizeFirstWord, getDays,
} from '../../../utils/util';
import { CHAPTER_UTILS, CONTENT_TYPES, TOUR_TYPES } from '../../../utils/constants';

const useStyles = makeStyles((theme) => {
  return {
    buttonContainer: {
      width: 260,
      padding: 15,
      marginTop: 30,
      [theme.breakpoints.down('sm')]: {
        width: 260,
      },
    },
    submit: {
      marginTop: 30,
      marginBottom: 30,
    },
    heading: {
      fontWeight: 600,
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 15,
    },
    topContainer: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    paperContainer: {
      padding: '0 15px',
      boxShadow: theme.boxShadow,
    },
    optionContainer: {
      marginBottom: 30,
    },
    linkButton: {
      marginBottom: 8,
      background: theme.secondaryColor,
      paddingLeft: 30,
      paddingRight: 30,
      fontWeight: 'bold',
      color: '#fff',
      '&:hover': {
        background: theme.secondaryColor,
      },
    },
    linkObjContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      marginBottom: 8,
    },
    linkContainer: {
      marginBottom: 8,
    },
    linkContainer2: {
      marginTop: 8,
    },
    card: {
      minHeight: 0,
    },
    paper: {
      boxShadow: theme.boxShadow,
      margin: '0 15px 15px 15px',
      padding: 15,
    },
    displayPaper: {
      minHeight: 200,
      marginLeft: 15,
      marginRight: 15,
      marginBottom: 30,
      padding: 15,
      boxShadow: theme.boxShadow,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    noque: {
      height: 200,
    },
  };
});

function Step2(props) {
  const {
    handleBack, insertTopic, setSnack, topics, getTopics, deleteTopic, chapters, fileData, resetFileData,
  } = props;
  const propsContent = props.content || {};
  const classes = useStyles();

  const getInitialState = (data = {}) => {
    return {
      ...data,
      chapter: data.chapter || '',
      chapterName: data.chapterName || '',
      name: data.name || '',
      documentUrls: data.documentUrls || [],
      videoUrls: data.videoUrls || [],
      uploadedFiles: data.uploadedFiles || [],
      content: data.content || '',
      order: data.order || 10,
      type: data.type || '',
      documentUrl: '',
      documentName: 'Document 1',
      videoUrl: '',
      videoName: 'Video 1',
    };
  };

  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [openDocumentModal, setOpenDocumnetModal] = React.useState(false);
  const [openVideoModal, setOpenVideoModal] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [content, setContent] = React.useState(getInitialState(propsContent));
  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const handleChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    if (elName === 'type') {
      const chapter = CHAPTER_UTILS.find((obj) => { return obj._id === value; });
      const type = chapter && chapter.type;
      const chapterName = type !== CONTENT_TYPES.CHAPTER ? (chapter && chapter.name) : '';
      const order = (chapter && chapter.order) || 10;
      setContent({
        ...content,
        chapterName,
        chapter: '',
        type,
        order,
      });
    } else if (elName === 'chapter') {
      const chapterObj = chapters && chapters.find((obj) => { return obj._id === value; });
      const chapterName = chapterObj && chapterObj.name;
      setContent({
        ...content,
        chapterName,
        chapter: value,
      });
    } else {
      setContent((prevContent) => {
        return { ...prevContent, [elName]: value };
      });
    }
  };

  const handleCombineChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setContent((prevContent) => {
      const preValue = prevContent[elName];
      return { ...prevContent, [elName]: getCombineValue(preValue, value, position) };
    });
  };

  const setFocus = (elName) => {
    setName(elName);
  };

  const handleSubmit = () => {
    const data = { ...content };
    topicValidationSchema.validate(data)
      .then((validatedData) => { setLoading(true); return insertTopic(validatedData); })
      .then(() => {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Topic Added Successfully!',
        });
        refreshPage();
        setLoading(false);
        setContent(getInitialState({
          ...propsContent,
          chapter: content.chapter,
          chapterName: content.chapterName,
          type: content.type,
          order: content.order,
          content: ' ',
        }));
      })
      .catch((err) => {
        setLoading(false);
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  const goBack = () => {
    handleBack();
  };

  const closeDocumentModal = () => {
    setOpenDocumnetModal(false);
  };

  const closeVideoModal = () => {
    setOpenVideoModal(false);
  };

  const addDocumentUrl = () => {
    setContent((prevContent) => {
      const url = prevContent.documentUrl || (prevContent.documentUrl).trim();
      const docName = prevContent.documentName || (prevContent.documentName).trim();
      const documentUrls = content.documentUrls || [];
      documentUrls.push({ name: docName, url });
      const documentName = `Document ${documentUrls.length + 1}`;
      return {
        ...prevContent, documentUrls, documentUrl: '', documentName,
      };
    });
    closeDocumentModal();
  };

  const addVideoUrl = () => {
    setContent((prevContent) => {
      const url = prevContent.videoUrl || (prevContent.videoUrl).trim();
      const vidName = prevContent.videoName || (prevContent.videoName).trim();
      const videoUrls = content.videoUrls || [];
      videoUrls.push({ name: vidName, url });
      const videoName = `Video ${videoUrls.length + 1}`;
      return {
        ...prevContent, videoUrls, videoUrl: '', videoName,
      };
    });
    closeVideoModal();
  };

  const addUploadedFile = (url, fileName) => {
    setContent((prevContent) => {
      const uploadedFiles = content.uploadedFiles || [];
      uploadedFiles.push({ url, name: fileName });
      return {
        ...prevContent, uploadedFiles,
      };
    });
  };

  const removeDocumentUrl = (index) => {
    setContent((prevContent) => {
      const documentUrls = content.documentUrls || [];
      documentUrls.splice(index, 1);
      const documentName = `Document ${documentUrls.length + 1}`;
      return { ...prevContent, documentUrls, documentName };
    });
  };

  const removeVideoUrl = (index) => {
    setContent((prevContent) => {
      const videoUrls = content.videoUrls || [];
      videoUrls.splice(index, 1);
      const videoName = `Video ${videoUrls.length + 1}`;
      return { ...prevContent, videoUrls, videoName };
    });
  };

  const removeUploadedFile = (index) => {
    setContent((prevContent) => {
      const uploadedFiles = content.uploadedFiles || [];
      uploadedFiles.splice(index, 1);
      return { ...prevContent, uploadedFiles };
    });
  };

  const onEdit = (editObject) => {
    setContent(getInitialState({ ...editObject, chapter: editObject.chapter && editObject.chapter._id }));
  };

  React.useEffect(() => {
    getTopics({ subjectName: content.subjectName, standard: content.standard });
  }, [content.subjectName, content.standard, refresh]);

  React.useEffect(() => {
    if (fileData && fileData.fileName) {
      const { fileName, originalName } = fileData;
      const url = `${config.fileUrl}/${fileName}`;
      addUploadedFile(url, originalName);
      resetFileData();
    }
  }, [fileData]);

  return (
    <>
      <Loader
        loading={loading}
      />
      <Tour type={TOUR_TYPES.ADD_CONTENT} title="How to add content?" />
      <Modal open={openDocumentModal} handleClose={closeDocumentModal}>
        <InputField
          title="Enter Document Name"
          name="documentName"
          value={content.documentName}
          handleChange={handleChange}
        />
        <InputField
          title="Enter Document URL"
          name="documentUrl"
          value={content.documentUrl}
          handleChange={handleChange}
        />
        <Grid container justifyContent="center">
          <AppButton
            onClick={addDocumentUrl}
            title="Add"
            disabled={!content.documentName || !content.documentUrl}
          />
        </Grid>
      </Modal>
      <Modal open={openVideoModal} handleClose={closeVideoModal}>
        <InputField
          title="Enter Video Name"
          name="videoName"
          value={content.videoName}
          handleChange={handleChange}
        />
        <InputField
          title="Enter Video URL"
          name="videoUrl"
          value={content.videoUrl}
          handleChange={handleChange}
        />
        <Grid container justifyContent="center">
          <AppButton
            onClick={addVideoUrl}
            title="Add"
            disabled={!content.videoName || !content.videoUrl}
          />
        </Grid>
      </Modal>
      <div className={classes.topContainer}>
        <TitleBar title="Add Content" />
        <div className={classes.container}>
          <Select
            title="Content Type"
            data={CHAPTER_UTILS}
            name="type"
            value={content.type}
            handleChange={handleChange}
            dataTour="content-type"
          />
          {
            content.type === CONTENT_TYPES.CHAPTER
              ? (
                <Grid>
                  <ChapterSelect
                    handleChange={handleChange}
                    value={content.chapter}
                    standard={content.standard}
                    standardName={content.standardName}
                    subjectName={content.subjectName}
                    refreshPage={refreshPage}
                  />
                </Grid>
              ) : null
          }
          <InputField
            title={`Enter ${content.type === CONTENT_TYPES.CHAPTER ? 'Topic' : capitalizeFirstWord(content.type)} Title`}
            name="name"
            value={content.name}
            handleChange={handleChange}
          />
          <Grid>
            <Select
              title="Practice Day (optional)"
              data={getDays()}
              name="day"
              value={content.day}
              handleChange={handleChange}
              prefix="Day"
              isArray
            />
          </Grid>
          <Grid style={{ marginTop: 8 }}>
            {name ? <ShowFunction name={name} handleChange={handleCombineChange} /> : null}
            <ContentEditor
              name="content"
              html={content.content}
              handleChange={handleChange}
              setParentFocus={setFocus}
              setPosition={setPosition}
              minHeight={100}
              placeholder="Add topic content"
            />
          </Grid>
          <Grid container justifyContent="center" style={{ marginBottom: 8 }}>
            <Grid item md={4} sm={8} xs={12} style={{ padding: '0 15px 15px 15px' }}>
              <Grid data-tour="upload">
                <Upload
                  acceptedFiles={['application/pdf']}
                  maxFileSize={50000000}
                  showPreviewsInDropzone={false}
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" className={classes.linkContainer} alignItems="center">
              <Grid item>
                <Typography style={{ marginBottom: 8, fontWeight: 'bold' }}>
                  {content.uploadedFiles && content.uploadedFiles.length > 0 ? 'Uploaded PDF Files: ' : 'Upload PDF Files'}
                </Typography>
              </Grid>
              <Grid item className={classes.linkObjContainer}>
                {
                  content.uploadedFiles.map((urlObj, index) => {
                    return (
                      <Chip
                        key={`${urlObj.name} ${index + 1}`}
                        label={(
                          <Link href={urlObj.url} target="_blank">
                            <Grid container alignItems="center">
                              <AttachFileIcon fontSize="small" />
                              {' '}
                              {`${urlObj.name}`}
                            </Grid>
                          </Link>
                        )}
                        onDelete={() => { removeUploadedFile(index); }}
                      />
                    );
                  })
                }
              </Grid>
            </Grid>
          </Grid>
          <div>
            <Grid container>
              <AppButton
                variant="contained"
                startIcon={<LinkIcon />}
                className={classes.linkButton}
                onClick={() => { setOpenDocumnetModal(true); }}
                data-tour="document-link"
              >
                Add Document Links
              </AppButton>
              <Grid item container className={classes.linkContainer}>
                <Grid item className={classes.linkObjContainer}>
                  {
                    content.documentUrls.map((urlObj, index) => {
                      return (
                        <Chip
                          key={`${urlObj.name} ${index + 1}`}
                          label={
                            <Link href={urlObj.url} target="_blank">{`${urlObj.name}`}</Link>
                          }
                          onDelete={() => { removeDocumentUrl(index); }}
                        />
                      );
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <AppButton
                variant="contained"
                startIcon={<LinkIcon />}
                className={classes.linkButton}
                onClick={() => { setOpenVideoModal(true); }}
                data-tour="video-link"
              >
                Add Video Links
              </AppButton>
              <Grid item container className={classes.linkContainer}>
                <Grid item className={classes.linkObjContainer}>
                  {
                    content.videoUrls.map((urlObj, index) => {
                      return (
                        <Chip
                          key={`${urlObj.name} ${index + 1}`}
                          label={
                            <Link href={urlObj.url} target="_blank">{`${urlObj.name}`}</Link>
                          }
                          onDelete={() => { removeVideoUrl(index); }}
                        />
                      );
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container justifyContent="center" alignItems="center">
          <Paper elevation={0} className={classes.buttonContainer}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <AppButton
                  onClick={goBack}
                  title="Back"
                />
              </Grid>
              <Grid item>
                <AppButton
                  onClick={handleSubmit}
                  title="Submit"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid>
          <TitleBar title="Added Topics" />
          <Paper elevation={0} className={classes.paper}>
            <Grid container justifyContent="center" className={classes.card} alignItems="center">
              <Grid item md={6} sm={6} xs={12}>
                <Typography>
                  Standard:
                  <b>
                    {' '}
                    {content.standardName}
                  </b>
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Typography>
                  Subject:
                  <b>
                    {' '}
                    {content.subjectName}
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <TopicAccordion
            setSnackData={setSnack}
            topics={topics}
            deleteTopic={deleteTopic}
            refreshPage={refreshPage}
            onEdit={onEdit}
          />
        </Grid>
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    content: state.content.data,
    chapters: state.content.chapters,
    topics: state.content.topics,
    fileData: state.file.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
