import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import { OPTIONS, MAX_HEIGHTS } from '../../utils/constants';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Checkbox color="default" {...props} />; });

const useStyles = makeStyles(() => {
  return {
    container: {
      marginTop: 15,
      marginBottom: 15,
    },
  };
});

const Resize = (props) => {
  const { setMaxHeight } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const handleCheckbox = (index) => {
    const newChecked = [];
    newChecked[index] = true;
    setMaxHeight(MAX_HEIGHTS[index]);
    setChecked(newChecked);
  };

  React.useState(() => {
    setChecked([false, true, false]);
    setMaxHeight(MAX_HEIGHTS[1]);
  }, []);

  return (
    <>
      <Grid className={classes.container} container justifyContent="space-between">
        {
          OPTIONS.map((option, index) => {
            return (
              <FormControlLabel
                key={option}
                control={(
                  <GreenCheckbox
                    checked={checked[index] || false}
                    onChange={() => { return handleCheckbox(index); }}
                  />
                )}
                label={option}
              />
            );
          })
        }
      </Grid>
    </>
  );
};

export default Resize;
