/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable object-curly-newline */
import * as React from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@material-ui/core";
import AutoCompleteSelect from "./AutoCompleteSelect";
import { grantAccess } from "../../axios/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AccessForm({
  open,
  handleClose,
  standards,
  standardId,
  userId,
  setSnack,
}) {
  const [data, setData] = React.useState({
    user: userId,
    productId: standardId,
    endAt: "",
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setData({
        ...data,
        productId: standardId,
        user: userId,
      });
    }
  }, [open]);

  const handleStandardChange = (e, newObj) => {
    setData({
      ...data,
      productId: newObj?._id,
    });
  };

  const handleDateChange = (e) => {
    setData({
      ...data,
      endAt: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await grantAccess(data);
      setSnack({ open: true, severity: "success", message: `Access granted` });
      handleClose();
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "1rem" }}
          >
            Grant Access
          </Typography>
          <AutoCompleteSelect
            title="Standard"
            data={standards}
            name="standard"
            value={data.productId}
            handleChange={handleStandardChange}
          />
          <div style={{ marginTop: "1rem" }}>
            <div style={{ color: "grey" }}>Expiring at:</div>
            <input
              id="endAt"
              type="date"
              onChange={handleDateChange}
              style={{ padding: "16px", width: "100%", marginTop: "8px" }}
            />
          </div>
          <Button
            style={{
              padding: "6px",
              backgroundColor: "#609cec",
              marginTop: "2rem",
              color: "white",
              width: "10rem",
            }}
            onClick={handleSubmit}
          >
            {loading ? "granting..." : "grant"}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
