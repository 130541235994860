import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => {
  return {
    formControl: {
      minWidth: 120,
      background: '#fff',
      marginTop: 8,
      marginBottom: 8,
    },
  };
});

export default function InputField(props) {
  const {
    name, value, handleChange, title, type, handleBlur,
  } = props;
  const classes = useStyles();

  const onBlur = () => {
    if (handleBlur) handleBlur();
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <TextField
          type={type || 'text'}
          onChange={handleChange}
          label={title}
          variant="outlined"
          name={name}
          value={value}
          onBlur={onBlur}
        />
      </FormControl>
    </div>
  );
}
