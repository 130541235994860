import React from 'react';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Logout from '../components/Authentication/Logout';

import Appstore from '../assets/images/appstore.png';
import Playstore from '../assets/images/playstore.png';

const Login = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
        <Grid container item xs={12} justifyContent="center">
          <Grid container item md={2} sm={3} xs={6} justifyContent="center">
            <Link href="https://play.google.com/store/apps/details?id=com.intelliclick" target="_blank">
              <img src={Playstore} height="45" width="auto" alt="playstore" />
            </Link>
          </Grid>
          <Grid container item md={2} sm={3} xs={6} justifyContent="center">
            <Link href="https://play.google.com/store/apps/details?id=com.intelliclick" target="_blank">
              <img src={Appstore} height="45" width="auto" alt="appstore" />
            </Link>
          </Grid>
        </Grid>
        <Logout />
      </Grid>
    </>
  );
};

export default Login;
