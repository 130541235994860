import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { QUESTION_TYPES } from '../../utils/constants';
import Html from './Html';

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Radio color="default" {...props} />; });

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Checkbox color="default" {...props} />; });

const useStyles = makeStyles((theme) => {
  return {
    typography: {
      fontSize: 16,
      fontFamily: '"Times New Roman", Courier, Garamond, serif',
    },
    optionLabel: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    title: {
      fontWeight: 'bold',
    },
    textField: {
      paddingTop: 15,
    },
    queSpace: {
      paddingBottom: 15,
      paddingTop: 15,
    },
    correct: {
      fontWeight: 'bold',
      color: theme.successColor,
      marginRight: 16,
    },
    incorrect: {
      fontWeight: 'bold',
      color: theme.errorColor,
      marginRight: 16,
    },
    unattempted: {
      fontWeight: 'bold',
      color: theme.warningColor,
    },
  };
});

export default function Options(props) {
  const {
    question, justify, noDivider,
  } = props;
  const classes = useStyles();
  const {
    questionType, options, correctOptionIndexes, answer, answers, solution,
  } = question;

  const disabled = true;

  return (
    <>
      <Grid container justifyContent={justify}>
        <Grid container className={classes.queSpace}>
          <Grid item md={12} sm={12} xs={12}>
            {
              questionType === QUESTION_TYPES.BOOLEAN || questionType === QUESTION_TYPES.SINGLE_CHOICE
                ? (
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="options"
                      name="answer"
                      value={correctOptionIndexes[0] !== undefined ? correctOptionIndexes[0] : ''}
                      inputprops={disabled ? { readOnly: true } : {}}
                    >
                      {
                          options && options.map((option, index) => {
                            return (
                              <FormControlLabel
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                value={index}
                                control={<GreenRadio />}
                                label={<Html html={option} />}
                              />
                            );
                          })
                      }
                    </RadioGroup>
                  </FormControl>
                ) : null
            }
            {
              questionType === QUESTION_TYPES.MULTIPLE_CHOICE
                ? (
                  <FormControl component="fieldset">
                    {
                        options && options.map((option, index) => {
                          const isChecked = correctOptionIndexes.includes(index);
                          return (
                            <FormControlLabel
                            // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              control={(
                                <GreenCheckbox
                                  checked={isChecked}
                                  name={index.toString()}
                                  inputprops={disabled ? { readOnly: true } : {}}
                                />
                              )}
                              label={<Html html={option} />}
                            />
                          );
                        })
                    }
                  </FormControl>
                ) : null
            }
            {
              questionType === QUESTION_TYPES.INTEGER || (disabled && questionType === QUESTION_TYPES.TEXT)
              || (disabled && questionType === QUESTION_TYPES.FILL_IN_THE_BLANK)
                ? (
                  <FormControl component="fieldset" className={classes.textField}>
                    {
                      questionType === QUESTION_TYPES.INTEGER
                        ? (
                          <TextField
                            id="answer"
                            label={disabled ? 'Answer' : 'Enter Answer'}
                            name="answer"
                            variant="outlined"
                            value={disabled ? answers[0] : answer}
                            type={questionType === QUESTION_TYPES.INTEGER ? 'number' : 'text'}
                            required
                            inputprops={disabled ? { readOnly: true } : {}}
                          />
                        ) : (
                          <div style={{ display: 'flex' }}>
                            <Typography component="span" className={classes.title}>
                              Ans:&nbsp;
                            </Typography>
                            <Html html={answers[0]} />
                          </div>
                        )
                    }
                  </FormControl>
                ) : null
            }
            {
                solution && disabled ? (
                  <div style={{ display: 'flex' }}>
                    <Typography component="span" className={classes.title}>
                      Solution:&nbsp;
                    </Typography>
                    <Html html={solution} />
                  </div>
                ) : null
            }
          </Grid>
        </Grid>
      </Grid>
      {
        noDivider ? null : <Divider />
      }
    </>
  );
}
