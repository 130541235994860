import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

// eslint-disable-next-line import/no-cycle
import RowColEntry from './FunctionModels/RowColEntry';

const Determinant = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const [row, setRow] = React.useState('');

  const handleRowChange = (e) => {
    const value = e.target.value;
    setRow((value && parseInt(value, 10)) || '');
  };

  const getHTML = (data) => {
    let htmlForParent = `
      <table style=" display: inline-table; border-left: 2px solid black; border-right: 2px solid black;
      vertical-align: middle "><tbody>`;
    for (let i = 1; i <= row; i += 1) {
      htmlForParent = `${htmlForParent} <tr>`;
      for (let j = 1; j <= row; j += 1) {
        htmlForParent = `${htmlForParent}  <td style="padding:0 10px;text-align:center">
         ${data[`${i}${j}`]}
        </td>`;
      }
      htmlForParent = `${htmlForParent} </tr>`;
    }
    htmlForParent = `${htmlForParent}   </tbody>
   </table>&nbsp;`;

    return htmlForParent;
  };

  return (
    <>
      <Grid container style={{ marginBottom: 30 }} justifyContent="center">
        <Grid container item xs={12} justifyContent="center">
          <TextField
            id="standard-number"
            label="Size"
            name="row"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            onChange={handleRowChange}
            value={row}
            autoFocus
            placeholder="Enter Size"
          />
        </Grid>
      </Grid>
      <RowColEntry
        handleChange={handleChange}
        row={row}
        column={row}
        name={name}
        closeModal={closeModal}
        getHTML={getHTML}
      />
    </>
  );
};

export default Determinant;
