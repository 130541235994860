import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Actions from '../actions';

import Loader from '../components/Progress/Loader';
import PrintQuestion from '../components/Display/PrintQuestion';
import PrintPage from '../components/Display/PrintPage';

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      display: 'inline',
    },
    container: {
      background: '#fff',
      minHeight: '100vh',
      paddingLeft: 60,
      paddingRight: 60,
      paddingTop: 30,
      paddingBottom: 30,
    },
    headerContainer: {
      paddingBottom: 15,
      marginBottom: 15,
      borderBottom: '1px solid #000',
    },
    subTitle: {
      marginTop: 15,
      marginBottom: 5,
    },
    link: {
      color: theme.primaryColor,
      fontSize: 12,
      fontWeight: 'bold',
    },
  };
});

const PrintPaper = (props) => {
  const {
    location, paper, fetching, getPaper,
  } = props;
  const classes = useStyles();
  const urlParams = new URLSearchParams(location.search);
  const paperId = urlParams.get('paperId');
  const token = urlParams.get('token');
  const solution = urlParams.get('solution');

  React.useEffect(() => {
    if (paperId) getPaper({ paperId, token, noShuffle: true });
  }, [paperId]);

  React.useEffect(() => {
    if (paper) {
      setTimeout(() => {
        document.title = paper.name;
        window.print();
      }, 2000);
    }
  }, [paper]);

  return (
    <PrintPage>
      <div className={classes.pageContainer}>
        <Grid className={classes.container}>
          <Loader loading={fetching} />
          {
          !paper ? null
            : (
              <Grid className={classes.headerContainer}>
                <Grid container justifyContent="center">
                  <Typography><b>{paper.name}</b></Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item md={6} sm={6} xs={6}>
                    <Typography variant="body2" className={classes.subTitle}>
                      <b>Max Marks: </b>
                      <b>{paper.maxMarks}</b>
                    </Typography>
                  </Grid>
                  <Grid container item md={6} sm={6} xs={6} justifyContent="flex-end">
                    <Typography variant="body2" className={classes.subTitle}>
                      <b>Time: </b>
                      <b>{`${paper.duration} mins`}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
        }
          {
          paper && paper.questions.map((question, index) => {
            return (
              <PrintQuestion
                key={question._id}
                disabled
                question={question}
                title={`Q${index + 1}`}
                marksMapping={paper.marksMapping}
                noDivider
                shouldDisplayAnswer={!!solution}
              />
            );
          })
        }
          {/* <Grid container justifyContent="flex-end">
            <a href="https://www.intelliclick.in" className={classes.link}>www.intelliclick.in</a>
          </Grid> */}
        </Grid>
      </div>
    </PrintPage>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    paper: state.paper.printPaper,
    fetching: state.paper.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintPaper));
