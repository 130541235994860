import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/Edit';

import Actions from '../../actions';

import Modal from '../Modal/Modal';
import DisplayNote from './DisplayNote';

const useStyles = makeStyles((theme) => {
  return {
    textarea: {
      width: '100%',
      padding: 8,
      borderRadius: 5,
      marginBottom: 15,
    },
    addNoteButton: {
      background: theme.secondaryColor,
      color: '#fff',
      borderRadius: 25,
      marginTop: 0,
      marginBottom: 12,
      boxShadow: theme.boxShadow,
      border: `2px solid ${theme.secondaryColor}`,
      '&:hover': {
        color: theme.secondaryColor,
        background: '#fff',
        boxShadow: theme.boxShadow,
      },
      transition: '0.4s',
      fontWeight: 'bold',
      paddingLeft: 25,
      paddingRight: 25,
    },
    loaderContainer: {
      marginTop: 15,
      marginBottom: 15,
    },
  };
});

function Note(props) {
  const {
    notes, insertOrUpdateNote, user, setSnack, fetchNotes, loading, batch, dashboardUser, loadUser, title,
  } = props;
  const classes = useStyles();
  const [note, setNote] = React.useState({ note: '' });
  const [openNote, setOpenNote] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setNote({ ...note, note: value });
  };

  const closeOpenNote = () => {
    setOpenNote(false);
  };

  const setEditNote = (editNote) => {
    setNote(editNote);
    setOpenNote(true);
  };

  const handleSubmit = () => {
    const value = note.note && note.note.trim();
    if (!value) {
      setSnack({ open: true, severity: 'error', msg: 'Note can not be empty.' });
      return;
    }
    if (!user || !dashboardUser) {
      setSnack({ open: true, severity: 'error', msg: 'Sorry! Missing Data.' });
      return;
    }
    if (note.createdBy && note.createdBy._id !== dashboardUser._id) {
      setSnack({ open: true, severity: 'error', msg: 'Can not edit other educator note!' });
      return;
    }

    insertOrUpdateNote({
      user, note: value, _id: note._id, batch,
    })
      .then(() => {
        setRefresh(!refresh);
        closeOpenNote();
        setSnack({ open: true, severity: 'success', message: 'Note added successfully.' });
      })
      .catch((error) => {
        setSnack({ open: true, severity: 'error', message: error.message || 'Something went wrong!' });
      });
  };

  React.useEffect(() => {
    fetchNotes({ user });
  }, [user, refresh]);

  React.useEffect(() => {
    if (!dashboardUser) loadUser();
  }, [dashboardUser]);

  return (
    <>
      {
        loading
          ? (
            <Grid container justifyContent="center" className={classes.loaderContainer}>
              <CircularProgress color="primary" />
            </Grid>
          )
          : (
            <>
              <Modal handleClose={closeOpenNote} open={openNote} title="User Note">
                <Grid>
                  <div>
                    <TextareaAutosize
                      minRows={5}
                      value={note.note}
                      onChange={handleChange}
                      aria-label="create note"
                      placeholder="Create Note"
                      className={classes.textarea}
                      disabled={!dashboardUser || (note.createdBy && note.createdBy._id !== dashboardUser._id)}
                    />
                  </div>
                  <Grid container justifyContent="center">
                    <Button
                      disabled={!dashboardUser || (note.createdBy && note.createdBy._id !== dashboardUser._id)}
                      variant="outlined"
                      onClick={handleSubmit}
                      className={classes.addNoteButton}
                    >
                      {note._id ? 'Update Note' : 'Add Note'}
                    </Button>
                  </Grid>
                </Grid>
              </Modal>
              <Grid container justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => { setNote({}); setOpenNote(true); }}
                  className={classes.addNoteButton}
                  startIcon={<EditIcon />}
                >
                  {title || 'Add Notes'}
                </Button>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  {
                    notes && notes.map((item) => {
                      return <DisplayNote key={item._id} note={item} setEditNote={setEditNote} />;
                    })
                  }
                </Grid>
              </Grid>
            </>
          )
      }
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    notes: state.note.notes,
    loading: state.note.fetching,
    dashboardUser: state.auth.user,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Note));
