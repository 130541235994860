import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import config from '../../config/config';

import Actions from '../../actions';

import Resize from '../App/Resize';
import Upload from '../App/Upload';

const UploadImage = (props) => {
  const {
    handleChange, name, closeModal, imageData, resetFileData,
  } = props;
  const [maxHeight, setMaxHeight] = React.useState(250);

  React.useEffect(() => {
    if (imageData && imageData.fileName) {
      const fileName = imageData.fileName;
      const imgUrl = `${config.fileUrl}/${fileName}`;
      const value = `&nbsp;<table cellspacing="0" cellpadding="0" border="0"
        style="display: inline-table; border-collapse: collapse; vertical-align: middle;">
          <tbody>
            <tr>
              <td><img style="max-height:${maxHeight}px;width:auto;" src="${imgUrl}" alt="${fileName}" /></td>
            </tr>
          </tbody>
        </table>&nbsp;`;
      handleChange({ target: { name, value } });
      closeModal();
      resetFileData();
    }
  }, [imageData, name]);

  return (
    <>
      <div>
        <Upload
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
          maxFileSize={5000000}
        />
        <Resize setMaxHeight={setMaxHeight} />
      </div>
      {/* <img src={URL.createObjectURL(selectedFile)} alt="name" /> */}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    imageData: state.file.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);
