import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Actions from '../../actions';

import VerticalTab from '../Batch/VerticalTab';
import HorizontalTab from '../Batch/HorizontalTab';

const Batch = (props) => {
  const { batch, location, fetchBatch } = props;
  const urlParams = new URLSearchParams(location.search);
  const batchId = urlParams.get('batchId');

  if (!batchId && !batch) {
    return (<Redirect to="/home" />);
  }

  React.useEffect(() => {
    if (batchId && !batch) fetchBatch({ batchId });
  }, [batchId]);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container justifyContent="center" alignItems="center">
          {
            batch
              ? (
                <>
                  {
                    window.innerWidth < 600 ? <HorizontalTab batch={batch} /> : <VerticalTab batch={batch} />
                  }
                </>
              ) : null
        }
        </Grid>
      </Container>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    batch: state.batch.data,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Batch));
