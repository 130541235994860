import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import TeacherHome from "../pages/Teacher/TeacherHome";
import TeacherBatches from "../pages/Teacher/TeacherBatches";
import CreatePaper from "../pages/CreatePaper";
import AddContent from "../pages/AddContent";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import AllPapers from "../pages/AllPaper";
import AllContents from "../pages/AllContent";
import AllQuestions from "../pages/AllQuestions";
import Announcement from "../pages/Announcement";
// import MarkAvailability from '../pages/MarkAvailability';
import ScheduleMeeting from "../pages/Teacher/ScheduleMeeting";
import DefaultHome from "../pages/DefaultHome";
import PrintPaper from "../pages/PrintPaper";
import Profile from "../pages/Profile";
import Batch from "../pages/Teacher/Batch";
import Student from "../pages/Student";
import Teacher from "../pages/Teacher";
import StudentDoubts from "../pages/StudentDoubts";
import AddDailyActivity from "../pages/AddDailyActivity";
import Students from "../pages/Students";
import Teachers from "../pages/Teachers";

import ScheduleInstituteMeeting from "../pages/Institute/ScheduleInstituteMeeting";
import InstituteHome from "../pages/Institute/InstituteHome";
import InstituteBatches from "../pages/Institute/InstituteBatches";
import InstituteBatch from "../pages/Institute/Batch";

import SchoolHome from "../pages/School/SchoolHome";
import ScheduleSchoolMeeting from "../pages/School/ScheduleSchoolMeeting";
import SchoolBatches from "../pages/School/SchoolBatches";
import SchoolBatch from "../pages/School/Batch";
import Meeting from "../pages/Meeting";

import { ROLES } from "../utils/constants";

const Body = ({ role }) => {
  const currentRole = role || localStorage.getItem("role");
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return <Login />;
        }}
      />
      <Route
        path="/login"
        render={() => {
          return <Login />;
        }}
      />
      <Route
        path="/sign-up"
        render={() => {
          return <Signup />;
        }}
      />
      <Route path="/print-paper" component={PrintPaper} />
      <PrivateRoute path="/profile" component={Profile} />
      {/* <PrivateRoute exact path="/availability" component={MarkAvailability} /> */}
      {currentRole === ROLES.TEACHER ? (
        <>
          <PrivateRoute path="/home" component={TeacherHome} />
          <PrivateRoute path="/schedule-meeting" component={ScheduleMeeting} />
          <PrivateRoute path="/my-students" component={Students} />
          <PrivateRoute path="/add-paper" component={CreatePaper} />
          <PrivateRoute path="/all-papers" component={AllPapers} />
          <PrivateRoute path="/all-contents" component={AllContents} />
          <PrivateRoute path="/add-content" component={AddContent} />
          <PrivateRoute path="/all-questions" component={AllQuestions} />
          <PrivateRoute path="/student" component={Student} />
          <PrivateRoute path="/batches" component={TeacherBatches} />
          <PrivateRoute path="/batch" component={Batch} />
          <PrivateRoute path="/student-doubts" component={StudentDoubts} />
          <PrivateRoute path="/announcement" component={Announcement} />
          <PrivateRoute path="/add-activity" component={AddDailyActivity} />
          <PrivateRoute path="/meeting/:meetingId" component={Meeting} />
        </>
      ) : null}
      {currentRole === ROLES.SCHOOL ? (
        <>
          <PrivateRoute path="/home" component={SchoolHome} />
          <PrivateRoute
            path="/schedule-meeting"
            component={ScheduleSchoolMeeting}
          />
          <PrivateRoute path="/add-paper" component={CreatePaper} />
          <PrivateRoute path="/all-papers" component={AllPapers} />
          <PrivateRoute path="/all-contents" component={AllContents} />
          <PrivateRoute path="/add-content" component={AddContent} />
          <PrivateRoute path="/my-teachers" component={Teachers} />
          <PrivateRoute path="/my-students" component={Students} />
          <PrivateRoute path="/student" component={Student} />
          <PrivateRoute path="/teacher" component={Teacher} />
          <PrivateRoute path="/batches" component={SchoolBatches} />
          <PrivateRoute path="/batch" component={SchoolBatch} />
          <PrivateRoute path="/student-doubts" component={StudentDoubts} />
          <PrivateRoute path="/announcement" component={Announcement} />
          <PrivateRoute path="/meeting/:meetingId" component={Meeting} />
        </>
      ) : null}
      {currentRole === ROLES.INSTITTUTE ? (
        <>
          <PrivateRoute path="/home" component={InstituteHome} />
          <PrivateRoute
            path="/schedule-meeting"
            component={ScheduleInstituteMeeting}
          />
          <PrivateRoute path="/add-paper" component={CreatePaper} />
          <PrivateRoute path="/all-papers" component={AllPapers} />
          <PrivateRoute path="/all-contents" component={AllContents} />
          <PrivateRoute path="/add-content" component={AddContent} />
          <PrivateRoute path="/my-teachers" component={Teachers} />
          <PrivateRoute path="/my-students" component={Students} />
          <PrivateRoute path="/student" component={Student} />
          <PrivateRoute path="/teacher" component={Teacher} />
          <PrivateRoute path="/batches" component={InstituteBatches} />
          <PrivateRoute path="/batch" component={InstituteBatch} />
          <PrivateRoute path="/student-doubts" component={StudentDoubts} />
          <PrivateRoute path="/announcement" component={Announcement} />
          <PrivateRoute path="/meeting/:meetingId" component={Meeting} />
        </>
      ) : (
        <PrivateRoute path="/home" component={DefaultHome} />
      )}
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Body);
