import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import ChatBubbleIcon from '@material-ui/icons/FiberManualRecord';

import ContentEditor from '../Editor/ContentEditor';
import ShowFunction from '../Functions/Display/ShowFunction';

import Modal from '../Modal/Modal';
import AppButton from './AppButton';
import AppButtonSeconday from './AppButtonSeconday';

import { getCombineValue } from '../../utils/util';

const useStyles = makeStyles((theme) => {
  return {
    html: {
      fontSize: 16,
      fontFamily: '"Times New Roman", Courier, Garamond, serif',
      overflow: 'auto',
      height: 120,
      [theme.breakpoints.down('xs')]: {
        height: 200,
      },
      marginTop: 6,
    },
    html2: {
      fontSize: 16,
      fontFamily: '"Times New Roman", Courier, Garamond, serif',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: window.innerWidth - 32,
      },
    },
    card: {
      minHeight: 100,
      textAlign: 'left',
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: '#555',
      padding: 15,
      [theme.breakpoints.down('xs')]: {
        width: window.innerWidth - 64,
      },
      width: '100%',
      height: '100%',
    },
    answered: {
      color: theme.successColor,
    },
    answer: {
      color: theme.redColor,
    },
    text: { textTransform: 'capitalize' },
    title: {
      marginLeft: 10,
    },
    active: {
      opacity: 1,
    },
    inactive: {
      opacity: 0,
      pointerEvents: 'none',
    },
    info: {
      padding: '16px 8px 0px 8px',
    },
  };
});

const Doubt = (props) => {
  const { doubt, insertOrUpdateSolution, refreshPage } = props;
  const classes = useStyles();
  const [currentDoubt, setCurrentDoubt] = React.useState({});
  const [currentAnswer, setCurrentAnswer] = React.useState({});
  const [current, setCurrent] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const isAnswered = currentDoubt.solutions && currentDoubt.solutions.length !== 0;

  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const viewAddSolution = () => {
    setOpen(true);
  };

  const handleCombineChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setCurrentAnswer((prevAnswer) => {
      const preValue = prevAnswer[elName];
      return { ...prevAnswer, [elName]: getCombineValue(preValue, value, position) };
    });
  };

  const handleChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setCurrentAnswer((prevAnswer) => {
      const newElement = value;
      return { ...prevAnswer, [elName]: newElement };
    });
  };

  const submit = () => {
    insertOrUpdateSolution(currentAnswer)
      .then(() => {
        handleClose();
        refreshPage();
      });
  };

  React.useEffect(() => {
    if (doubt) {
      setCurrentDoubt(doubt);
      setCurrentAnswer(doubt.solutions[0] || { question: doubt._id });
    }
  }, [doubt]);

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        title={`${currentDoubt.standard && currentDoubt.standard.name} ${currentDoubt.subjectName}`}
      >
        <Grid container justifyContent="center">
          <Grid
            item
            dangerouslySetInnerHTML={{ __html: currentDoubt && currentDoubt.question }}
            className={classes.html2}
          />
        </Grid>
        <Grid container justifyContent="flex-end">
          <Typography variant="caption">
            <i>
              <b>
                By
                {' '}
                {currentDoubt.createdBy && currentDoubt.createdBy.name}
              </b>
            </i>
          </Typography>
        </Grid>
        <Grid>
          <div className={current === 'solution' ? classes.active : classes.inactive}>
            <ShowFunction name={current} handleChange={handleCombineChange} />
          </div>
          <ContentEditor
            name="solution"
            html={currentAnswer && currentAnswer.solution}
            handleChange={handleChange}
            setParentFocus={() => { setCurrent('solution'); }}
            setPosition={setPosition}
            minHeight={100}
          />
        </Grid>
        <Grid container justifyContent="center">
          <AppButton onClick={submit} disabled={!currentAnswer || !currentAnswer.solution} title="Submit" />
        </Grid>
      </Modal>
      <Paper className={classes.card}>
        <Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs container alignItems="flex-start">
              <Grid item>
                <Tooltip title={isAnswered ? 'Solved' : 'Add Solution'} arrow>
                  <IconButton size="small" onClick={viewAddSolution}>
                    {
                      isAnswered
                        ? <ChatBubbleIcon className={classes.answered} />
                        : <ChatBubbleIcon className={classes.answer} />
                    }
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Typography className={classes.title}>
                  {`${currentDoubt.standard && currentDoubt.standard.name} ${currentDoubt.subjectName} Doubt`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid
            item
            dangerouslySetInnerHTML={{ __html: currentDoubt.question }}
            className={classes.html}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          className={classes.info}
        >
          <Grid item>
            <Typography variant="caption">
              <i>
                <b>
                  By
                  {' '}
                  {currentDoubt.createdBy && currentDoubt.createdBy.name}
                </b>
              </i>
            </Typography>
          </Grid>
          <Grid item>
            <AppButtonSeconday
              isBackground={!!currentAnswer._id}
              onClick={viewAddSolution}
              title={currentAnswer._id ? 'View Solution' : 'Add Solution'}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Doubt;
