import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import Actions from '../../actions';

import TitleBar from '../TitleBar';
import TestResult from '../UserComponents/TestResult';
import Note from '../Note/Note';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: 30,
      marginBottom: 30,
    },
    profileContainer: {
      boxShadow: theme.boxShadow,
      background: '#fff',
      padding: 15,
      marginBottom: 15,
    },
    header: {
      marginBottom: 15,
    },
    avatar: {
      marginRight: 16,
      width: 60,
      height: 60,
      [theme.breakpoints.down('sm')]: {
        marginRight: 8,
        width: 40,
        height: 40,
      },
    },
    name: {
      fontSize: 28,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    noteContainer: {
      paddingLeft: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    empty: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 0px 10px 17px',
      color: '#555',
    },
    text: {
      textAlign: 'center',
    },
    subscriptionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 0',
      gap: 20,
      flexWrap: 'wrap',
    },
    subscriptionCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px 0',
      gap: 2,
    },
    button: {
      padding: '5px 10px',
      borderRadius: 999,
      backgroundColor: theme.secondaryColor,
      color: '#fff',
    },
  };
});

function StudentProfile(props) {
  const {
    student, fetchTestResults, results, loading, getAccess, fetchStandards, standards, access,
  } = props;
  const classes = useStyles();
  const [accessList, setAccessList] = React.useState([]);

  if (!student) {
    return (<Redirect to="/home" />);
  }

  React.useEffect(() => {
    fetchTestResults({ user: student._id });
    fetchStandards();
    getAccess({ userId: student._id });
  }, [student._id]);

  React.useEffect(() => {
    if (access && standards) {
      const list = access.map((acc) => {
        const standard = standards.find((std) => {
          return std._id === acc.productId;
        });
        return {
          className: standard?.name ?? '',
          expiresAt: acc.endAt ?? '',
        };
      });
      setAccessList(list);
    }
  }, [access, standards]);

  const ExpireDateCheck = (date) => {
    if (date.length > 0) {
      return moment(date).isAfter(moment())
        ? `Expires on ${moment(date).format(' DD MMM, YYYY')}`
        : `Expired on ${moment(date).format(' DD MMM, YYYY')}`;
    }
    return 'Expired date not found';
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item md={8} sm={8} xs={12}>
          <Grid item xs={12} className={classes.profileContainer}>
            <Grid container alignItems="center" className={classes.header}>
              <Avatar className={classes.avatar} />
              <Typography className={classes.name}>{student.name}</Typography>
            </Grid>
            <Grid>
              <Typography variant="caption" display="block" noWrap>{student.phone}</Typography>
              <Typography variant="caption" display="block" noWrap>{student.email}</Typography>
              <Typography variant="caption" display="block" noWrap>{student.role}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.profileContainer}>
            <Typography className={classes.name}>
              {student.name}
              {' Subscriptions'}
            </Typography>
            <Grid className={classes.subscriptionContainer}>
              {
                accessList && accessList.length > 0
                  ? accessList.map((item) => {
                    return (
                      <Grid className={classes.subscriptionCard}>
                        <Typography variant="h6" display="block" noWrap>{item.className}</Typography>
                        <Typography variant="caption" display="block" className={classes.button} noWrap>
                          {ExpireDateCheck(item.expiresAt)}
                        </Typography>
                      </Grid>
                    );
                  })
                  : (
                    <Typography className={classes.text}>
                      No subscriptions yet.
                    </Typography>
                  )
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TitleBar title="Attempted Test Results" />
            {
              loading ? <Grid container justifyContent="center" alignItems="center"><CircularProgress /></Grid>
                : (
                  <Grid>
                    {
                      results && results.map((result) => {
                        return (
                          <TestResult key={result._id} result={result} />
                        );
                      })
                    }
                  </Grid>
                )
            }
            {
              !loading && results && results.length === 0
                ? (
                  <Grid container justifyContent="center">
                    <Paper elevation={0} className={classes.empty}>
                      <Typography className={classes.text}>
                        No tests taken yet.
                      </Typography>
                    </Paper>
                  </Grid>
                ) : null
            }
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={12} className={classes.noteContainer}>
          <Note user={student._id} title="Add Student Notes" />
        </Grid>
      </Grid>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    access: state.access.data,
    standards: state.standards.data,
    results: state.user.results,
    loading: state.user.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentProfile));
