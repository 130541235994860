import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: '#fff',
      marginBottom: 40,
    },
    button: {
      background: theme.primaryColor,
      color: '#fff',
      paddingLeft: 35,
      paddingRight: 35,
      paddingTop: 10,
      paddingBottom: 10,
      fontWeight: 'bold',
      marginBottom: 20,
      marginTop: 30,
      '&:hover': {
        background: theme.primaryColor,
        color: '#fff',
      },
      boxShadow: theme.boxShadow,
    },
    spacing: {
      marginTop: 25,
    },
    midText: {
      color: '#6c7172',
      fontSize: 20,
      fontWeight: 600,
      margin: 0,
      padding: 0,
    },
    timeGrid: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexGrow: 1,
      marginBottom: 20,
      marginTop: 40,
    },
    slotsAlloted: {
      padding: 8,
      fontSize: 14,
      color: '#6c7172',
      textAlign: 'center',
      backgroundColor: '#f2f3f3',
    },
    emptySlots: {
      padding: 40,
      fontSize: 16,
      fontWeight: 500,
      color: '#6c7172',
      textAlign: 'center',
    },
    emptySlotsImg: {
      height: 85,
      width: 85,
    },
    paper: {
      color: theme.palette.text.secondary,
    },
    bookedSlot: {
      padding: theme.spacing(2),
      backgroundColor: '#ff7b6f',
      textAlign: 'center',
      color: '#fff',
      fontSize: 16,
      fontWeight: 500,
    },
    notBookedSlot: {
      padding: theme.spacing(2),
      backgroundColor: '#f2f3f3',
      textAlign: 'center',
      color: '#6c7172',
      fontSize: 16,
      fontWeight: 500,
    },
    hourSlotTime: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: '#000',
      fontSize: 16,
      fontWeight: 500,
    },
    hourSlots: {
      marginTop: 25,
      marginBottom: 25,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    box: {
      padding: 8,
      width: 60,
      height: 45,
      display: 'flex',
      borderRadius: 8,
      background: 'white',
      color: '#555',
      justifyContent: 'center',
      textAlign: 'center',
      fontWeight: 600,
      margin: 5,
      '&:hover': {
        background: theme.greenColor,
        color: '#fff',
      },
      boxShadow: theme.boxShadow,
    },
    activeBox: {
      padding: 8,
      width: 60,
      height: 45,
      display: 'flex',
      borderRadius: 8,
      background: theme.greenColor,
      color: '#fff',
      justifyContent: 'center',
      fontWeight: 600,
      margin: 5,
      '&:hover': {
        background: theme.greenColor,
        color: '#fff',
      },
      boxShadow: theme.boxShadow,
    },
    slotBox: {
      paddingLeft: 20,
      [theme.breakpoints.up('md')]: { paddingLeft: 40 },
      [theme.breakpoints.down('sm')]: { paddingLeft: 0 },
    },
    timePicker: {
      color: '#0087cc',
      fontSize: 30,
      textAlign: 'center',
      width: 150,
    },
    input: {
      width: 40,
      paddingLeft: 10,
      paddingRight: 10,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    headingPaper: {
      width: '100%',
      padding: 15,
      textAlign: 'left',
      marginBottom: 30,
      marginTop: 15,
      background: '#fafafa',
    },
    calButton: {
      color: '#fff',
      background: theme.secondaryColor,
      boxShadow: theme.boxShadow,
    },
    calButton2: {
      color: '#fff',
      background: theme.secondaryColor,
      boxShadow: theme.boxShadow,
      '&:hover': {
        background: theme.secondaryColor,
      },
    },
    textButton: {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#fff',
    },
    slotButton: {
      width: '100%',
      borderWidth: 1,
      borderColor: '#e0e0e0',
      padding: 0,
      paddingTop: 15,
      paddingBottom: 15,
    },
    booked: {
      background: '#ff7b6f',
      color: '#fff',
      borderColor: '#ff7b6f',
      '&:hover': {
        background: '#ff7b6f',
        color: '#fff',
        borderColor: '#ff7b6f',
      },
    },
    free: {
      background: '#e0e0e0',
    },
    dateButton: {
    },
    past: {
      opacity: 0.5,
    },
    future: {
      opacity: 1,
    },
    paperContainer: {
      padding: 30,
      boxShadow: theme.boxShadow,
      maxWidth: 600,
      width: '100%',
    },
    linkButton: {
      textTransform: 'none',
      fontSize: 12,
    },
  };
});

export default useStyles;
