import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Edit from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';

import Actions from '../../actions';

import ExamPaper from '../App/Paper';
import Doubt from '../App/Doubt';
import AppIconButton from '../App/IconButton';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 8,
        marginRight: 8,
      },
    },
    itemAligment: {
      '@media (max-width:460px)': {
        justifyContent: 'center',
      },
      marginBottom: 30,
    },
    deviceItem: {
      width: '33.33%',
      '@media (max-width:460px)': {
        width: '100%',
      },
    },
    card: {
      minHeight: 100,
      textAlign: 'left',
      width: '100%',
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: '#555',
      padding: 15,
      height: '100%',
    },
    emptyCard: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 15px',
      color: '#333',
      fontSize: 16,
    },
    published: {
      color: theme.successColor,
    },
    subtitle: {
      fontWeight: 'bold',
      maxWidth: '300px',
    },
    title: {
      marginLeft: 10,
    },
    loadingContainer: {
      minHeight: 0,
    },
  };
});

const Workspace = (props) => {
  const {
    workspace, fetchWorkspaceData, loading, insertOrUpdateSolution,
  } = props;
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(false);
  const [editData, setEditData] = React.useState(null);

  const handleEdit = (topic) => {
    if (!topic) return;
    const data = {
      standard: topic.standard,
      standardName: topic.standardName,
      subjectName: topic.subjectName,
    };
    setEditData(data);
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  React.useEffect(() => {
    fetchWorkspaceData();
  }, [refresh]);

  if (editData) {
    return (
      <Redirect push to={{ pathname: '/add-content', state: { topic: editData } }} />
    );
  }

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="stretch" style={{ marginBottom: 15 }}>
        <Grid item style={{ marginBottom: 15 }}>
          <AppIconButton
            icon={<PostAddIcon />}
            href="/dashboard/add-paper"
            dataTour="create-paper"
            title="Create Paper"
          />
        </Grid>
        <Grid item style={{ marginBottom: 15 }}>
          <AppIconButton
            icon={<LibraryAddIcon />}
            href="/dashboard/add-content"
            dataTour="create-content"
            title="Add Content"
          />
        </Grid>
      </Grid>
      {
        loading ? (
          <Grid container justifyContent="center" alignItems="center" className={classes.loadingContainer}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container className={classes.itemAligment} spacing={3} data-tour="workspace">
            {
              workspace && workspace.papers && workspace.papers.map((obj) => {
                return (
                  <ExamPaper
                    key={obj._id}
                    refreshPage={refreshPage}
                    noDelete
                    paper={obj}
                    title={obj.isDemo ? 'Demo Paper' : 'Paper'}
                  />
                );
              })
            }
            {
              workspace && workspace.topics && workspace.topics.map((obj) => {
                return (
                  <Grid
                    key={obj._id}
                    item
                    className={classes.deviceItem}
                  >
                    <Paper className={classes.card}>
                      <Grid container>
                        <Grid item container justifyContent="space-between">
                          <Grid item xs container alignItems="center">
                            <Tooltip title="Published" arrow>
                              <IconButton size="small">
                                <DoneIcon className={classes.published} />
                              </IconButton>
                            </Tooltip>
                            <Typography className={classes.title}>Content</Typography>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={() => { handleEdit(obj); }}>
                              <Edit fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" className={classes.subtitle} noWrap>
                            {obj.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} sm={6} xs={6}>
                          <Typography variant="caption" display="block" noWrap>
                            <b>Standard</b>
                            {': '}
                            {obj.standardName}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <Typography variant="caption" display="block" noWrap>
                            <b>Subject</b>
                            {': '}
                            {obj.subjectName}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <Typography className={classes.text} variant="caption" display="block" noWrap>
                            <b>Content Type</b>
                            {': '}
                            {obj.type || 'None'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <Typography className={classes.text} variant="caption" display="block" noWrap>
                            <b>Created by</b>
                            {': '}
                            {obj.createdBy && obj.createdBy.name}
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography variant="caption" display="block" noWrap>
                            <b>Last Edit Date</b>
                            {': '}
                            {
                              (obj.updatedAt && new Date(obj.updatedAt).toLocaleTimeString('en-US',
                                {
                                  month: 'short',
                                  day: 'numeric',
                                  year: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                }))
                              || 'None'
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })
            }
            {
              workspace && workspace.latestDoubt
                ? (
                  <Grid item className={classes.deviceItem}>
                    <Doubt
                      doubt={workspace.latestDoubt}
                      insertOrUpdateSolution={insertOrUpdateSolution}
                      refreshPage={refreshPage}
                    />
                  </Grid>
                ) : null
            }
            {
              workspace && workspace.topics && workspace.papers
              && workspace.papers.length === 0 && workspace.topics.length === 0
                ? (
                  <Grid container justifyContent="center">
                    <Paper elevation={0} className={classes.emptyCard}>
                      <Typography align="center">
                        No material found. Please create a paper or add content.
                      </Typography>
                    </Paper>
                  </Grid>
                ) : null
            }
          </Grid>
        )
      }
    </Grid>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    workspace: state.user.workspace,
    loading: state.user.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Workspace));
