import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

// import UndoIcon from '@material-ui/icons/Undo';
// import RedoIcon from '@material-ui/icons/Redo';

import Grid from '@material-ui/core/Grid';

import EditButton from './EditButton';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: 5,
      },
      minHeight: 30,
    },
    hide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    show: {
      opacity: 1,
    },
  };
});

const COMMANDS = [
  {
    cmd: 'italic',
    html: '<i>I</i>',
  },
  {
    cmd: 'bold',
    html: '<strong>B</strong>',
  },
  // {
  //   cmd: 'subscript',
  //   html: 'X<sub>2</sub>',
  // },
  // {
  //   cmd: 'superscript',
  //   html: 'X<sup>2</sup>',
  // },
  // {
  //   cmd: 'undo',
  //   html: '',
  //   icon: <UndoIcon fontSize="small" />,
  // },
  // {
  //   cmd: 'redo',
  //   html: '',
  //   icon: <RedoIcon fontSize="small" />,
  // },
];

const SUP_SUB_COMMANDS = [
  {
    cmd: 'subscript',
    html: 'X<sub>2</sub>',
  },
  {
    cmd: 'superscript',
    html: 'X<sup>2</sup>',
  },
];

const Commands = (props) => {
  const { isFocused, name } = props;
  const classes = useStyles();
  const ITEMS = window.innerWidth > 600 ? [...COMMANDS, ...SUP_SUB_COMMANDS] : COMMANDS;

  return (
    <Grid container spacing={1} className={[classes.container, isFocused ? classes.show : classes.hide].join(' ')}>
      {
        ITEMS.map((item) => {
          const key = `${name}_${item.cmd}`;
          return <EditButton key={key} id={key} cmdData={item} isFocused={isFocused} />;
        })
      }
    </Grid>
  );
};

export default Commands;
