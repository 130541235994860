import React from 'react';

import { makeStyles } from '@material-ui/core';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import AppBar from '../components/AppBar';
import TitleBar from '../components/TitleBar';
import Invite from '../components/App/Invite';
import StudentMapping from '../components/Display/StudentMapping';
import SnackBar from '../components/Progress/Snack';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  };
});

const InstitutesStudents = () => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(false);
  const [snack, setSnack] = React.useState({});

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const closeSnack = () => {
    setSnack({});
  };

  return (
    <>
      <SnackBar
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <AppBar>
        <Container maxWidth={false}>
          <TitleBar title="Your Students" />
          <Grid container justifyContent="flex-end" style={{ marginBottom: 30 }}>
            <Invite userRole="student" refreshPage={refreshPage} />
          </Grid>
          <Grid className={classes.container}>
            <StudentMapping refresh={refresh} setSnack={setSnack} refreshPage={refreshPage} />
          </Grid>
        </Container>
      </AppBar>
    </>
  );
};

export default InstitutesStudents;
