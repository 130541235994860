import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => {
  return {
    card: {
    },
    paper: {
      boxShadow: theme.boxShadow,
      padding: 15,
    },
  };
});

export default function EmptyCard(props) {
  const { text } = props;
  const classes = useStyles();
  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Grid container justifyContent="center" alignItems="center" className={classes.card}>
          <Typography>{text || 'Nothing'}</Typography>
        </Grid>
      </Paper>
    </>
  );
}
