/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(() => {
  return {
    formControl: {
      minWidth: 120,
      marginTop: 8,
      marginBottom: 8,
      background: "#fff",
    },
  };
});

export default function AutoCompleteSelect(props) {
  const {
    name,
    value,
    handleChange,
    data,
    title,
    selectName,
    handleBlur,
    disabled,
    placeholder,
    groupKey,
    showEditIcon,
    editItem,
  } = props;
  const classes = useStyles();

  console.log(data);
  const displayName = selectName || "name";

  const onBlur = () => {
    if (handleBlur) handleBlur();
  };

  const getValue = () => {
    const obj = (data || []).find((o) => {
      return o._id === value;
    });
    return obj || null;
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <Autocomplete
          id="grouped-demo"
          disabled={disabled || false}
          options={data || []}
          name={name}
          value={getValue()}
          onChange={handleChange}
          onBlur={onBlur}
          groupBy={(option) => {
            return groupKey ? option[groupKey] : option.parent;
          }}
          getOptionLabel={(option) => {
            return option[displayName] || "";
          }}
          renderOption={(optionProps, option) => {
            return (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Grid
                {...optionProps}
                container
                spacing={2}
                justifyContent="space-between"
              >
                <Grid item>{option[displayName] || ""}</Grid>
                <Grid item>
                  {showEditIcon ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        editItem(option);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  ) : null}
                </Grid>
              </Grid>
            );
          }}
          renderInput={(params) => {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return (
              <TextField {...params} label={placeholder || `Select ${title}`} />
            );
          }}
        />
      </FormControl>
    </div>
  );
}
