const initialState = {
  fetching: false,
};

export default function imageReducer(currentState = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'INSERTING_OR_UPDATING_ANNOUNCEMENT':
      return { ...currentState, fetching: true };
    case 'INSERTED_OR_UPDATED_ANNOUNCEMENT':
      return { ...currentState, fetching: false };
    case 'FETCHING_USER_ANNOUNCEMENT':
      return { ...currentState, fetching: true };
    case 'FETCHED_USER_ANNOUNCEMENT':
      return { ...currentState, data: payload, fetching: false };
    case 'DELETING_ANNOUNCEMENT':
      return { ...currentState, fetching: true };
    case 'DELETED_ANNOUNCEMENT':
      return { ...currentState, fetching: false };
    default:
      return currentState;
  }
}
