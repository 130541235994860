import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => {
  return {
    card: {
      minHeight: 0,
    },
    paper: {
      padding: 15,
      borderRadius: 0,
      width: '100%',
      marginTop: 25,
    },
    title: {
      fontSize: 18,
    },
    subtitle: {
      color: '#888',
    },
  };
});

const Tab1 = (props) => {
  const batch = props.batch || {};
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Paper elevation={0} className={classes.paper}>
          <Grid container justifyContent="center" className={classes.card} alignItems="center">
            <Grid item>
              <Typography className={classes.title}>
                <b>{`${batch.standardName} (${batch.year} - Batch ${batch.id})`}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.card} alignItems="center">
            <Grid item>
              <Typography variant="caption" className={classes.subtitle}>
                <b>
                  <i>
                    Created by:
                    {' '}
                    {batch.createdBy && batch.createdBy.name}
                  </i>
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default Tab1;
