import config from '../config/config';

const getBracket = (rows, topCode, middleCode, bottomCode) => {
  let html = `<table cellspacing="0" cellpadding="0" border="0"
  style="display: inline-table; border-collapse: collapse; vertical-align:middle;"
  >
        <tr>
          <td style="line-height: 1.0; font-size: 20px;">${topCode}</td>
        </tr>`;
  for (let i = 3; i <= rows; i += 1) {
    html = `${html} <tr>
        <td style="line-height: 1.0; font-size: 20px;">${middleCode}</td>
      </tr>`;
  }
  html = `${html} <tr>
          <td style="line-height: 1.0; font-size: 20px;">${bottomCode}</td>
        </tr>
    </table>&nbsp;`;
  return html;
};

const getImgBracket = (bracketName) => {
  const imgUrl = `${config.fileUrl}/${bracketName}`;
  const html = `<table cellspacing="0" cellpadding="0" border="0"
  style="display: inline-table; border-collapse: collapse; vertical-align:middle;"
  >
        <tr>
          <td><img src="${imgUrl}" alt="${bracketName}" /></td>
        </tr>
    </table>&nbsp;`;
  return html;
};

export const VerticalLine = (rows) => {
  return getBracket(rows, '&#9125;', '&#9125;', '&#9125;');
};

export const RightSquareBracket = (rows) => {
  return getBracket(rows, '&#9124;', '&#9125;', '&#9126;');
};

export const LeftSqeareBracket = (rows) => {
  return getBracket(rows, '&#9121;', '&#9122;', '&#9123;');
};

export const LeftBracket = (rows) => {
  if (rows === 2) return getImgBracket('left_bracket_2.png');
  if (rows === 3) return getImgBracket('left_bracket_3.png');
  return getBracket(rows, '&#9127;', '&#9125;', '&#9129;');
};

export const RightBracket = (rows) => {
  if (rows === 2) return getImgBracket('right_bracket_2.png');
  if (rows === 3) return getImgBracket('right_bracket_3.png');
  return getBracket(rows, '&#9131;', '&#9125;', '&#9133;');
};

export const LeftCurlyBracket = (rows) => {
  if (rows === 3) return getImgBracket('left_curly_bracket_3.png');
  return getBracket(rows, '&#9127;', '&#9128;', '&#9129;');
};

export const RightCurlyBracket = (rows) => {
  if (rows === 3) return getImgBracket('right_curly_bracket_3.png');
  return getBracket(rows, '&#9131;', '&#9132;', '&#9133;');
};
