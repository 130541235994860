import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import IconButton from '@material-ui/core/IconButton';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Actions from '../actions';

import AppBar from '../components/AppBar';
import TitleBar from '../components/TitleBar';
import InputField from '../components/InputField';
import ContentEditor from '../components/Editor/ContentEditor';
import ShowFunction from '../components/Functions/Display/ShowFunction';
import AppButton from '../components/App/AppButton';
import Upload from '../components/App/Upload';
import AutoCompleteSelect from '../components/App/AutoCompleteSelect';
import DialogBox from '../components/Dialog/DialogBox';

import { getCombineValue } from '../utils/util';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2),
    },
  };
})(MuiAccordionDetails);

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    insideContainer: {
      paddingLeft: 30,
      paddingRight: 30,
      marginBottom: 32,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 16,
      },
    },
    active: {
      opacity: 1,
      marginTop: 16,
    },
    inactive: {
      opacity: 0,
      pointerEvents: 'none',
      marginTop: 16,
    },
    textButton: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    calButton: {
      textTransform: 'none',
      background: theme.secondaryColor,
      color: '#fff',
      paddingLeft: 16,
      paddingRight: 16,
      '&:hover': {
        background: theme.lightSecondaryColor,
      },
    },
    dateContainer: {
      marginTop: 16,
      marginBottom: 32,
    },
    linkContainer: {
    },
    expired: {
      background: theme.lightRed,
    },
    alive: {
      background: theme.lightGreen,
    },
    expiredStatus: {
      color: theme.lightRed,
    },
    aliveStatus: {
      color: theme.lightGreen,
    },
  };
});

const Announcement = (props) => {
  const {
    fetchStandards, standards, loadingStandards, fileData, resetFileData, setSnack, insertOrUpdateAnnouncement,
    getUserAnnouncement, announcements, deleteAnnouncement,
  } = props;
  const classes = useStyles();
  const currentTime = new Date();
  currentTime.setHours(0, 0, 0, 0);

  const getInitialData = (obj = {}) => {
    return {
      _id: obj._id,
      title: obj.title || '',
      content: obj.content || '',
      startDate: (obj.startDate && new Date(obj.startDate)) || currentTime,
      endDate: (obj.endDate && new Date(obj.endDate)) || moment(currentTime).add(1, 'month').toDate(),
      uploadedFiles: obj.uploadedFiles || [],
      standards: (obj.standards && obj.standards.map((o) => { return o._id; })) || [],
    };
  };

  const [position] = React.useState({ start: 0, end: 0 });
  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const [data, setData] = React.useState(getInitialData());
  const [name, setName] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [expanded, setExpanded] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [editObject, setEditObject] = React.useState();

  const openDeleteDialog = (_id) => {
    setDeleteId(_id);
    setOpenDialog(true);
  };

  const openEditDialogContent = (obj) => {
    setEditObject(obj);
    setOpenEditDialog(true);
  };

  const onCloseDialog = () => {
    setDeleteId('');
    setOpenDialog(false);
  };

  const onCloseEditDialog = () => {
    setEditObject();
    setOpenEditDialog(false);
  };

  const confirmDelete = () => {
    deleteAnnouncement({ _id: deleteId })
      .then(() => {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Announcement Deleted Successfully!',
        });
        setRefresh(!refresh);
        onCloseDialog();
      });
  };

  const confirmEdit = () => {
    setData(getInitialData(editObject));
    setOpenEditDialog(false);
    setEditObject();
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
  };

  const handleStandardChange = (e, newObj) => {
    setData({
      ...data,
      standards: (newObj && [newObj._id]) || [],
    });
  };

  const handleChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setData({ ...data, [elName]: value });
  };

  const handleCombineChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setData((prevContent) => {
      const preValue = prevContent[elName];
      return { ...prevContent, [elName]: getCombineValue(preValue, value, position) };
    });
  };

  const addUploadedFile = (url, fileName) => {
    setData((prevContent) => {
      const uploadedFiles = data.uploadedFiles || [];
      uploadedFiles.push({ url, name: fileName });
      return {
        ...prevContent, uploadedFiles,
      };
    });
  };

  const removeUploadedFile = (index) => {
    setData((prevContent) => {
      const uploadedFiles = data.uploadedFiles || [];
      uploadedFiles.splice(index, 1);
      return { ...prevContent, uploadedFiles };
    });
  };

  const submit = () => {
    const inputData = { ...data };
    inputData.title = inputData.title && inputData.title.trim();
    if (!inputData.title) {
      setSnack({
        message: 'Title is undefiend! Please enter title.',
        severity: 'warning',
      });
      return null;
    }
    return insertOrUpdateAnnouncement(inputData)
      .then(() => {
        setSnack({
          message: 'Announcement published successfully.',
          severity: 'success',
        });
        setRefresh(!refresh);
        setData(getInitialData({ content: ' ' }));
      });
  };

  const filterPassedTime = (time, date) => {
    const currentDate = date ? new Date(date) : currentTime;
    const selectedDate = new Date(time);
    return currentDate.getTime() <= selectedDate.getTime();
  };

  const handleToggle = (panel) => {
    return (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  };

  const CustomInputDate = React.forwardRef(
    ({ value, onClick }, ref) => {
      return (
        <Grid container justifyContent="center">
          <Button
            onClick={onClick}
            endIcon={<CalendarTodayIcon />}
            ref={ref}
            className={classes.calButton}
          >
            <Typography className={classes.textButton}><b>{value}</b></Typography>
          </Button>
        </Grid>
      );
    },
  );

  React.useEffect(() => {
    if (!standards && !loadingStandards) fetchStandards();
  }, [standards]);

  React.useEffect(() => {
    if (fileData && fileData.fileName) {
      const { originalName, location } = fileData;
      const url = location;
      addUploadedFile(url, originalName);
      resetFileData();
    }
  }, [fileData]);

  React.useEffect(() => {
    getUserAnnouncement();
  }, [refresh]);

  return (
    <>
      <DialogBox open={openDialog} onClose={onCloseDialog} title="Delete this announcement" confirm={confirmDelete}>
        Are you sure want to delete this announcement?
      </DialogBox>
      <DialogBox open={openEditDialog} onClose={onCloseEditDialog} title="Edit this topic" confirm={confirmEdit}>
        Are you sure want to edit this announcement?
      </DialogBox>
      <AppBar>
        <div className={classes.container}>
          <Grid>
            <TitleBar title="Add Announcement" />
            <div className={classes.insideContainer}>
              <InputField
                title="Enter Announcement Title"
                name="title"
                value={data.title}
                handleChange={handleChange}
              />
              <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                <Grid item md={4} sm={8} xs={12} style={{ padding: '0 15px 15px 15px' }}>
                  <Upload
                    acceptedFiles={['application/pdf']}
                    maxFileSize={5000000}
                    showPreviewsInDropzone={false}
                  />
                </Grid>
                <Grid item container justifyContent="center" className={classes.linkContainer} alignItems="center">
                  <Grid item>
                    <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                      {data.uploadedFiles && data.uploadedFiles.length > 0 ? 'Uploaded PDF Files: ' : ''}
                    </Typography>
                  </Grid>
                  &nbsp;
                  <Grid item className={classes.linkObjContainer}>
                    {
                        data.uploadedFiles.map((urlObj, index) => {
                          return (
                            <Chip
                              key={`${urlObj.name} ${index + 1}`}
                              label={(
                                <Link href={urlObj.url} target="_blank">
                                  <Grid container alignItems="center">
                                    <AttachFileIcon fontSize="small" />
                                    {' '}
                                    {`${urlObj.name}`}
                                  </Grid>
                                </Link>
                                )}
                              onDelete={() => { removeUploadedFile(index); }}
                            />
                          );
                        })
                    }
                  </Grid>
                </Grid>
              </Grid>
              <div className={name === 'content' ? classes.active : classes.inactive}>
                <ShowFunction name={name} handleChange={handleCombineChange} />
              </div>
              <ContentEditor
                name="content"
                html={data.content}
                handleChange={handleChange}
                setParentFocus={() => { setName('content'); }}
                setPosition={setPosition}
                minHeight={100}
                placeholder="Add content"
              />
              <Grid>
                <AutoCompleteSelect
                  title="Standard"
                  data={standards}
                  name="standards"
                  value={data.standards[0]}
                  handleChange={handleStandardChange}
                  placeholder={data.standards.length === 0 ? 'For All Standards' : ''}
                />
              </Grid>
              <Grid container className={classes.dateContainer}>
                <Grid item xs={12} md={6} container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <Typography align="center" variant="subtitle2"><b>Set Start Date: &nbsp;</b></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      selected={data.startDate}
                      onChange={(date) => {
                        return setData({ ...data, startDate: date, endDate: moment(date).add(1, 'month').toDate() });
                      }}
                      timeInputLabel="Time:"
                      dateFormat="EEE, MMM d, yyyy h:mm aa"
                      customInput={<CustomInputDate />}
                      dropdownMode="select"
                      showTimeInput
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      filterDate={filterPassedTime}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <Typography align="center" variant="subtitle2"><b>Set End Date: &nbsp;</b></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      selected={data.endDate}
                      onChange={(date) => { return setData({ ...data, endDate: date }); }}
                      timeInputLabel="Time:"
                      dateFormat="EEE, MMM d, yyyy h:mm aa"
                      customInput={<CustomInputDate />}
                      dropdownMode="select"
                      showTimeInput
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      filterDate={(time) => { return filterPassedTime(time, data.startDate); }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 16, marginBottom: 32 }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <AppButton
                      onClick={submit}
                      title={data._id ? 'Update' : 'Publish'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid>
            <TitleBar title="My Accouncements" />
            <Grid className={classes.insideContainer}>
              {
                announcements && announcements.map((obj, index) => {
                  const isAlive = new Date(obj.endDate) >= new Date();
                  return (
                    <Grid key={obj._id}>
                      <Accordion
                        square
                        expanded={expanded === index}
                        onChange={handleToggle(index)}
                      >
                        <AccordionSummary
                          aria-controls={`${index}-header`}
                          id={`${index}-header`}
                          className={isAlive ? classes.alive : classes.expired}
                        >
                          <Grid container justifyContent="space-between" alignItems="center">
                            {
                              expanded === index ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                            }
                            <Grid item>
                              <b>
                                {`${index + 1}. `}
                                &nbsp;
                              </b>
                            </Grid>
                            <Grid container item xs alignItems="center">
                              <Grid item xs>
                                <Typography className={classes.question}>
                                  {obj.title}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={3} justifyContent="space-evenly">
                              <>
                                <IconButton size="small" onClick={() => { openEditDialogContent(obj); }}>
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" onClick={() => { openDeleteDialog(obj._id); }}>
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid style={{ width: '100%' }}>
                            <Grid style={{ width: '100%' }}>
                              <Typography
                                dangerouslySetInnerHTML={{ __html: obj.content }}
                                className={classes.typography}
                              />
                            </Grid>
                            <Grid>
                              <br />
                              <b>Announcement for: </b>
                              {' '}
                              {(obj.standards && obj.standards.map((o) => { return o.name; }).join(', ')) || 'All standards'}
                            </Grid>
                            <Grid>
                              <b>Start Date:</b>
                              {' '}
                              {
                               (new Date(obj.startDate)).toLocaleTimeString('en-US',
                                 {
                                   month: 'short',
                                   day: 'numeric',
                                   year: 'numeric',
                                   hour: 'numeric',
                                   minute: 'numeric',
                                   hour12: true,
                                 })
                              }
                            </Grid>
                            <Grid>
                              <b>End Date:</b>
                              {' '}
                              {
                               (new Date(obj.endDate)).toLocaleTimeString('en-US',
                                 {
                                   month: 'short',
                                   day: 'numeric',
                                   year: 'numeric',
                                   hour: 'numeric',
                                   minute: 'numeric',
                                   hour12: true,
                                 })
                              }
                            </Grid>
                            <Grid container alignItems="center">
                              <b>Status:</b>
                              &nbsp;
                              {isAlive ? 'Active' : 'Inactive'}
                              &nbsp;
                              <FiberManualRecordIcon
                                fontSize="small"
                                className={isAlive ? classes.aliveStatus : classes.expiredStatus}
                              />
                            </Grid>
                            <Grid container alignItems="center" className={classes.linkContainer2}>
                              <Grid><b>Uploaded Files:&nbsp;</b></Grid>
                              <Grid>
                                {
                                obj.uploadedFiles && obj.uploadedFiles.map((urlObj, i) => {
                                  return (
                                    <Chip
                                      key={`${urlObj.name} ${i + 1}`}
                                      label={(
                                        <Link href={urlObj.url} target="_blank">
                                          <Grid container alignItems="center">
                                            <AttachFileIcon fontSize="small" />
                                            {' '}
                                            {`${urlObj.name}`}
                                          </Grid>
                                        </Link>
                                        )}
                                    />
                                  );
                                })
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })
            }
            </Grid>
          </Grid>
        </div>
      </AppBar>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    standards: state.standards.data,
    loadingStandards: state.standards.fetching,
    fileData: state.file.data,
    announcements: state.announcement.data,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Announcement));
