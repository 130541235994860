/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import tabStyle from '../../assets/styles/tabStyle';

import SnackBar from '../Progress/Snack';
import Loader from '../Progress/Loader';
import BatchInfo from './BatchInfo';

import getTabs from './Tabs/tabUtil';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{ pl: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({ batch }) {
  const [value, setValue] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const [snack, setSnack] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const closeSnack = () => {
    setSnack({});
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
  }, [value]);

  return (
    <>
      <SnackBar
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <Loader loading={loading} />
      <BatchInfo batch={batch} />
      <Box
        sx={{
          flexGrow: 1, display: 'flex', minHeight: '100vh', textAlign: 'left',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{
            borderRight: 1, borderColor: 'divider', bgcolor: 'background.paper', minWidth: 260,
          }}
        >
          {
            getTabs().map((tab, index) => {
              const style = (value === index) ? tabStyle.active : tabStyle.inactive;
              return (
                <Tab
                  key={tab.title}
                  label={(
                    <Box
                      style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                      }}
                    >
                      {tab.icon}
                      <Typography variant="body2" sx={{ ...tabStyle.tab2 }}>{tab.title}</Typography>
                    </Box>
                  )}
                  {...a11yProps(index)}
                  style={{
                    display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginBottom: '30px', ...style,
                  }}
                />
              );
            })
        }
        </Tabs>
        {
          getTabs({
            batch, refresh, setSnack, refreshPage, setLoading,
          }).map((tab, index) => {
            return (
              <TabPanel key={tab.title} value={value} index={index}>
                {tab.tab}
              </TabPanel>
            );
          })
        }
      </Box>
    </>
  );
}
