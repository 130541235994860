import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
  VerticalLine, RightSquareBracket, LeftSqeareBracket, LeftBracket, RightBracket, LeftCurlyBracket, RightCurlyBracket,
} from '../../utils/dymaicBrackets';

const BRACKETS = [
  {
    name: 'Vertical Line 2 Rows',
    code: VerticalLine(2),
  },
  {
    name: 'Vertical Line 3 Rows',
    code: VerticalLine(3),
  },
  {
    name: 'Left Bracket 2 Rows',
    code: LeftBracket(2),
  },
  {
    name: 'Right Bracket 2 Rows',
    code: RightBracket(2),
  },
  {
    name: 'Left Bracket 3 Rows',
    code: LeftBracket(3),
  },
  {
    name: 'Right Bracket 3 Rows',
    code: RightBracket(3),
  },
  {
    name: 'Left Curly Bracket 3 Rows',
    code: LeftCurlyBracket(3),
  },
  {
    name: 'Right Curly Bracket 3 Rows',
    code: RightCurlyBracket(3),
  },
  {
    name: 'Left Square Bracket 2 Rows',
    code: LeftSqeareBracket(2),
  },
  {
    name: 'Right Square Bracket 2 Rows',
    code: RightSquareBracket(2),
  },
  {
    name: 'Left Square Bracket 3 Rows',
    code: LeftSqeareBracket(3),
  },
  {
    name: 'Right Square Bracket 3 Rows',
    code: RightSquareBracket(3),
  },
];

const Brackets = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;

  const handleSubmit = (value) => {
    closeModal();
    return handleChange({ target: { name, value } });
  };

  return (
    <>
      {
        BRACKETS.map((item) => {
          return (
            <Tooltip title={item.name} key={item.name} arrow>
              <IconButton
                onClick={() => { handleSubmit(item.code); }}
                style={{ color: '#000' }}
              >
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: item.code }}
                />
              </IconButton>
            </Tooltip>
          );
        })
      }
    </>
  );
};

export default Brackets;
