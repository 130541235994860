import Axios from "axios";
import config from "../config/config";

import { TOKEN } from "../utils/constants";

const URI = config.serverURL;
const DEFAULT_HEADER = {
  headers: {
    "Content-Type": "application/json",
  },
};

const POST = "post";
const GET = "get";

const calling = (url, method, data) => {
  Axios.defaults.headers.common.Authorization = localStorage.getItem(TOKEN);
  if (method === POST) {
    return Axios.post(url, data, DEFAULT_HEADER).then((response) => {
      return response.data;
    });
  }
  return Axios.get(url, { params: data }, DEFAULT_HEADER).then((response) => {
    return response.data;
  });
};

const callApi = (url, method, data) => {
  return calling(url, method, data);
};

export function loadUser() {
  const url = `${URI}/api/user/read/get-user`;
  return callApi(url, GET);
}

export function verify(data) {
  const url = `${URI}/api/user/read/verify`;
  return callApi(url, GET, data);
}

export function fetchStandards() {
  const url = `${URI}/api/standard/read/get-all-standards`;
  return callApi(url, GET);
}

export function insertQuestion(data) {
  const url = `${URI}/api/question/write/insert-question`;
  return callApi(url, POST, data);
}

export function fetchSubjects() {
  const url = `${URI}/api/subject/read//get-all-subjects`;
  return callApi(url, GET);
}

export function addToPaper(data) {
  const url = `${URI}/api/exampaper/update/add-question-in-paper`;
  return callApi(url, POST, data);
}

export function getAllQuestions(paperId) {
  const url = `${URI}/api/exampaper/read-question/get-all-questions`;
  return callApi(url, GET, { paperId });
}

export function getPaper(data) {
  const url = `${URI}/api/exampaper/read/get-all-questions`;
  return callApi(url, GET, data);
}

export function createUserProfile(data) {
  const url = `${URI}/api/authentication/sign-up`;
  return callApi(url, POST, data);
}

export function login(data) {
  const url = `${URI}/api/authentication/login`;
  return callApi(url, POST, data);
}

export function logout() {
  const url = `${URI}/api/authentication/logout`;
  return callApi(url, POST);
}

export const sendOTP = (data) => {
  const url = `${URI}/api/authentication/send-otp`;
  return callApi(url, POST, data);
};

export const verifyOTP = (data) => {
  const url = `${URI}/api/authentication/verify-otp`;
  return callApi(url, POST, data);
};

export function sendInvite(data) {
  const url = `${URI}/api/user/write/invite`;
  return callApi(url, POST, data);
}

export function removeQuestionFromPaper(data) {
  const url = `${URI}/api/exampaper/delete/remove-question`;
  return callApi(url, POST, data);
}

export function getAllPaper() {
  const url = `${URI}/api/exampaper/read/get-all-exams`;
  return callApi(url, GET);
}

export function deactivateExampaper(data) {
  const url = `${URI}/api/exampaper/delete/deactivate-paper`;
  return callApi(url, POST, data);
}

export function updateQuestion(data) {
  const url = `${URI}/api/question/write/update-question`;
  return callApi(url, POST, data);
}

export function getAllQuestionsOfUser() {
  const url = `${URI}/api/question/read/get-all-questions`;
  return callApi(url, GET);
}

export function importQuestion(data) {
  const url = `${URI}/api/exampaper/update/import-questions-in-paper`;
  return callApi(url, POST, data);
}

export function insertOrUpdatePaper(data) {
  const url = `${URI}/api/exampaper/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function fetchPapers(data) {
  const url = `${URI}/api/exampaper/read/get-papers`;
  return callApi(url, GET, data);
}

export function fetchPaper(data) {
  const url = `${URI}/api/exampaper/read/get-paper`;
  return callApi(url, GET, data);
}

export function deletePaper(data) {
  const url = `${URI}/api/exampaper/delete/delete-paper`;
  return callApi(url, POST, data);
}

export function insertQuestionInPaper(data) {
  const url = `${URI}/api/question/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function importQuestionsInPaper(data) {
  const url = `${URI}/api/question/write/import-questions`;
  return callApi(url, POST, data);
}

export function uploadFile(data) {
  const url = `${URI}/api/file/write/upload`;
  return callApi(url, POST, data);
}

export function publishPaper(data) {
  const url = `${URI}/api/exampaper/write/access`;
  return callApi(url, POST, data);
}

export function markAvailability(data) {
  const url = `${URI}/api/availability/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function fetchAvailability() {
  const url = `${URI}/api/availability/read/get-availability`;
  return callApi(url, GET);
}

export function markTimeoff(data) {
  const url = `${URI}/api/availability/write/time-off`;
  return callApi(url, POST, data);
}

export function getStudentMappings(data) {
  const url = `${URI}/api/user-mapping/read/get-student-mappings`;
  return callApi(url, GET, data);
}

export function getTeacherMappings(data) {
  const url = `${URI}/api/user-mapping/read/get-teacher-mappings`;
  return callApi(url, GET, data);
}

export function deleteUserMapping(data) {
  const url = `${URI}/api/user-mapping/delete/delete-mapping`;
  return callApi(url, POST, data);
}

export function insertOrUpdateMeeting(data) {
  const url = `${URI}/api/meeting/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function fetchMeetings(data) {
  const url = `${URI}/api/meeting/read/get-user-meetings`;
  return callApi(url, GET, data);
}

export function fetchOtherMeetings() {
  const url = `${URI}/api/meeting/read/get-meetings`;
  return callApi(url, GET);
}

export function fetchTestResults(data) {
  const url = `${URI}/api/test-result/read/get-all`;
  return callApi(url, GET, data);
}

export function insertTopic(data) {
  const url = `${URI}/api/topic/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function getChapters(data) {
  const url = `${URI}/api/chapter/read/get`;
  return callApi(url, GET, data);
}

export function insertOrUpdateChapter(data) {
  const url = `${URI}/api/chapter/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function getTopics(data) {
  const url = `${URI}/api/topic/read/get`;
  return callApi(url, GET, data);
}

export function getAllTopics(data) {
  const url = `${URI}/api/topic/read/get-all-topics`;
  return callApi(url, GET, data);
}

export function deleteTopic(data) {
  const url = `${URI}/api/topic/delete/delete-topic`;
  return callApi(url, POST, data);
}

export function deleteMeeting(data) {
  const url = `${URI}/api/meeting/delete/delete-meeting`;
  return callApi(url, POST, data);
}

export function fetchMeeting(data) {
  const url = `${URI}/api/meeting/read/get-meeting`;
  return callApi(url, GET, data);
}

export function fetchBatches() {
  const url = `${URI}/api/batch/read/get-batches`;
  return callApi(url, GET);
}

export function deleteBatch(data) {
  const url = `${URI}/api/batch/delete/delete-batch`;
  return callApi(url, POST, data);
}

export function fetchBatch(data) {
  const url = `${URI}/api/batch/read/get-batch`;
  return callApi(url, GET, data);
}

export function addUsers(data) {
  const url = `${URI}/api/batch/write/add-users`;
  return callApi(url, POST, data);
}

export function fetchAttendees(data) {
  const url = `${URI}/api/meeting/read/get-attendees`;
  return callApi(url, GET, data);
}

export function insertOrUpdateNote(data) {
  const url = `${URI}/api/user-note/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function fetchNotes(data) {
  const url = `${URI}/api/user-note/read/get-notes`;
  return callApi(url, GET, data);
}

export function fetchWorkspaceData() {
  const url = `${URI}/api/user/read/workspace`;
  return callApi(url, GET);
}

export function fetchStudentDoubts() {
  const url = `${URI}/api/question/read/get-student-doubts`;
  return callApi(url, GET);
}

export function fetchQuestions(data) {
  const url = `${URI}/api/question/read/get-questions`;
  return callApi(url, POST, data);
}

export function insertOrUpdateSolution(data) {
  const url = `${URI}/api/solution/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function insertOrUpdateAnnouncement(data) {
  const url = `${URI}/api/announcement/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function getUserAnnouncement() {
  const url = `${URI}/api/announcement/read/get-user-announcements`;
  return callApi(url, GET);
}

export function deleteAnnouncement(data) {
  const url = `${URI}/api/announcement/delete/delete-announcement`;
  return callApi(url, POST, data);
}

export function insertOrUpdateDailyActivity(data) {
  const url = `${URI}/api/daily-activity/write/insert-or-update`;
  return callApi(url, POST, data);
}

export function getDailyActivity(data) {
  const url = `${URI}/api/daily-activity/read/get`;
  return callApi(url, GET, data);
}

export function grantAccess(data) {
  const url = `${URI}/api/access/write/give-access`;
  return callApi(url, POST, data);
}

export function getAccess(data) {
  const url = `${URI}/api/access/read/get`;
  return callApi(url, GET, data);
}
