import React from 'react';

import Grid from '@material-ui/core/Grid';

import StudentMapping from '../../Display/StudentMapping';

import Invite from '../../App/Invite';

const Tab1 = (props) => {
  const { refresh, setSnack, refreshPage } = props;
  const batch = props.batch || {};
  return (
    <>
      <Grid container justifyContent="flex-end" style={{ marginTop: 15, marginBottom: 15 }}>
        <Invite
          userRole="student"
          refreshPage={refreshPage}
          standard={batch.standard}
          standardName={batch.standardName}
          batchId={batch.id}
          year={batch.year}
          batch={batch._id}
        />
      </Grid>
      <StudentMapping
        refresh={refresh}
        setSnack={setSnack}
        refreshPage={refreshPage}
        batch={batch._id}
        title="Batch Students"
      />
    </>
  );
};

export default Tab1;
