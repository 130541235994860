const initialState = {
  fetching: false,
};

export default function contentReducer(currentState = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'SET_CONTENT':
      return { ...currentState, data: payload };
    case 'INSERTING_TOPIC':
      return { ...currentState, fetching: true };
    case 'INSERTED_TOPIC':
      return { ...currentState, fetching: false };
    case 'FETCHING_TOPICS':
      return { ...currentState, fetching: true };
    case 'FETCHED_TOPICS':
      return { ...currentState, topics: payload, fetching: false };
    case 'DELETING_TOPIC':
      return { ...currentState, fetching: true };
    case 'DELETED_TOPIC':
      return { ...currentState, fetching: false };
    case 'FETCHING_CHAPTERS':
      return { ...currentState, fetching: true };
    case 'FETCHED_CHAPTERS':
      return { ...currentState, chapters: payload, fetching: false };
    case 'ADDING_CHAPTER':
      return { ...currentState, fetching: true };
    case 'ADDED_CHAPTER':
      return { ...currentState, chapter: payload, fetching: false };
    case 'FETCHING_CONTENTS':
      return { ...currentState, fetching: true };
    case 'FETCHED_CONTENTS':
      return { ...currentState, contents: payload, fetching: false };
    default:
      return currentState;
  }
}
