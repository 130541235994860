import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Button from '@mui/material/Button';
import Actions from '../../actions';

import CircularLoader from '../Progress/CircularLoader';
import QuestionAccordion from './QuestionAccordion';
import EmptyCard from '../Display/EmptyCard';
import AppButton from '../App/AppButton';
import AutoCompleteSelect from '../App/AutoCompleteSelect';

const useStyles = makeStyles(() => {
  return {
    container: {
      width: '100%',
    },
    innerContainer: {
      width: '100%',
      marginTop: 8,
    },
    buttonContainer: {
      width: '100%',
      marginTop: 32,
    },
  };
});

const ImportQuestions = (props) => {
  const {
    standard, subjectName, questions, standards, fetchQuestions, loading, questionIds, paperId,
    importQuestionsInPaper, setSnack, papers, fetchPapers, resetQuestions,
  } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({ subjectName: '', paper: null });
  const [newQuetions, setNewQuestions] = React.useState([]);

  const handleChange = (e, newObj) => {
    setState((prevState) => { return { ...prevState, paper: newObj }; });
  };

  const filterQuestions = () => {
    return questions && questions.filter((que) => {
      return !questionIds.includes(que._id);
    });
  };

  const addOrRemoveQuestion = (questionId) => {
    const ids = [...newQuetions];
    const index = ids.findIndex((id) => { return id === questionId; });
    if (index === -1) ids.push(questionId);
    else ids.splice(index, 1);
    setNewQuestions(ids);
  };

  const importQuestions = () => {
    importQuestionsInPaper({ paperId, questions: newQuetions })
      .then(() => {
        setSnack({
          message: 'Questions are imported successfully!',
          severity: 'success',
        });
      });
  };

  const isAllSelected = () => {
    return questions && newQuetions.length === questions.length;
  };

  const selectAll = () => {
    const ids = (questions && questions.map((question) => { return question._id; })) || [];
    const selectedIds = isAllSelected() ? [] : ids;
    setNewQuestions(selectedIds);
  };

  React.useEffect(() => {
    setNewQuestions([]);
    if (state.paper) {
      fetchQuestions({ questionIds: state.paper.questions });
    }
  }, [state.paper]);

  React.useEffect(() => {
    setNewQuestions([]);
    if (standard) {
      fetchPapers({ standard });
    }
  }, [standard]);

  React.useEffect(() => {
    if (standard && subjectName && standards) {
      const standardObj = standards.find((obj) => { return obj._id === standard; });
      setState({ standard: standardObj, subjectName });
    }
  }, [standard, subjectName, standards]);

  React.useEffect(() => {
    resetQuestions();
    setNewQuestions([]);
  }, []);

  const filteredQuestions = filterQuestions() || [];

  return (
    <>
      <Grid container>
        <Grid item className={classes.container}>
          <AutoCompleteSelect
            title="Paper"
            data={papers || []}
            name="paper"
            value={state.paper && state.paper._id}
            handleChange={handleChange}
            groupKey="subjectName"
          />
        </Grid>
      </Grid>
      <Grid className={classes.innerContainer}>
        <Grid container alignItems="center">
          {
            loading ? <CircularLoader loading={loading} />
              : (
                <>
                  {
                    filteredQuestions.length
                      ? (
                        <Button
                          color="primary"
                          size="medium"
                          onClick={selectAll}
                        >
                          {`${isAllSelected() ? 'Deselect' : 'Select'} All`}
                        </Button>
                      ) : null
                  }
                  <QuestionAccordion
                    questions={filteredQuestions}
                    newQuetions={newQuetions}
                    addOrRemoveQuestion={addOrRemoveQuestion}
                  />
                </>
              )
          }
          <Grid className={classes.container}>
            {
              !loading && filteredQuestions.length === 0 ? <EmptyCard text="No Question" /> : null
            }
          </Grid>
        </Grid>
        <Grid container className={classes.buttonContainer} justifyContent="center">
          <AppButton disabled={newQuetions.length === 0 || loading} title="import" onClick={importQuestions} />
        </Grid>
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    questions: state.question.questions,
    standards: state.standards.data,
    loading: state.question.fetching,
    papers: state.paper.papers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportQuestions);
