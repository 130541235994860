import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

// eslint-disable-next-line import/no-cycle
import RowColEntry from './FunctionModels/RowColEntry';

const Metrics = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const [row, setRow] = React.useState('');
  const [column, setColumn] = React.useState('');

  const handleRowChange = (e) => {
    const value = e.target.value;
    setRow((value && parseInt(value, 10)) || '');
  };

  const handleColumnChange = (e) => {
    const value = e.target.value;
    setColumn((value && parseInt(value, 10)) || '');
  };

  const getHTML = (data) => {
    let htmlForParent = `
       <table style="display: inline-table; border-collapse: collapse; vertical-align: middle;"><tbody>
       <tr style="line-height:0.1"><td style=" border-top:2px solid black; border-left:2px solid black">&nbsp;</td>
       <td colspan="${column}"></td>
       <td style=" border-top:2px solid black; border-right:2px solid black" >&nbsp;</td></tr>`;
    for (let i = 1; i <= row; i += 1) {
      htmlForParent = `${htmlForParent} <tr><td style="border-left:2px solid black"></td>`;
      for (let j = 1; j <= column; j += 1) {
        htmlForParent = `${htmlForParent}  <td style="padding:0 10px; text-align:center">
         ${data[`${i}${j}`]}
        </td>`;
      }
      htmlForParent = `${htmlForParent} <td style="border-right:2px solid black" ></td></tr>`;
    }
    htmlForParent = `${htmlForParent}
      <tr style="line-height:0.1"><td style=" border-bottom:2px solid black; border-left:2px solid black">&nbsp;</td>
       <td colspan="${column}"></td>
       <td style=" border-bottom:2px solid black; border-right:2px solid black" >&nbsp;</td></tr></tbody>
   </table>&nbsp;`;

    return htmlForParent;
  };

  return (
    <>
      <Grid container style={{ marginBottom: 30 }} justifyContent="center">
        <Grid container item xs={6} justifyContent="center">
          <TextField
            id="standard-number"
            label="Rows"
            name="row"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            onChange={handleRowChange}
            value={row}
            autoFocus
            placeholder="Enter Row Size"
          />
        </Grid>
        <Grid container item xs={6} justifyContent="center">
          <TextField
            id="standard-number"
            label="Columns"
            name="column"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            onChange={handleColumnChange}
            value={column}
            placeholder="Enter Column Size"
          />
        </Grid>
      </Grid>
      <RowColEntry
        handleChange={handleChange}
        row={row}
        column={column}
        name={name}
        closeModal={closeModal}
        getHTML={getHTML}
      />
    </>
  );
};

export default Metrics;
