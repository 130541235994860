const initialState = {
  fetching: false,
};

export default function availabilityReducer(currentState = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'FETCHING_BATCHES':
      return { ...currentState, fetching: true };
    case 'FETCHED_BATCHES':
      return { ...currentState, batches: payload, fetching: false };
    case 'ASSIGNING_USERS':
      return { ...currentState, fetching: true };
    case 'ASSIGNED_USERS':
      return { ...currentState, data: payload, fetching: false };
    case 'SET_BATCH':
      return { ...currentState, data: payload };
    case 'FETCHING_BATCH':
      return { ...currentState, fetching: true };
    case 'FETCHED_BATCH':
      return { ...currentState, data: payload, fetching: false };
    case 'DELETING_BATCH':
      return { ...currentState, fetching: true };
    case 'DELETED_BATCH':
      return { ...currentState, fetching: false };
    default:
      return currentState;
  }
}
