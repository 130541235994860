const initialState = {
  fetching: false,
  fetchingMeetings: false,
  fetchingOtherMeetings: false,
};

export default function meetingReducer(currentState = initialState, action) {
  switch (action.type) {
    case 'INSERTING_OR_UPDATING_MEETING':
      return { ...currentState, fetching: true };
    case 'INSERTED_OR_UPDATING_MEETING':
      return { ...currentState, data: action.payload, fetching: false };
    case 'FETCHING_MEETINGS':
      return { ...currentState, fetchingMeetings: true };
    case 'FETCHED_MEETINGS':
      return { ...currentState, meetings: action.payload, fetchingMeetings: false };
    case 'FETCHING_OTHER_MEETINGS':
      return { ...currentState, fetchingOtherMeetings: true };
    case 'FETCHED_OTHER_MEETINGS':
      return { ...currentState, otherMeetings: action.payload, fetchingOtherMeetings: false };
    case 'FETCHING_ATTENDEES':
      return { ...currentState, fetching: true };
    case 'FETCHED_ATTENDEES':
      return { ...currentState, attendees: action.payload, fetching: false };
    case 'DELETING_MEETING':
      return { ...currentState, fetching: true };
    case 'DELETED_MEETING':
      return { ...currentState, fetching: false };
    default:
      return currentState;
  }
}
