import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import Actions from '../../actions';

import Modal from '../Modal/Modal';
import AutoCompleteSelect from './AutoCompleteSelect';
import InputField from '../InputField';
import AppButton from './AppButton';
import IconButton from './IconButton';

const useStyles = makeStyles(() => {
  return {
    container: {
      paddingRight: 15,
    },
    buttonContainer: {
      marginBottom: 15,
    },
  };
});

const NAME = 'chapter';
const SUBJECT_NAME = 'subjectName';

const ChapterSelect = (props) => {
  const {
    handleChange, value, standard, standardName, subjectName, chapters, insertOrUpdateChapter, setSnack, getChapters,
    chapter, refreshPage, fetching,
  } = props;
  const classes = useStyles();
  const [data, setData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const handleChapterChange = (e) => {
    const chapName = e.target.value;
    const name = e.target.name;
    setData((prevData) => { return { ...prevData, [name]: chapName }; });
  };

  const onCloseModal = () => {
    setOpen(false);
    setData({});
  };

  const editChapter = (obj) => {
    setData(obj);
    setOpen(true);
  };

  const openModal = () => {
    setOpen(true);
  };

  const handleChapterSelect = (e, newObj) => {
    handleChange({ target: { name: NAME, value: newObj && newObj._id } });
    handleChange({ target: { name: SUBJECT_NAME, value: newObj && newObj.subjectName } });
  };

  const addChapter = () => {
    const requestData = { ...data };
    requestData.name = requestData.name && requestData.name.trim();
    if (!requestData.name) {
      return setSnack({
        open: true,
        severity: 'warning',
        message: 'Chapter name is required!',
      });
    }
    return insertOrUpdateChapter({
      standard, standardName, subjectName, ...requestData,
    }).then(() => {
      setSnack({
        open: true,
        severity: 'success',
        message: data._id ? 'Chapter name updated successfuly!' : 'New chapter name added successfully!',
      });
      onCloseModal();
      refreshPage();
    }).catch();
  };

  React.useEffect(() => {
    getChapters({ subjectName, standard });
  }, [subjectName, standard]);

  React.useEffect(() => {
    if (chapter && chapters) {
      const index = chapters.findIndex((obj) => { return (chapter._id === obj._id); });
      if (index > -1) chapters[index] = chapter;
      else chapters.push(chapter);
      handleChange({ target: { name: NAME, value: chapter._id } });
    }
  }, [chapter]);

  React.useEffect(() => {
    if (value && chapters) {
      const chapterObj = chapters.find((obj) => { return (value === obj._id); });
      handleChange({ target: { name: NAME, value: (chapterObj && chapterObj._id) || null } });
    }
  }, [chapters]);

  return (
    <>
      <Modal open={open} handleClose={onCloseModal} title="Add New Chapter">
        <InputField
          title="Enter Chapter Name"
          name="name"
          value={data.name}
          handleChange={handleChapterChange}
        />
        <Grid container justifyContent="center">
          <AppButton onClick={addChapter} title={data._id ? 'Update' : 'Add'} />
        </Grid>
      </Modal>
      <Grid container>
        <Grid item md={10} sm={8} xs={7} className={classes.container}>
          <AutoCompleteSelect
            title="Chapter (Optional)"
            data={chapters || []}
            name="chapter"
            value={value}
            handleChange={handleChapterSelect}
            editItem={editChapter}
            showEditIcon
            groupKey="subjectName"
            disabled={fetching}
          />
        </Grid>
        <Grid item md={2} sm={4} xs={5} container alignItems="center" className={classes.buttonContainer}>
          <IconButton onClick={openModal} title="Add Chapter" icon={<AddToPhotosIcon />} />
        </Grid>
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    content: state.content.data,
    chapters: state.content.chapters,
    fetching: state.content.fetching,
    chapter: state.content.chapter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterSelect);
