const initialState = {
  fetching: false,
};

export default function questionReducer(currentState = initialState, action) {
  switch (action.type) {
    case 'INSERTING_QUESTION':
      return { ...currentState, fetching: true };
    case 'INSERTED_QUESTION':
      return { ...currentState, data: action.payload.data, fetching: false };
    case 'SET_QUESTION':
      return { ...currentState, data: action.payload.data, fetching: false };
    case 'INSERTING_QUESTION_IN_PAPER':
      return { ...currentState, fetching: true };
    case 'INSERTED_QUESTION_IN_PAPER':
      return { ...currentState, fetching: false };
    case 'FETCHING_DOUBTS':
      return { ...currentState, fetching: true };
    case 'FETCHED_DOUBTS':
      return { ...currentState, doubts: action.payload, fetching: false };
    case 'INSERTING_OR_UPDATING_SOLUTION':
      return { ...currentState, fetching: true };
    case 'INSERTED_OR_UPDATED_SOLUTION':
      return { ...currentState, fetching: false };
    case 'FETCHING_IMPORT_QUESTIONS':
      return { ...currentState, fetching: true };
    case 'FETCHED_IMPORT_QUESTIONS':
      return { ...currentState, questions: action.payload, fetching: false };
    case 'RESET_IMPORT_QUESTIONS':
      return { ...currentState, questions: action.payload, fetching: false };
    default:
      return currentState;
  }
}
