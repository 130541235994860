import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import ContentEditor from '../Editor/ContentEditor';
import AppButton from '../App/AppButton';

// eslint-disable-next-line import/no-cycle
import ShowFunctions from './Display/ShowFunction';

import { getCombineValue } from '../../utils/util';

const NAMES = {
  UP: 'upper',
  DOWN: 'lower',
  DATA: 'data',
};

const useStyles = makeStyles(() => {
  return {
    table: {
      margin: 0,
      padding: 0,
    },
    cell: {
      border: 'none',
      padding: 0,
      margin: 0,
    },
    tends: {
      fontSize: 20,
      height: 50,
    },
    text: {
      verticalAlign: 'top',
      textAlign: 'center',
      width: '25%',
    },
    symbol: {
      marginTop: -10,
    },
  };
});

const Integration = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const classes = useStyles();
  const [data, setData] = React.useState({
    [NAMES.UP]: '',
    [NAMES.DOWN]: '',
    [NAMES.DATA]: '',
  });
  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const [focusedElement, setFocusedElement] = React.useState(NAMES.DATA);

  const handleContentChange = (event) => {
    const dataName = event.target.name;
    const value = event.target.value;
    setData((preData) => {
      const newData = { ...preData };
      newData[dataName] = value;
      return newData;
    });
  };

  const handleSubmit = () => {
    const value = `<table cellspacing="0" cellpadding="0" border="0"
    style="display:inline-table; border-collapse: collapse; vertical-align: middle;">
    <tbody>
    <tr>
      <td style="vertical-align: bottom; text-align: left;">
        ${data[NAMES.UP]}
      </td>
      <td rowSpan="3">${data[NAMES.DATA]}</td>
    </tr>
    <tr>
      <td><span style="padding-left: 3px; line-height: 1.2; font-size: 30px;">
      &#8747;</span>&nbsp;</td>
    </tr>
    <tr style="vertical-align: top; text-align: left;">
      <td>
      ${data[NAMES.DOWN]}
      </td>
    </tr>
    </tbody>
    </table>&nbsp;`;
    closeModal();
    handleChange({ target: { name, value } });
  };

  const setFocus = (elementName) => {
    setFocusedElement(elementName);
  };

  const getData = (event) => {
    const elName = event.target.name;
    const value = event.target.value;
    setData((prevData) => {
      const newElement = getCombineValue(prevData[elName], value, position);
      return { ...prevData, [elName]: newElement };
    });
  };

  return (
    <>
      <ShowFunctions handleChange={getData} name={focusedElement} />
      <Grid>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell colSpan="2" className={classes.cell}>
                <Grid container>
                  <Grid item xs={7}>
                    <ContentEditor
                      name={NAMES.UP}
                      html={data[NAMES.UP]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={[classes.cell, classes.text].join(' ')}>
                <Grid className={classes.symbol}>
                  <Typography variant="h2">&#8747;</Typography>
                </Grid>
              </TableCell>
              <TableCell className={classes.cell}>
                <Grid>
                  <Grid>
                    <ContentEditor
                      name={NAMES.DATA}
                      html={data[NAMES.DATA]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                      isAutoFocus
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan="2" className={classes.cell}>
                <Grid container>
                  <Grid item xs={7}>
                    <ContentEditor
                      name={NAMES.DOWN}
                      html={data[NAMES.DOWN]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid container justifyContent="center">
        <AppButton onClick={handleSubmit} />
      </Grid>
    </>
  );
};

export default Integration;
