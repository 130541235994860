import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import { QUESTION_TYPES } from '../../utils/constants';
import { FULL_TEST } from '../../utils/util';

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Radio color="default" {...props} />; });

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Checkbox color="default" {...props} />; });

const useStyles = makeStyles((theme) => {
  return {
    typography: {
      fontSize: 16,
      fontFamily: '"Times New Roman", Courier, Garamond, serif',
    },
    optionLabel: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    title: {
      fontWeight: 'bold',
    },
    textField: {
      paddingTop: 15,
    },
    queSpace: {
      paddingBottom: 15,
      paddingTop: 15,
    },
    correct: {
      fontWeight: 'bold',
      color: theme.successColor,
      marginRight: 16,
    },
    incorrect: {
      fontWeight: 'bold',
      color: theme.errorColor,
      marginRight: 16,
    },
    unattempted: {
      fontWeight: 'bold',
      color: theme.warningColor,
    },
    subject: {
      fontWeight: 'bold',
      color: theme.secondaryColor,
      marginRight: 16,
    },
  };
});

export default function Question(props) {
  const {
    question, handleIndexChange, handleAnswerChange, handleRadioChange, title, disabled,
    justify, editQuestion, deleteQuestion, noDivider, marksMapping,
  } = props;
  const classes = useStyles();
  const {
    questionType, options, correctOptionIndexes, answer, answers, solution,
  } = question;

  const questionHTML = question.question;
  const marks = question.marks;

  const getHTML = (html) => {
    return (
      <Typography
        dangerouslySetInnerHTML={{ __html: html }}
        className={classes.typography}
      />
    );
  };

  return (
    <>
      <Grid container justifyContent={justify}>
        <Grid container className={classes.queSpace}>
          {
            disabled ? (
              <Grid container style={{}}>
                <Grid item md={4} sm={4} xs={4}>
                  <Typography variant="caption" className={classes.subject}>
                    {question.subjectName !== FULL_TEST ? question.subjectName : null}
                  </Typography>
                  <Typography variant="caption" className={classes.correct}>
                    {'+'}
                    {
                      (marks && marks.correct)
                      || marksMapping[question.questionType].correct
                    }
                  </Typography>
                  <Typography variant="caption" className={classes.incorrect}>
                    {
                      (marks && marks.incorrect)
                      || marksMapping[question.questionType].incorrect
                    }
                  </Typography>
                  <Typography variant="caption" className={classes.unattempted}>
                    {
                      ((marks && marks.unattempted) || marksMapping[question.questionType].unattempted) > 0 ? '+' : ''
                    }
                    {
                      (marks && marks.unattempted)
                      || marksMapping[question.questionType].unattempted
                    }
                  </Typography>
                </Grid>
                <Grid container item md={8} sm={8} xs={8} justifyContent="flex-end">
                  <IconButton size="small" onClick={() => { deleteQuestion(question); }}>
                    <Delete fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => { editQuestion(question); }}>
                    <Edit fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ) : null
          }
          <Grid item md={12} sm={12} xs={12}>
            <div style={{ display: 'flex' }}>
              {
                title ? (
                  <Typography component="span" className={classes.title}>
                    {`${title}.`}
                    &nbsp;
                  </Typography>
                ) : null
              }
              {
                getHTML(questionHTML)
              }
            </div>
            {
              questionType === QUESTION_TYPES.BOOLEAN || questionType === QUESTION_TYPES.SINGLE_CHOICE
                ? (
                  <FormControl component="fieldset">
                    {
                      disabled ? null
                        : (
                          <FormLabel component="legend" className={classes.optionLabel}>
                            Select One Option
                          </FormLabel>
                        )
                    }
                    <RadioGroup
                      aria-label="options"
                      name="answer"
                      value={correctOptionIndexes[0] !== undefined ? correctOptionIndexes[0] : ''}
                      onChange={handleRadioChange}
                      inputprops={disabled ? { readOnly: true } : {}}
                    >
                      {
                          options && options.map((option, index) => {
                            return (
                              <FormControlLabel
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                value={index}
                                control={<GreenRadio />}
                                label={getHTML(option)}
                              />
                            );
                          })
                      }
                    </RadioGroup>
                  </FormControl>
                ) : null
            }
            {
              questionType === QUESTION_TYPES.MULTIPLE_CHOICE
                ? (
                  <FormControl component="fieldset">
                    {
                        disabled ? null
                          : (
                            <FormLabel component="legend" className={classes.optionLabel}>
                              Select Multiple Options
                            </FormLabel>
                          )
                    }
                    {
                        options && options.map((option, index) => {
                          const isChecked = correctOptionIndexes.includes(index);
                          return (
                            <FormControlLabel
                            // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              control={(
                                <GreenCheckbox
                                  checked={isChecked}
                                  onChange={() => { return (!disabled && handleIndexChange(index)); }}
                                  name={index.toString()}
                                  inputprops={disabled ? { readOnly: true } : {}}
                                />
                              )}
                              label={getHTML(option)}
                            />
                          );
                        })
                    }
                  </FormControl>
                ) : null
            }
            {
              questionType === QUESTION_TYPES.INTEGER || (disabled && questionType === QUESTION_TYPES.TEXT)
              || (disabled && questionType === QUESTION_TYPES.FILL_IN_THE_BLANK)
                ? (
                  <FormControl component="fieldset" className={classes.textField}>
                    {
                        disabled ? null
                          : (
                            <FormLabel component="legend" className={classes.optionLabel}>
                              Enter Your Answer
                            </FormLabel>
                          )
                    }
                    {
                      questionType === QUESTION_TYPES.INTEGER
                        ? (
                          <TextField
                            id="answer"
                            label={disabled ? 'Answer' : 'Enter Answer'}
                            name="answer"
                            variant="outlined"
                            value={disabled ? answers[0] : answer}
                            onChange={handleAnswerChange}
                            type={questionType === QUESTION_TYPES.INTEGER ? 'number' : 'text'}
                            required
                            inputprops={disabled ? { readOnly: true } : {}}
                          />
                        ) : (
                          <div style={{ display: 'flex' }}>
                            <Typography component="span" className={classes.title}>
                              Ans:&nbsp;
                            </Typography>
                            {getHTML(answers[0])}
                          </div>
                        )
                    }
                  </FormControl>
                ) : null
            }
            {
                solution && disabled ? (
                  <div style={{ display: 'flex' }}>
                    <Typography component="span" className={classes.title}>
                      Solution:&nbsp;
                    </Typography>
                    {getHTML(solution)}
                  </div>
                ) : null
            }
          </Grid>
        </Grid>
      </Grid>
      {
        noDivider ? null : <Divider />
      }
    </>
  );
}
