const initialState = {
  fetching: false,
};

export default function availabilityReducer(currentState = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'MARKING_AVAILABILITY':
      return { ...currentState, fetching: true };
    case 'MARKED_AVAILABILITY':
      return { ...currentState, data: payload, fetching: false };
    case 'FETCHING_AVAILABILITY':
      return { ...currentState, fetching: true };
    case 'FETCHED_AVAILABILITY':
      return { ...currentState, data: payload, fetching: false };
    case 'MARKING_TIMEOFF':
      return { ...currentState, fetching: true };
    case 'MARKED_TIMEOFF':
      return { ...currentState, data: payload, fetching: false };
    default:
      return currentState;
  }
}
