/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import clsx from "clsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

import Home from "@material-ui/icons/Home";
// import StudentIcon from '@material-ui/icons/TransferWithinAStation';
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
// import ExamPortalIcon from '@material-ui/icons/CallMade';
import PostAddIcon from "@material-ui/icons/PostAdd";
// import BugReportIcon from '@material-ui/icons/BugReport';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import GroupIcon from "@material-ui/icons/Group";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import CollectionsIcon from "@material-ui/icons/Collections";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ForumIcon from "@material-ui/icons/Forum";
import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import Actions from "../actions";

import { ROLES } from "../utils/constants";

import Name from "./App/Name";

const NAV_DATA = {
  [ROLES.TEACHER]: {
    ICONS: [
      <Home />,
      <CampaignIcon />,
      <DateRangeIcon />,
      <PostAddIcon />,
      <LibraryAddIcon />,
      <DashboardCustomizeIcon />,
      <FilterNoneIcon />,
      <GroupIcon />,
      <FolderOpenIcon />,
      <CollectionsIcon />,
      <ForumIcon />,
      <AssignmentIcon />,
    ],
    TEXTS: [
      "Home",
      "Announcement",
      "Schedule Class",
      "Add Test Paper",
      "Add Study Materials",
      "Add Daily Avtivity",
      "My Batches",
      "My Students",
      "My Test Papers",
      "My Study Materials",
      "Student Doubts",
      "Use Whiteboard",
    ],
    LINKS: [
      "/dashboard/home",
      "/dashboard/announcement",
      "/dashboard/schedule-meeting",
      "/dashboard/add-paper",
      "/dashboard/add-content",
      "/dashboard/add-activity",
      "/dashboard/batches",
      "/dashboard/my-students",
      "/dashboard/all-papers",
      "/dashboard/all-contents",
      "/dashboard/student-doubts",
      "https://jamboard.google.com/",
    ],
  },
  [ROLES.SCHOOL]: {
    ICONS: [
      <Home />,
      <CampaignIcon />,
      <DateRangeIcon />,
      <PostAddIcon />,
      <LibraryAddIcon />,
      <FilterNoneIcon />,
      <LocalLibraryIcon />,
      <GroupIcon />,
      <FolderOpenIcon />,
      <CollectionsIcon />,
      <ForumIcon />,
      <AssignmentIcon />,
    ],
    TEXTS: [
      "Home",
      "Announcement",
      "Schedule Class",
      "Add Test Paper",
      "Add Study Materials",
      "My Batches",
      "My Teachers",
      "My Students",
      "My Test Papers",
      "My Study Materials",
      "Student Doubts",
      "Use Whiteboard",
    ],
    LINKS: [
      "/dashboard/home",
      "/dashboard/announcement",
      "/dashboard/schedule-meeting",
      "/dashboard/add-paper",
      "/dashboard/add-content",
      "/dashboard/batches",
      "/dashboard/my-teachers",
      "/dashboard/my-students",
      "/dashboard/all-papers",
      "/dashboard/all-contents",
      "/dashboard/student-doubts",
      "https://jamboard.google.com/",
    ],
  },
  [ROLES.INSTITTUTE]: {
    ICONS: [
      <Home />,
      <CampaignIcon />,
      <DateRangeIcon />,
      <PostAddIcon />,
      <LibraryAddIcon />,
      <FilterNoneIcon />,
      <LocalLibraryIcon />,
      <GroupIcon />,
      <FolderOpenIcon />,
      <CollectionsIcon />,
      <ForumIcon />,
      <AssignmentIcon />,
    ],
    TEXTS: [
      "Home",
      "Announcement",
      "Schedule Class",
      "Add Test Paper",
      "Add Study Materials",
      "My Batches",
      "My Teachers",
      "My Students",
      "My Test Papers",
      "My Study Materials",
      "Student Doubts",
      "Use Whiteboard",
    ],
    LINKS: [
      "/dashboard/home",
      "/dashboard/announcement",
      "/dashboard/schedule-meeting",
      "/dashboard/add-paper",
      "/dashboard/add-content",
      "/dashboard/batches",
      "/dashboard/my-teachers",
      "/dashboard/my-students",
      "/dashboard/all-papers",
      "/dashboard/all-contents",
      "/dashboard/student-doubts",
      "https://jamboard.google.com/",
    ],
  },
};

const getTexts = (role) => {
  return NAV_DATA[role].TEXTS;
};

const getLinks = (role) => {
  return NAV_DATA[role].LINKS;
};

const getIcons = (role) => {
  return NAV_DATA[role].ICONS;
};

const drawerWidth = 280;

function PersistentDrawerLeft(props) {
  const [open, setOpen] = React.useState(false);
  const useStyles = makeStyles((theme) => {
    return {
      root: {
        display: "flex",
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: theme.boxShadow,
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      hide: {
        display: "none",
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      drawerOpen: {
        width: drawerWidth,
        color: "#fff",
        background: 'linear-gradient(103.37deg, #99179F 4.77%, #2068A8 95.46%)',
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: 0,
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9) + 1,
        },
      },
      content: {
        flexGrow: 1,
      },
      contentShift: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      link: {
        textDecoration: "none",
      },
      linkItem: {
        display: "flex",
        alignItems: "center",
        fontSize: 15,
        fontWeight: "bold",
        marginBottom: 5,
        padding: 15,
        paddingLeft: 24,
      },
      active: {
        background: open ? '#0000004D' : 'linear-gradient(103.37deg, #99179F 4.77%, #2068A8 95.46%)',
        color: "#fff",
        "&:hover": {
          background: '0000004D',
        },
      },
      iconActive: {
        color: "#fff",
      },
      inactive: {
        color: open ? "#fff" : "#99179F",
        "&:hover": {
          background: '#0000004D',
        },
      },
      iconInactive: {
        color: open ? "#fff" : "#99179F",
      },
      toolbar: {
        background: "#fff",
      },
      avtar: {
        background: "#c0c0c0",
        height: 40,
        width: 40,
        [theme.breakpoints.down("sm")]: {
          height: 35,
          width: 35,
        },
      },
    };
  });
  const classes = useStyles();
  const theme = useTheme();
  const role = localStorage.getItem("role");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    props.logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
                data-tour="drawer"
              >
                <MenuIcon />
              </IconButton>
              <div>
                <Name />
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ paddingRight: "15px" }}>
                  <Link href="/dashboard/profile">
                    <Avatar className={classes.avtar} />
                  </Link>
                </div>
                <Hidden smDown>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#555", paddingRight: "15px" }}
                  >
                    Hello, {localStorage.getItem("userName")}
                  </Typography>
                </Hidden>
                <div>
                  <Tooltip title="Logout" arrow>
                    <IconButton
                      variant="contained"
                      href="/dashboard/"
                      onClick={handleLogout}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <div className={classes.drawerHeader}>
          <div style={{ paddingLeft: "8px" }}>
            <Link href="/dashboard/profile">
              <Avatar className={classes.avtar} />
            </Link>
          </div>
          <Typography
            variant="subtitle1"
            style={{ paddingLeft: "15px" }}
            noWrap
          >
            Hello, {localStorage.getItem("userName")}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List style={{ padding: 0 }}>
          {getTexts(role).map((text, index) => {
            return (
              <Link
                key={text}
                href={getLinks(role)[index]}
                className={classes.link}
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  button
                  className={[
                    classes.linkItem,
                    window.location.pathname === getLinks(role)[index]
                      ? classes.active
                      : classes.inactive,
                  ].join(" ")}
                >
                  <ListItemIcon
                    className={
                      window.location.pathname === getLinks(role)[index]
                        ? classes.iconActive
                        : classes.iconInactive
                    }
                  >
                    <Tooltip title={text} arrow>
                      {getIcons(role)[index]}
                    </Tooltip>
                  </ListItemIcon>
                  {text}
                </ListItem>
              </Link>
            );
          })}
        </List>
        <Divider />
        <hr />
        <List style={{ visibility: open ? "visible" : "hidden" }}>
          <ListItem>
            <Button href="/contact-us" target="_blank" style={{ color: "#fff" }}>
              Request Features
            </Button>
          </ListItem>
          <ListItem>
            <Button href="/contact-us" target="_blank" style={{ color: "#fff" }}>
              Contact Us
            </Button>
          </ListItem>
          <ListItem>
            <Button href="/" target="_blank" style={{ color: "#fff" }}>
              Website
            </Button>
          </ListItem>
          <ListItem>
            <Button
              onClick={handleLogout}
              href="/dashboard/"
              endIcon={<ExitToAppIcon />}
              style={{ color: "#fff" }}
            >
              Logout
            </Button>
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <div>{props.children}</div>
      </main>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(null, mapDispatchToProps)(PersistentDrawerLeft);
