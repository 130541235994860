/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
import React, { useState } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LinkIcon from "@material-ui/icons/Link";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import useStyles from "../Availability/styles";

import { WEEK_DAYS_INTEGER_MAPPINGS } from "../../utils/constants";
import { meetingValidationSchema } from "../../utils/util";

import Actions from "../../actions";

import InputField from "../InputField";
import GroupSelect from "../GroupSelect";
import AutoCompleteSelect from "../App/AutoCompleteSelect";
import AppButton from "../App/AppButton";

function ScheduleForm(props) {
  const {
    loading,
    setSnack,
    setLoading,
    insertOrUpdateMeeting,
    refreshPage,
    fetchAttendees,
    batch,
    mappings,
    attendees,
    standards,
  } = props;
  const classes = useStyles();
  const meetingData = props.meetingData || {};
  const currentTime = new Date();
  currentTime.setHours(9, 0, 0, 0);
  const [open, setOpen] = React.useState(false);
  const [standard, setStandard] = React.useState("");

  const getInitialState = (meeting = {}) => {
    const startTime =
      (meeting.startTime && new Date(meeting.startTime)) || currentTime;
    return {
      _id: meetingData._id,
      startTime,
      endTime:
        (meeting.endTime && new Date(meeting.endTime)) ||
        moment(startTime).add(1, "hour").toDate(),
      name: meeting.name || "",
      isRepeat: meeting.isRepeat || true,
      days:
        (meeting.days &&
          meeting.days.map((obj) => {
            return new Date(obj).getDay();
          })) ||
        [],
      standardName: standard?._id || "",
      meetingId: meeting.meetingId,
      batch,
    };
  };

  const [data, setData] = useState(getInitialState());

  const actionString = data.meetingId ? "Update" : "Create";

  const onSelectTime = (date) => {
    setData((prevData) => {
      const endTime = moment(date).add(1, "hour").toDate();
      return { ...prevData, startTime: date, endTime };
    });
  };

  const addOrRemoveDay = (dayIndex) => {
    const index = data.days.indexOf(dayIndex);
    if (index === -1) setData({ ...data, days: [...data.days, dayIndex] });
    else {
      const days = data.days;
      days.splice(index, 1);
      setData({ ...data, days: [...days] });
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = data.startTime;
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  console.log(standards);

  const handleStandardChange = (e, newObj) => {
    setStandard(newObj);

    setData({
      ...data,
      standardName: newObj?._id || "",
    });
  };

  console.log(data);

  const handleSubmit = () => {
    const roomNumber = Math.floor(10000 + Math.random() * 90000);

    const selectedDays = [];
    const DATE_FORMAT = "DD/MM/YYYY";
    const TIME_FORMAT = "HH:mm";
    const startWeekDate = moment(new Date(data.startTime))
      .startOf("week")
      .toDate();
    const startTimeString = moment(startWeekDate).format(DATE_FORMAT);
    const thisWeekStartTime = moment(
      startTimeString,
      `${DATE_FORMAT} ${TIME_FORMAT}`
    ).toDate();
    const pushData = (startTime) => {
      selectedDays.push(startTime);
    };
    data.days.forEach((dayIndex) => {
      const startTime = moment(thisWeekStartTime)
        .add(dayIndex, "days")
        .toDate();
      pushData(startTime);
    });

    if (selectedDays.length === 0 && data.startTime <= new Date()) {
      setSnack({
        open: true,
        message: "Please select future date & time! Or use isRepeat option!!",
        severity: "warning",
      });
    } else {
      setLoading(true);
      const insertData = { ...data, days: selectedDays, meetingId: roomNumber };

      meetingValidationSchema
        .validate(insertData)
        .then(() => {
          return insertOrUpdateMeeting(insertData);
        })
        .then(() => {
          refreshPage();
          setData(getInitialState({}));
          setSnack({
            open: true,
            message: `Meeting ${actionString}d Successfully!`,
            severity: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setSnack({
            open: true,
            message: err.message || "Something Went Wrong!",
            severity: "error",
          });
        });
    }
  };

  const CustomInputDate = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <Button
        variant="contained"
        type="button"
        onClick={onClick}
        startIcon={<CalendarTodayIcon />}
        ref={ref}
        className={classes.calButton2}
      >
        <Typography className={classes.textButton}>{value}</Typography>
      </Button>
    );
  });

  const CustomInputTime = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <Grid container justifyContent="center">
        <Button
          variant="contained"
          type="button"
          onClick={onClick}
          startIcon={<ScheduleIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          ref={ref}
          className={classes.calButton2}
        >
          <Typography className={classes.textButton}>{value}</Typography>
        </Button>
      </Grid>
    );
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleCheckbok = (event) => {
    const isChecked = event.target.checked;
    setData((prevData) => {
      let days = prevData.days;
      if (!isChecked) days = [];
      return { ...data, days, isRepeat: isChecked };
    });
  };

  const handleCheckbokClick = ({ label, value, standardName }) => {
    let index = -1;
    for (let i = 0; i < data.attendees.length; i += 1) {
      if (data.attendees[i].value === value) {
        index = i;
        break;
      }
    }
    setData((prevData) => {
      const newAttendees = [...prevData.attendees];
      if (index > -1) newAttendees.splice(index, 1);
      else newAttendees.push({ label, value, standardName });
      return { ...prevData, attendees: newAttendees };
    });
  };

  console.log(standards);
  console.log(standard);

  React.useEffect(() => {
    setData(getInitialState(meetingData));
  }, [meetingData]);

  React.useEffect(() => {
    fetchAttendees({ batch });
  }, [batch]);

  React.useEffect(() => {
    if (
      mappings &&
      batch &&
      !data._id &&
      (!data.attendees || data.attendees.length === 0)
    ) {
      setData((prevData) => {
        const users = [];
        Object.values(mappings).forEach((values) => {
          values.forEach((user) => {
            users.push({ label: user.name, value: user._id });
          });
        });
        return { ...prevData, attendees: users };
      });
    }
  }, [mappings, batch]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        container
        md={7}
        sm={7}
        xs={12}
        justifyContent="center"
        alignItems="center"
      >
        <Paper elevation={0} className={classes.paperContainer}>
          <Grid item xs={12}>
            <InputField
              title="Enter Title"
              name="name"
              value={data.name}
              handleChange={handleChange}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <InputField
              title="Enter Meeting Link"
              name="meetingLink"
              value={data.meetingLink}
              handleChange={handleChange}
            />
          </Grid> */}

          <Grid container>
            <Grid item container xs={12} justifyContent="flex-start">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.isRepeat}
                    onChange={handleCheckbok}
                    name="isRepeat"
                    color="secondary"
                  />
                }
                label="Is Repeat?"
              />
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              {data.isRepeat ? (
                Object.values(WEEK_DAYS_INTEGER_MAPPINGS).map((day, index) => {
                  return (
                    <div key={day}>
                      <Button
                        variant="contained"
                        className={
                          data.days.indexOf(index) !== -1
                            ? classes.activeBox
                            : classes.box
                        }
                        onClick={() => {
                          addOrRemoveDay(index);
                        }}
                      >
                        {day}
                      </Button>
                    </div>
                  );
                })
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <DatePicker
                    selected={data.startTime}
                    onChange={(date) => {
                      return onSelectTime(date);
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="EEE, MMM d, yyyy"
                    customInput={<CustomInputDate />}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center" className={classes.spacing}>
            <Grid
              item
              container
              md={10}
              sm={12}
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                md={2}
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <p className={classes.midText}>from</p>
              </Grid>
              <Grid
                container
                item
                md={4}
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <DatePicker
                  selected={data.startTime}
                  onChange={(date) => {
                    return onSelectTime(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  customInput={<CustomInputTime />}
                />
              </Grid>
              <Grid
                container
                item
                md={2}
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <p className={classes.midText}>to</p>
              </Grid>
              <Grid
                container
                item
                md={4}
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <DatePicker
                  selected={data.endTime}
                  onChange={(endTime) => {
                    return setData({ ...data, endTime });
                  }}
                  filterTime={filterPassedTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  customInput={<CustomInputTime />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            style={{ display: "block", marginTop: "15px" }}
          >
            <AutoCompleteSelect
              title="Standard"
              data={standards}
              name="standard"
              value={standard?._id || ""}
              handleChange={handleStandardChange}
            />
          </Grid>
          <Grid container item justifyContent="center" alignItems="center">
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <AppButton
                disabled={loading}
                onClick={handleSubmit}
              >
                {`${actionString} Class`}
              </AppButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    mappings: state.meeting.attendees,
    loading: state.meeting.fetching,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleForm)
);
