import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import DialogBox from '../Dialog/DialogBox';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2),
    },
  };
})(MuiAccordionDetails);

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      fontWeight: 600,
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 15,
    },
    linkContainer: {
      marginBottom: 8,
    },
    linkContainer2: {
    },
    displayPaper: {
      minHeight: 100,
      marginLeft: 15,
      marginRight: 15,
      marginBottom: 30,
      padding: 15,
      boxShadow: theme.boxShadow,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    noque: {
      padding: 15,
      height: 200,
    },
    question: {
    },
  };
});

const TopicAccordion = (props) => {
  const {
    topics, deleteTopic, setSnackData, refreshPage, onEdit, isDisabledEdit,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');
  const [editObject, setEditObject] = React.useState();

  const openDeleteDialog = (_id) => {
    setDeleteId(_id);
    setOpenDialog(true);
  };

  const openEditDialogContent = (obj) => {
    setEditObject(obj);
    setOpenEditDialog(true);
  };

  const handleToggle = (panel) => {
    return (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  };

  const onCloseDialog = () => {
    setDeleteId('');
    setOpenDialog(false);
  };

  const onCloseEditDialog = () => {
    setEditObject();
    setOpenEditDialog(false);
  };

  const confirmDelete = () => {
    deleteTopic({ _id: deleteId })
      .then(() => {
        setSnackData({
          open: true,
          severity: 'success',
          message: 'Topic Deleted Successfully!',
        });
        refreshPage();
        onCloseDialog();
      });
  };

  const confirmEdit = () => {
    onEdit(editObject);
    setOpenEditDialog(false);
    setEditObject();
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
  };

  const copy = (content, type) => {
    navigator.clipboard.writeText(content);
    setSnackData({
      open: true,
      severity: 'success',
      message: `${type} name coppied successfully!`,
    });
  };

  return (
    <>
      <DialogBox open={openDialog} onClose={onCloseDialog} title="Delete this topic" confirm={confirmDelete}>
        Are you sure want to delete this topic?
      </DialogBox>
      <DialogBox open={openEditDialog} onClose={onCloseEditDialog} title="Edit this topic" confirm={confirmEdit}>
        Are you sure want to edit this topic?
      </DialogBox>
      <Grid>
        <Paper elevation={0} className={classes.displayPaper}>
          {
            !topics || Object.keys(topics).length === 0
              ? (
                <Grid container justifyContent="center" alignItems="center" className={classes.noque}>
                  <Typography>No content added yet. Please add study materials content.</Typography>
                </Grid>
              )
              : null
          }
          {
            topics && Object.keys(topics).map((chapterName, index) => {
              const topicsObjs = topics[chapterName];
              return (
              // eslint-disable-next-line react/no-array-index-key
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {`${index + 1}. ${chapterName}`}
                    </Typography>
                    <IconButton onClick={() => { copy(chapterName, 'Chapter'); }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: 'block' }}>
                    {
                      topicsObjs.map((obj, indx) => {
                        return (
                        // eslint-disable-next-line react/no-array-index-key
                          <Grid key={indx}>
                            <Accordion
                              square
                              expanded={expanded === `${index}-${indx}`}
                              onChange={handleToggle(`${index}-${indx}`)}
                            >
                              <AccordionSummary
                                aria-controls={`${index}-${indx}-header`}
                                id={`${index}-${indx}-header`}
                              >
                                <Grid container justifyContent="space-between" alignItems="center">
                                  <Grid item>
                                    {
                                      expanded === `${index}-${indx}` ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                    }
                                  </Grid>
                                  <Grid item>
                                    <b>
                                      {`${index + 1}.${indx + 1} `}
                                      &nbsp;
                                    </b>
                                  </Grid>
                                  <Grid container item xs alignItems="center">
                                    <Grid item xs>
                                      <Typography className={classes.question}>
                                        {obj.name}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <IconButton onClick={() => { copy(obj.name, 'Topic'); }}>
                                        <ContentCopyIcon fontSize="small" />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  <Grid container item xs={3} justifyContent="space-evenly">
                                    {
                                      isDisabledEdit ? null : (
                                        <>
                                          <IconButton size="small" onClick={() => { openEditDialogContent(obj); }}>
                                            <EditIcon fontSize="small" />
                                          </IconButton>
                                          <IconButton size="small" onClick={() => { openDeleteDialog(obj._id); }}>
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        </>
                                      )
                                    }
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid style={{ width: '100%' }}>
                                  <Grid style={{ width: '100%' }}>
                                    <Typography
                                      dangerouslySetInnerHTML={{ __html: obj.content }}
                                      className={classes.typography}
                                    />
                                  </Grid>
                                  <br />
                                  <Grid container alignItems="center" className={classes.linkContainer2}>
                                    <Grid><b>Uploaded Files:&nbsp;</b></Grid>
                                    <Grid>
                                      {
                                        obj.uploadedFiles && obj.uploadedFiles.map((urlObj, i) => {
                                          return (
                                            <Chip
                                              key={`${urlObj.name} ${i + 1}`}
                                              label={(
                                                <Link href={urlObj.url} target="_blank">
                                                  <Grid container alignItems="center">
                                                    <AttachFileIcon fontSize="small" />
                                                    {' '}
                                                    {`${urlObj.name}`}
                                                  </Grid>
                                                </Link>
                                                )}
                                            />
                                          );
                                        })
                                      }
                                    </Grid>
                                  </Grid>
                                  <Grid container alignItems="center" className={classes.linkContainer2}>
                                    <Grid><b>Documents urls:&nbsp;</b></Grid>
                                    <Grid>
                                      {
                                        obj.documentUrls && obj.documentUrls.map((urlObj, i) => {
                                          return (
                                            <Chip
                                              key={`${urlObj.name} ${i + 1}`}
                                              label={(
                                                <Link href={urlObj.url} target="_blank">
                                                  {urlObj.name}
                                                </Link>
                                                )}
                                            />
                                          );
                                        })
                                      }
                                    </Grid>
                                  </Grid>
                                  <Grid container alignItems="center" className={classes.linkContainer2}>
                                    <Grid><b>Videos urls:&nbsp;</b></Grid>
                                    <Grid>
                                      {
                                        obj.videoUrls && obj.videoUrls.map((urlObj, i) => {
                                          return (
                                            <Chip
                                              key={`${urlObj.name} ${i + 1}`}
                                              label={(
                                                <Link href={urlObj.url} target="_blank">
                                                  {urlObj.name}
                                                </Link>
                                                )}
                                            />
                                          );
                                        })
                                      }
                                    </Grid>
                                  </Grid>
                                  {
                                    obj.day
                                      ? (
                                        <Grid>
                                          <b>Practice for:&nbsp;</b>
                                          {'Day'}
                                          {' '}
                                          {obj.day}
                                        </Grid>
                                      ) : null
                                  }
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        );
                      })
                    }
                  </AccordionDetails>
                </Accordion>
              );
            })
        }
        </Paper>
      </Grid>
    </>
  );
};

export default TopicAccordion;
