import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import AppBar from '../components/AppBar';
import TitleBar from '../components/TitleBar';
import Invite from '../components/App/Invite';
import TeacherMapping from '../components/Display/TeacherMapping';
import SnackBar from '../components/Progress/Snack';

const Teachers = () => {
  const [refresh, setRefresh] = React.useState(false);
  const [snack, setSnack] = React.useState({});

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const closeSnack = () => {
    setSnack({});
  };

  return (
    <>
      <SnackBar
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <AppBar>
        <Container maxWidth={false}>
          <TitleBar title="Your Teachers" />
          <Grid container justifyContent="flex-end" style={{ marginBottom: 30 }}>
            <Invite userRole="teacher" refreshPage={refreshPage} />
          </Grid>
          <TeacherMapping refresh={refresh} setSnack={setSnack} refreshPage={refreshPage} />
        </Container>
      </AppBar>
    </>
  );
};

export default Teachers;
