import React from 'react';

import Grid from '@material-ui/core/Grid';

import ContentEditor from '../../Editor/ContentEditor';

// eslint-disable-next-line import/no-cycle
import ShowFunctions from '../Display/ShowFunction';
import AppButton from '../../App/AppButton';

import { getCombineValue } from '../../../utils/util';

const SingleInput = (props) => {
  const {
    content, handleChange, name, closeModal, getHTML, contentName, isAutoFocus,
  } = props;
  const NAME = contentName;
  const [data, setData] = React.useState({
    [NAME]: content || '',
  });
  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const handleContentChange = (event) => {
    const dataName = event.target.name;
    const value = event.target.value;
    setData((preData) => {
      const newData = { ...preData };
      newData[dataName] = value;
      return newData;
    });
  };

  const handleSubmit = () => {
    const value = getHTML(data[NAME]);
    closeModal();
    handleChange({ target: { name, value } });
  };

  const getData = (event) => {
    const elName = event.target.name;
    const value = event.target.value;
    setData((prevData) => {
      const newElement = getCombineValue(prevData[elName], value, position);
      return { ...prevData, [elName]: newElement };
    });
  };

  return (
    <>
      <ShowFunctions handleChange={getData} name={NAME} />
      <ContentEditor
        name={NAME}
        html={data[NAME]}
        handleChange={handleContentChange}
        setPosition={setPosition}
        isAutoFocus={isAutoFocus}
      />
      <Grid container justifyContent="center">
        <AppButton onClick={handleSubmit} />
      </Grid>
    </>
  );
};

export default SingleInput;
