import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import Delete from '@material-ui/icons/Delete';

import Actions from '../../actions';

import DialogBox from '../Dialog/DialogBox';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginBottom: 15,
    },
    loaderContainer: {
      minHeight: 150,
    },
    itemAligment: {
      '@media (max-width:460px)': {
        justifyContent: 'center',
      },
      marginBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
    },
    deviceItem: {
      width: '25%',
      '@media (max-width:460px)': {
        width: '100%',
      },
    },
    userCard: {
      cursor: 'pointer',
      minHeight: 100,
      textAlign: 'left',
      width: '100%',
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: '#555',
      padding: 15,
      '&:hover': {
        color: '#fff',
        background: theme.secondaryColor,
      },
    },
    standard: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 15px',
      color: '#555',
    },
    subtitle: {
      fontWeight: 'bold',
    },
    container: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    text: {
      textAlign: 'center',
    },
  };
});

function Mapping(props) {
  const {
    getTeacherMappings, refresh, teacherMappings, loading, deleteUserMapping, setSnack, refreshPage, setTeacher,
  } = props;
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});
  const [redirect, setRedirect] = React.useState(false);

  const onCloseDialog = () => {
    setOpenDialog(false);
    setDeleteData({});
  };

  const confirmDelete = () => {
    setOpenDialog(false);
    deleteUserMapping(deleteData)
      .then(() => {
        setSnack({ open: true, severity: 'success', message: 'Removed teacher successfully!' });
        setDeleteData({});
        refreshPage();
      })
      .catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  const handleDelete = (_id) => {
    setDeleteData({ _id });
    setOpenDialog(true);
  };

  const setTeacherData = (user) => {
    setTeacher(user);
    setRedirect(true);
  };

  React.useEffect(() => {
    getTeacherMappings();
  }, [refresh]);

  if (redirect) {
    return (<Redirect push to="/teacher" />);
  }

  return (
    <>
      <DialogBox open={openDialog} onClose={onCloseDialog} title="Remove Teacher" confirm={confirmDelete}>
        Are you sure want to remove this teacher?
      </DialogBox>
      {
        loading ? <Grid container justifyContent="center" alignItems="center"><CircularProgress /></Grid>
          : (
            <Grid container className={classes.container}>
              <Grid container className={classes.itemAligment} spacing={3}>
                {
                    teacherMappings && teacherMappings.map((obj) => {
                      const user = obj.user;
                      return (
                        <Grid key={user._id} item className={classes.deviceItem}>
                          <Paper className={classes.userCard}>
                            <Grid container justifyContent="space-between">
                              <Grid item xs={10} onClick={() => { setTeacherData(user); }}>
                                <Typography variant="subtitle1" className={classes.subtitle} noWrap>
                                  {user.name}
                                </Typography>
                              </Grid>
                              <Grid container item xs={2} justifyContent="flex-end">
                                <IconButton size="small" onClick={() => { handleDelete(obj._id); }}>
                                  <Delete fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Grid onClick={() => { setTeacherData(user); }}>
                              <Typography variant="caption" display="block" noWrap>
                                {user.phone}
                              </Typography>
                              <Typography variant="caption" display="block" noWrap>
                                {user.email}
                              </Typography>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })
                }
              </Grid>
              {
                !loading && (teacherMappings && teacherMappings.length) === 0
                  ? (
                    <Grid container justifyContent="center">
                      <Paper elevation={0} className={classes.standard}>
                        <Typography className={classes.text}>
                          No teachers are invited yet. Please invite teacher.
                        </Typography>
                      </Paper>
                    </Grid>
                  ) : null
              }
            </Grid>
          )
      }
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    teacherMappings: state.userMapping.teacherMappings,
    loading: state.userMapping.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mapping));
