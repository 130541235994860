import React from 'react';

import Grid from '@material-ui/core/Grid';

import SiginupComponent from '../components/Authentication/Signup';

const Signup = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <SiginupComponent />
      </Grid>
    </>
  );
};

export default Signup;
