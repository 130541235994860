import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import Actions from '../../../actions';

import { ROLES } from '../../../utils/constants';

import Invite from '../../App/Invite';
import AppButton from '../../App/AppButton';
import Modal from '../../Modal/Modal';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Checkbox color="default" {...props} />; });

const useStyles = makeStyles((theme) => {
  return {
    button: {
      background: theme.secondaryColor,
      color: '#fff',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 'bold',
      boxShadow: theme.boxShadow,
    },
    titleBar: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 0px 10px 17px',
      color: '#555',
    },
    subtitle: {
      fontWeight: 'bold',
    },
    itemAligment: {
      '@media (max-width:460px)': {
        justifyContent: 'center',
      },
      marginBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
    },
    deviceItem: {
      width: '25%',
      '@media (max-width:460px)': {
        width: '100%',
      },
    },
    userCard: {
      minHeight: 100,
      textAlign: 'left',
      width: '100%',
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: '#555',
      padding: 15,
      cursor: 'pointer',
    },
    box: {
      padding: 5,
    },
  };
});

const Tab3 = (props) => {
  const {
    refresh, refreshPage, getTeacherMappings, teacherMappings, newBatch, addUsers, setSnack, setLoading, setTeacher,
  } = props;
  const batch = newBatch || props.batch || {};
  const accessTeachers = batch.users && batch.users.filter((user) => { return user.role === ROLES.TEACHER; });
  const classes = useStyles();

  const [users, setUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const handleCheckboxClick = (userId) => {
    const newUsers = [...users];
    if (!users.includes(userId)) newUsers.push(userId);
    else {
      const index = newUsers.indexOf(userId);
      newUsers.splice(index, 1);
    }
    setUsers(newUsers);
  };

  const handleClosseModal = () => { setOpen(false); };

  const assignTeachers = () => {
    handleClosseModal();
    setLoading(true);
    addUsers({ users, _id: batch._id })
      .then(() => {
        setLoading(false);
        setSnack({ open: true, severity: 'success', message: 'Teachers assigned successfully!' });
        refreshPage();
      })
      .catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  const setTeacherData = (user) => {
    setTeacher(user);
    setRedirect(true);
  };

  React.useEffect(() => {
    getTeacherMappings();
  }, [refresh]);

  React.useEffect(() => {
    if (batch.users) setUsers(batch.users.map((obj) => { return obj._id; }));
  }, [refresh]);

  if (redirect) {
    return (<Redirect push to="/teacher" />);
  }

  return (
    <>
      <Modal open={open} handleClose={handleClosseModal} title="Assign Selected Teachers">
        <Grid className={classes.box}>
          <Grid container>
            {
                teacherMappings && teacherMappings.map((obj) => {
                  const teacher = obj.user || {};
                  return (
                    <Grid key={obj._id} item>
                      <FormControl>
                        <FormControlLabel
                          control={(
                            <GreenCheckbox
                              checked={users.includes(teacher._id)}
                              onChange={() => { handleCheckboxClick(teacher._id); }}
                            />
                        )}
                          label={teacher.name}
                        />
                      </FormControl>
                    </Grid>
                  );
                })
            }
          </Grid>
          <Grid container justifyContent="center" style={{ marginTop: 15 }}>
            <AppButton
              title={(batch.users && batch.users.length > 1) ? 'Update' : 'Assign'}
              // disabled={users.length === 1}
              onClick={assignTeachers}
            />
          </Grid>
        </Grid>
      </Modal>
      <Grid container justifyContent="space-between" style={{ marginTop: 15, marginBottom: 15 }}>
        <Grid>
          <Button onClick={() => { setOpen(true); }} startIcon={<LocalLibraryIcon />} className={classes.button}>
            Assign Teachers
          </Button>
        </Grid>
        <Grid item>
          <Invite
            userRole="teacher"
            refreshPage={refreshPage}
            batch={batch._id}
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid>
          <Paper elevation={0} className={classes.titleBar}>
            <Typography variant="subtitle1" className={classes.subtitle}>Assigned Teachers</Typography>
          </Paper>
        </Grid>
        <Grid container className={classes.itemAligment} spacing={3}>
          {
              accessTeachers && accessTeachers.map((user) => {
                return (
                  <Grid key={user._id} item className={classes.deviceItem}>
                    <Paper className={classes.userCard}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={10} onClick={() => { setTeacherData(user); }}>
                          <Typography variant="subtitle1" className={classes.subtitle} noWrap>
                            {user.name}
                          </Typography>
                          <Typography variant="caption" display="block" noWrap>
                            {user.phone}
                          </Typography>
                          <Typography variant="caption" display="block" noWrap>
                            {user.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })
          }
          {
            !accessTeachers || accessTeachers.length === 0
              ? (
                <Grid container justifyContent="center">
                  <Paper elevation={0} className={classes.titleBar}>
                    <Typography align="center">
                      No assigned yet. Please assign teachers.
                    </Typography>
                  </Paper>
                </Grid>
              ) : null
          }
        </Grid>
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    teacherMappings: state.userMapping.teacherMappings,
    loading: state.userMapping.fetching,
    newBatch: state.batch.data,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tab3));
