import React from 'react';

import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { TOKEN } from '../../utils/constants';

import Modal from '../Modal/Modal';
import AppButton from '../App/AppButton';

export default (props) => {
  const bodyRef = React.createRef();
  const { text, paperId } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  // const createPdf = () => { return props.createPdf(bodyRef.current); };
  return (
    <>
      <Modal open={open} handleClose={handleClose} title="Choose">
        <Grid>
          <br />
          <Grid container justifyContent="center" alignItems="center">
            <AppButton
              title="Print Without Solution"
              href={`/dashboard/print-paper?paperId=${paperId}&token=${localStorage.getItem(TOKEN)}`}
              target="_blank"
            />
          </Grid>
          <br />
          <Grid container justifyContent="center" alignItems="center">
            <Typography>OR</Typography>
          </Grid>
          <br />
          <Grid container justifyContent="center" alignItems="center">
            <AppButton
              title="Print With Solution"
              href={`/dashboard/print-paper?paperId=${paperId}&token=${localStorage.getItem(TOKEN)}&solution=true`}
              target="_blank"
            />
          </Grid>
          <br />
        </Grid>
      </Modal>
      <Grid container justifyContent="flex-end">
        <Button
          data-tour="create-pdf"
          variant="outlined"
          color="primary"
          endIcon={<PrintIcon />}
          onClick={() => { setOpen(true); }}
        >
          {text || 'Create PDF'}
        </Button>
      </Grid>
      <section ref={bodyRef}>
        {props.children}
      </section>
    </>
  );
};
