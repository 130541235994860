import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { capitalizeFirstWord, splitCamelCase } from '../../utils/util';

import Actions from '../../actions';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      minHeight: '80vh',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },
    paperContainer: {
      width: 500,
      minHeight: 400,
      padding: 50,
      paddingTop: 30,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: 15,
      },
      boxShadow: theme.boxShadow,
      borderRadius: 5,
    },
    icon: {
      border: `3px solid ${theme.lightBlue}`,
      boxShadow: theme.boxShadow,
      borderRadius: '50%',
      color: theme.lightSecondaryColor,
      height: 48,
      width: 48,
      padding: 10,
      marginRight: 15,
    },
    contactContainer: {
      marginBottom: 5,
    },
    title: {
      fontSize: 22,
      fontWeight: 600,
      color: '#333',
      marginBottom: 5,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    titleContainer: {
      borderBottom: `1px solid ${theme.lightBlue}`,
      marginBottom: 25,
    },
    value: {
      marginBottom: 5,
      fontWeight: 600,
      color: '#555',
    },
  };
});

const Profile = (props) => {
  const { user, loadUser } = props;
  const classes = useStyles();
  const FIELDS = ['name', 'phone', 'email', 'role', 'pinCode'];
  const ICONS = [
    <PersonIcon className={classes.icon} />,
    <PhoneInTalkIcon className={classes.icon} />,
    <EmailIcon className={classes.icon} />,
    <AssignmentIndIcon className={classes.icon} />,
    <LocationOnIcon className={classes.icon} />,
  ];

  React.useEffect(() => {
    if (!user) loadUser();
  }, [user]);

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.container}>
      <Paper elevation={0} className={classes.paperContainer}>
        <Grid className={classes.titleContainer}>
          <Typography className={classes.title}>Profile Details</Typography>
        </Grid>
        {
          FIELDS.map((field, index) => {
            return (
              <Grid key={field} container alignItems="center" className={classes.contactContainer}>
                <Grid item>
                  <Tooltip title={capitalizeFirstWord(splitCamelCase(field))} arrow>
                    {ICONS[index]}
                  </Tooltip>
                </Grid>
                <Grid item xs>
                  <Typography align="left" noWrap className={classes.value}>
                    {user && user[field]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        }
      </Paper>
    </Grid>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
