import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { QUESTION_TYPES } from '../../utils/constants';
import { splitCamelCase } from '../../utils/util';

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Radio size="small" color="default" {...props} />; });

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[400],
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => { return <Checkbox size="small" color="default" {...props} />; });

const useStyles = makeStyles(() => {
  return {
    typography: {
      fontSize: 12,
    },
    optionLabel: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 12,
    },
    textField: {
      paddingTop: 15,
    },
    queSpace: {
      marginBottom: 15,
      marginTop: 15,
    },
    type: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      fontSize: 10,
    },
    correct: {
      fontWeight: 'bold',
      marginLeft: 16,
      fontSize: 10,
    },
    incorrect: {
      fontWeight: 'bold',
      marginLeft: 16,
      fontSize: 10,
    },
    unattempted: {
      fontWeight: 'bold',
      marginLeft: 16,
      fontSize: 10,
    },
    optionContainer: {
      marginLeft: 22,
      fontSize: 10,
    },
  };
});

export default function Question(props) {
  const {
    question, handleIndexChange, handleAnswerChange, handleRadioChange, title, disabled,
    noDivider, marksMapping, shouldDisplayAnswer, shouldNotShowMarks,
  } = props;
  const classes = useStyles();
  const {
    questionType, options, correctOptionIndexes, answer, answers, solution,
  } = question;

  const questionHTML = question.question;
  const marks = question.marks;

  const getHTML = (html) => {
    return (
      <Typography
        dangerouslySetInnerHTML={{ __html: html }}
        className={classes.typography}
      />
    );
  };

  return (
    <>
      <div>
        <div className="print">
          {
            !shouldNotShowMarks ? (
              <Grid container justifyContent="flex-end">
                <Grid item md={4} sm={4} xs={4} container justifyContent="flex-end">
                  <Typography variant="caption" className={classes.type}>
                    {
                      splitCamelCase(question.questionType)
                    }
                  </Typography>
                  <Typography variant="caption" className={classes.correct}>
                    {'+'}
                    {
                      (marks && marks.correct) || marksMapping[question.questionType].correct
                    }
                  </Typography>
                  {
                    (marks && marks.incorrect) || marksMapping[question.questionType].incorrect
                      ? (
                        <Typography variant="caption" className={classes.incorrect}>
                          {
                        ((marks && marks.incorrect) || marksMapping[question.questionType].incorrect)
                        }
                        </Typography>
                      ) : null
                  }
                  {
                      (marks && marks.unattempted) || marksMapping[question.questionType].unattempted
                        ? (
                          <Typography variant="caption" className={classes.unattempted}>
                            {
                          ((marks && marks.unattempted) || marksMapping[question.questionType].unattempted) > 0
                            ? '+' : ''
                        }
                            {
                          (marks && marks.unattempted) || marksMapping[question.questionType].unattempted
                        }
                          </Typography>
                        ) : null
                  }
                </Grid>
              </Grid>
            ) : null
          }
          <div style={{ display: 'flex' }}>
            {
              title ? (
                <Typography className={classes.title}>
                  {`${title}.`}
                  &nbsp;
                </Typography>
              ) : null
            }
            {
              getHTML(questionHTML)
            }
          </div>
        </div>
        <div className="print">
          {
            questionType === QUESTION_TYPES.BOOLEAN || questionType === QUESTION_TYPES.SINGLE_CHOICE
              ? (
                <FormControl component="fieldset" className={classes.optionContainer}>
                  <RadioGroup
                    aria-label="options"
                    name="answer"
                    value={shouldDisplayAnswer && correctOptionIndexes[0] !== undefined
                      ? correctOptionIndexes[0] : ''}
                    onChange={handleRadioChange}
                    inputprops={disabled ? { readOnly: true } : {}}
                  >
                    {
                        options && options.map((option, index) => {
                          return (
                            <FormControlLabel
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              value={index}
                              control={<GreenRadio />}
                              label={getHTML(option)}
                            />
                          );
                        })
                    }
                  </RadioGroup>
                </FormControl>
              ) : null
          }
          {
            questionType === QUESTION_TYPES.MULTIPLE_CHOICE
              ? (
                <FormControl component="fieldset" className={classes.optionContainer}>
                  {
                      options && options.map((option, index) => {
                        const isChecked = shouldDisplayAnswer ? correctOptionIndexes.includes(index) : false;
                        return (
                          <FormControlLabel
                          // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            control={(
                              <GreenCheckbox
                                checked={isChecked}
                                onChange={() => { return (!disabled && handleIndexChange(index)); }}
                                name={index.toString()}
                                inputprops={disabled ? { readOnly: true } : {}}
                              />
                            )}
                            label={getHTML(option)}
                          />
                        );
                      })
                  }
                </FormControl>
              ) : null
          }
          {
            questionType === QUESTION_TYPES.INTEGER || (disabled && questionType === QUESTION_TYPES.TEXT)
              ? (
                <FormControl component="fieldset" className={classes.textField}>
                  {
                      disabled ? null
                        : (
                          <FormLabel component="legend" className={classes.optionLabel}>
                            Enter Your Answer
                          </FormLabel>
                        )
                  }
                  {
                      shouldDisplayAnswer
                        ? (
                          <Grid>
                            {
                              questionType === QUESTION_TYPES.INTEGER
                                ? (
                                  <TextField
                                    id="answer"
                                    label={disabled ? 'Answer' : 'Enter Answer'}
                                    name="answer"
                                    variant="outlined"
                                    value={disabled ? answers[0] : answer}
                                    onChange={handleAnswerChange}
                                    type={questionType === QUESTION_TYPES.INTEGER ? 'number' : 'text'}
                                    required
                                    inputprops={disabled ? { readOnly: true } : {}}
                                  />
                                ) : (
                                  <div style={{ display: 'flex' }}>
                                    <Typography component="span" className={classes.title}>
                                      Ans:&nbsp;
                                    </Typography>
                                    {getHTML(answers[0])}
                                  </div>
                                )
                            }
                          </Grid>
                        ) : null
                  }
                </FormControl>
              ) : null
          }
        </div>
        {
            shouldDisplayAnswer && solution && disabled ? (
              <div style={{ display: 'flex' }} className="print">
                <Typography component="span" className={classes.title}>
                  Solution:&nbsp;
                </Typography>
                {getHTML(solution)}
              </div>
            ) : null
        }
      </div>
      {
        noDivider ? null : <Divider />
      }
    </>
  );
}
