import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import GetAppIcon from '@mui/icons-material/GetApp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Actions from '../../../actions';

import Snack from '../../Progress/Snack';

import TitleBar from '../../TitleBar';
import Select from '../../Select';
import ContentEditor from '../../Editor/ContentEditor';
import ShowFunction from '../../Functions/Display/ShowFunction';
import DialogBox from '../../Dialog/DialogBox';
import AppButton from '../../App/AppButton';
import IconButton from '../../App/IconButton';
import Modal from '../../Modal/Modal';
import ImportQuestions from '../../ImportQuestions/ImportQuestions';

import PdfContainer from '../../PDF/PdfContainer';

import { questionValidationSchema, getCombineValue } from '../../../utils/util';
import {
  QUESTION_TYPES, STANDARD_QUESTION_TYPES, STANDARDS, TOUR_TYPES,
} from '../../../utils/constants';
import Question from '../../Display/Question';
import PaperDetails from '../../Display/PaperDetails';

import Tour from '../../Tour/Tour';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      width: 200,
      padding: 15,
      marginTop: 30,
      [theme.breakpoints.down('sm')]: {
        width: 200,
      },
    },
    submit: {
      marginTop: 30,
      marginBottom: 30,
    },
    heading: {
      fontWeight: 600,
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 15,
    },
    topContainer: {
      padding: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    optionContainer: {
    },
    displayPaper: {
      minHeight: 200,
      marginLeft: 15,
      marginRight: 15,
      marginBottom: 30,
      padding: 25,
      boxShadow: theme.boxShadow,
    },
    noque: {
      height: 200,
    },
    qType: {
      minWidth: 200,
    },
    active: {
      opacity: 1,
    },
    inactive: {
      opacity: 0,
      pointerEvents: 'none',
    },
  };
});

function Step2(props) {
  const {
    handleNext, snackData, setSnackData, setQuestionData, handleBack, removeQuestionFromPaper,
  } = props;
  const paper = props.paper || {};
  const questions = paper.questions || [];
  const questionData = props.question || {};
  const classes = useStyles();
  const getInitialState = (propsQuestion = {}) => {
    return {
      _id: propsQuestion._id,
      question: propsQuestion.question || '',
      questionType: propsQuestion.questionType || QUESTION_TYPES.SINGLE_CHOICE,
      standard: propsQuestion.standard || paper.standard || '',
      subject: propsQuestion.subject || paper.subject || '',
      subjectName: propsQuestion.subjectName || paper.subjectName || '',
      standardName: propsQuestion.standardName || paper.standardName || '',
      year: propsQuestion.year || paper.year || '',
      'option 1': (propsQuestion.options && propsQuestion.options[0]) || '',
      'option 2': (propsQuestion.options && propsQuestion.options[1]) || '',
      'option 3': (propsQuestion.options && propsQuestion.options[2]) || '',
      'option 4': (propsQuestion.options && propsQuestion.options[3]) || '',
      solutionId: propsQuestion.solutionId,
      solution: propsQuestion.solution || '',
      correctOptionIndexes: propsQuestion.correctOptionIndexes || [],
      answer: propsQuestion.answer || (propsQuestion.answers && propsQuestion.answers[0]) || '',
      answers: propsQuestion.answers || [],
      paperId: paper._id,
      marks: propsQuestion.marks,
      marksMapping: paper.marksMapping,
      chapter: propsQuestion.chapter,
    };
  };

  const [snack, setSnack] = React.useState({});
  const [name, setName] = React.useState('question');
  const [question, setQuestion] = React.useState(getInitialState(questionData));
  const [disableQueType, setDisableQueType] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [modifyQue, setModifyQue] = React.useState(null);
  const [position] = React.useState({ start: 0, end: 0 });
  const [isOpenImport, setIsSetImport] = React.useState(false);

  const totalQuestions = (questions && questions.length) || 0;

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const handleChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setQuestion((prevQuestion) => {
      const newElement = value;
      return { ...prevQuestion, [elName]: newElement };
    });
  };

  const closeImport = () => {
    setIsSetImport(false);
  };

  const openImport = () => {
    setIsSetImport(true);
  };

  const editQuestion = (propsQuestion) => {
    setModifyQue(propsQuestion);
    setOpenEditDialog(true);
  };

  const confirmEditQuestion = (propsQuestion) => {
    const questionObj = getInitialState(propsQuestion);
    setOpenEditDialog(false);
    setQuestion(questionObj);
    setDisableQueType(true);
    setModifyQue(null);
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
  };

  const confirmDeleteQuestion = (propsQuestion) => {
    setOpenDeleteDialog(false);
    removeQuestionFromPaper({ paperId: paper._id, question: propsQuestion })
      .then(() => {
        let index;
        questions.forEach((que, i) => {
          if (que._id === propsQuestion._id) {
            index = i;
            return false;
          }
          return true;
        });
        if (index !== undefined) {
          questions.splice(index, 1);
          setSnack({
            open: true,
            severity: 'success',
            message: 'Question deleted successfully!',
          });
        }
        setModifyQue(null);
      })
      .catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  const deleteQuestion = (propsQuestion) => {
    setModifyQue(propsQuestion);
    setOpenDeleteDialog(true);
  };

  const onCloseDialog = () => {
    setModifyQue(null);
    setOpenDeleteDialog(false);
    setOpenEditDialog(false);
  };

  const handleCombineChange = (e) => {
    const elName = e.target.name;
    const value = e.target.value;
    setQuestion((prevQuestion) => {
      const preValue = prevQuestion[elName];
      return { ...prevQuestion, [elName]: getCombineValue(preValue, value, position) };
    });
  };

  const setFocus = (elName) => {
    setName(elName);
  };

  const handleSubmit = () => {
    let options = [question['option 1'], question['option 2'], question['option 3'], question['option 4']];
    if (QUESTION_TYPES.BOOLEAN === question.questionType) options = ['true', 'false'];
    else options = options.filter((option) => { return !!option; });
    if ([QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.MULTIPLE_CHOICE].includes(question.questionType)
    && options.length !== 4) {
      setSnack({
        open: true,
        severity: 'warning',
        message: 'Please Add Options!',
      });
      return;
    }
    if ((QUESTION_TYPES.BOOLEAN === question.questionType) && options.length !== 2) {
      setSnack({
        open: true,
        severity: 'warning',
        message: 'Boolean options are not provided!',
      });
      return;
    }
    const data = { ...question, options };
    questionValidationSchema.validate(data)
      .then((validatedQuestion) => {
        setQuestionData(validatedQuestion);
        handleNext();
      })
      .catch((err) => {
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  const closeSnack = () => {
    setSnack({});
  };

  React.useEffect(() => {
    if (snackData) {
      setSnack(snackData);
      setSnackData(null);
    }
  }, [snackData, setSnackData]);

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
  }, []);

  if (!question._id && disableQueType) setDisableQueType(false);

  return (
    <>
      <Snack
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <Modal open={isOpenImport} handleClose={closeImport} title="Import Questions">
        <ImportQuestions
          questionIds={questions.map((que) => { return que._id; })}
          standard={question.standard}
          subjectName={question.subjectName}
          paperId={paper._id}
        />
      </Modal>
      <div className={classes.container}>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid item><Tour type={TOUR_TYPES.ADD_QUESTION} title="How to add a question?" /></Grid>
          <Grid item><IconButton title="Import Questions" icon={<GetAppIcon />} onClick={openImport} /></Grid>
        </Grid>
      </div>
      <DialogBox
        open={openEditDialog}
        onClose={onCloseDialog}
        title="Edit this question"
        confirm={() => { confirmEditQuestion(modifyQue); }}
      >
        Are you sure want to edit this question?
      </DialogBox>
      <DialogBox
        open={openDeleteDialog}
        onClose={onCloseDialog}
        title="Delete this question"
        confirm={() => { confirmDeleteQuestion(modifyQue); }}
      >
        Are you sure want to delete this question?
      </DialogBox>
      <div className={classes.topContainer}>
        <Grid container justifyContent="center">
          <Grid className={classes.qType} data-tour="question-type">
            <Select
              title="Question Type"
              data={STANDARD_QUESTION_TYPES[question.standardName] || STANDARD_QUESTION_TYPES[STANDARDS.OTHER]}
              name="questionType"
              value={question.questionType}
              handleChange={handleChange}
              disabled={disableQueType}
            />
          </Grid>
        </Grid>
        <TitleBar title="Add Question" />
        <div className={classes.container}>
          <div className={name === 'question' ? classes.active : classes.inactive}>
            <ShowFunction name="question" handleChange={handleCombineChange} />
          </div>
          <div data-tour="question">
            <ContentEditor
              name="question"
              html={question.question}
              handleChange={handleChange}
              setParentFocus={setFocus}
              setPosition={setPosition}
              minHeight={100}
              placeholder={`Add question ${totalQuestions + 1}`}
            />
          </div>
        </div>
        {
          question.questionType === QUESTION_TYPES.SINGLE_CHOICE
          || question.questionType === QUESTION_TYPES.MULTIPLE_CHOICE
            ? (
              <>
                <TitleBar title="Add Options" />
                <div className={classes.container}>
                  <div className={name === 'option 1' ? classes.active : classes.inactive}>
                    <ShowFunction name="option 1" handleChange={handleCombineChange} />
                  </div>
                  <Grid className={classes.optionContainer}>
                    <ContentEditor
                      name="option 1"
                      html={question['option 1']}
                      handleChange={handleChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                  <div className={name === 'option 2' ? classes.active : classes.inactive}>
                    <ShowFunction name="option 2" handleChange={handleCombineChange} />
                  </div>
                  <Grid className={classes.optionContainer}>
                    <ContentEditor
                      name="option 2"
                      html={question['option 2']}
                      handleChange={handleChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                  <div className={name === 'option 3' ? classes.active : classes.inactive}>
                    <ShowFunction name="option 3" handleChange={handleCombineChange} />
                  </div>
                  <Grid className={classes.optionContainer}>
                    <ContentEditor
                      name="option 3"
                      html={question['option 3']}
                      handleChange={handleChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                  <div className={name === 'option 4' ? classes.active : classes.inactive}>
                    <ShowFunction name="option 4" handleChange={handleCombineChange} />
                  </div>
                  <Grid>
                    <ContentEditor
                      name="option 4"
                      html={question['option 4']}
                      handleChange={handleChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                </div>
              </>
            ) : null
        }
        <Grid container justifyContent="center" alignItems="center">
          <Paper elevation={0} className={classes.paper}>
            <Grid container justifyContent="center">
              {/* <Grid item>
                <AppButton
                  onClick={handleBack}
                  title="Back"
                />
              </Grid> */}
              <Grid item>
                <AppButton
                  onClick={handleSubmit}
                  title="Next"
                  endIcon={<NavigateNextIcon />}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid>
          <TitleBar title="Added Questions" />
          <Grid style={{ marginRight: 15, marginBottom: 15 }}>
            <PdfContainer paperId={paper && paper._id} />
          </Grid>
          <PaperDetails paper={paper} setSnack={setSnack} handleEdit={handleBack} />
          <Paper elevation={0} className={classes.displayPaper} data-tour="added-question">
            {
              questions.length === 0
                ? (
                  <Grid container justifyContent="center" alignItems="center" className={classes.noque}>
                    <Typography>No questions added yet. Please add question.</Typography>
                  </Grid>
                )
                : null
              }
            {
              questions.map((que, index) => {
                return (
                  <Question
                    key={que._id}
                    question={que}
                    marksMapping={paper.marksMapping}
                    title={index + 1}
                    editQuestion={editQuestion}
                    deleteQuestion={deleteQuestion}
                    disabled
                  />
                );
              })
            }
          </Paper>
        </Grid>
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    snackData: state.snack.data,
    paper: state.paper.data,
    question: state.question.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
