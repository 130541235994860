import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      background: theme.primaryColor,
      color: '#fff',
      fontWeight: 'bold',
      paddingLeft: 30,
      paddingRight: 30,
    },
  };
});

const AppButton = (props) => {
  const {
    onClick, title, disabled, endIcon, href, target, startIcon,
  } = props;
  const classes = useStyles();
  const styles = {
    background: 'linear-gradient(103.37deg, #99179F 4.77%, #2068A8 95.46%)',
    color: '#fff',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  };
  return (
    <>
      <Button
        disabled={disabled || false}
        className={classes.button}
        variant="contained"
        color="primary"
        href={href}
        target={target}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        style={styles}
      >
        {title || props.children}
      </Button>
    </>
  );
};

export default AppButton;
