/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
import React from "react";
import "./App.css";

import Body from "./routes/Body";
import Progress from "./components/Progress/Progress";

function App() {
  return (
    <div className="App" id="body">
      <Body />
      <Progress />
    </div>
  );
}

export default App;
