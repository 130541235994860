const initialState = {
  loading: false,
  fetching: false,
};

export default function usersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'FETCHING_NOTES': return { ...state, loading: true };
    case 'FETCHED_NOTES': return { ...state, loading: false, notes: payload };
    case 'INSERTING_NOTE': return { ...state, fetching: true };
    case 'INSERTED_NOTE': return { ...state, fetching: false, note: payload };
    default: return state;
  }
}
