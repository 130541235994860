import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import PersonAddIcon from "@material-ui/icons/PersonAdd";

import Actions from "../../actions";

import Modal from "../Modal/Modal";
import UserInsertForm from "./UserInsertForm";
import IconButton from "./IconButton";

const useStyles = makeStyles(() => {
  return {
    formContainer: {
      textAlign: "center",
      maxWidth: 460,
    },
  };
});

const Invite = (props) => {
  const {
    userRole,
    refreshPage,
    standard,
    standardName,
    batch,
    batchId,
    year,
  } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);

  const handleClick = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal open={openModal} handleClose={closeModal}>
        <Grid className={classes.formContainer}>
          <UserInsertForm
            standard={standard}
            handleClose={closeModal}
            standardName={standardName}
            batch={batch}
            batchId={batchId}
            year={year}
            refreshPage={refreshPage}
            isInvite
            role={userRole}
          />
        </Grid>
      </Modal>
      <IconButton
        onClick={handleClick}
        icon={<PersonAddIcon />}
        dataTour={`${userRole}-invite`}
        title={`Invite ${userRole}`}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(null, mapDispatchToProps)(Invite);
