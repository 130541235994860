import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import PostAddIcon from '@material-ui/icons/PostAdd';

import AppBar from '../components/AppBar';
import MyContent from '../components/Display/MyContent';
import TitleBar from '../components/TitleBar';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    buttonContainer: {
      paddingTop: 30,
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      background: theme.secondaryColor,
      color: '#fff',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 'bold',
      boxShadow: theme.boxShadow,
    },
    paperContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  };
});

const AllContent = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar>
        <div className={classes.container}>
          <div className={classes.buttonContainer}>
            <Button href="/dashboard/add-content" className={classes.button} startIcon={<PostAddIcon />}>
              Add Study Material
            </Button>
          </div>
          <TitleBar title="My Study Materials" />
          <div className={classes.paperContainer}>
            <MyContent />
          </div>
        </div>
      </AppBar>
    </>
  );
};

export default (AllContent);
