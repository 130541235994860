/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-console */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from "@material-ui/core/Checkbox";
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import IconButton from '@material-ui/core/IconButton';
// import InputLabel from '@material-ui/core/InputLabel';

import Actions from "../../actions";

import { ROLES, BATCHES } from "../../utils/constants";
import {
  userSignupValidationSchema,
  checkAndGetNumber,
  getYears,
  capitalizeFirstWord,
} from "../../utils/util";

import Select from "../Select";
import InputField from "../InputField";
// import Modal from '../Modal/Modal';
// import OTP from '../Authentication/OTP';
import AutoCompleteSelect from "./AutoCompleteSelect";

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: green[400],
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => {
  return <Checkbox color="default" {...props} />;
});

const useStyles = makeStyles((theme) => {
  return {
    formControl: {
      marginBottom: 15,
    },
    submit: {
      marginTop: 15,
      marginBottom: 30,
    },
    button: {
      background: theme.primaryColor,
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        background: theme.primaryColor,
        color: "#fff",
      },
    },
    text: {
      textTransform: "capitalize",
      marginTop: 15,
      fontWeight: "bold",
    },
  };
});

function UserInsertForm(props) {
  const {
    createUserProfile,
    fetchStandards,
    sendInvite,
    isInvite,
    role,
    setSnack,
    standards,
    loadingStandards,
    refreshPage,
    setLoading,
    verifyOTP,
    paramRole,
    standard,
    standardName,
    batch,
    batchId,
    year,
    handleClose,
  } = props;
  const currentRole = role;
  // const passDisplay = isInvite ? 'none' : 'inherit';
  const [standardDisplay, setStandardDisplay] = React.useState("none");
  // const [open, setOpen] = React.useState(false);
  const text = isInvite ? `Invite ${currentRole}` : "Sign Up";
  const classes = useStyles();

  const getInitialState = () => {
    return {
      name: "",
      role: currentRole || "",
      email: "",
      phone: "",
      password: isInvite ? "12345678" : "",
      standard: standard || "",
      standardName: standardName || "",
      batchId: batchId || 1,
      year: year || new Date().getFullYear(),
      showPassword: false,
      isStudentInvite: !!(currentRole === ROLES.STUDENT && isInvite),
      batch,
      isChecked: !!batch && currentRole === ROLES.TEACHER,
      isInvite,
    };
  };

  const [data, setData] = React.useState(getInitialState());

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleCheckboxClick = () => {
    setData({ ...data, isChecked: !data.isChecked });
  };

  const handleStandardChange = (e, newObj) => {
    const stdName = (newObj && newObj.name) || "";
    setData({
      ...data,
      standard: (newObj && newObj._id) || "",
      standardName: stdName,
    });
  };

  // const close = () => {
  //   setOpen(false);
  // };

  const verify = (newData, code = "2105") => {
    if (!code || code.length !== 4) {
      setSnack({
        open: true,
        severity: "warning",
        message: "Please enter valid code!",
      });
      return;
    }
    setLoading(true);
    // setOpen(false);
    verifyOTP({ ...newData, code: parseInt(code, 10) })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // setOpen(true);
        setSnack({
          open: true,
          severity: "error",
          message: `Invalid OTP! ${err.message}`,
        });
      });
  };

  const sendProfileToBackend = () => {
    const insertData = { ...data, phone: checkAndGetNumber(data.phone) };
    setLoading(true);
    userSignupValidationSchema
      .validate(insertData)
      .then(() => {
        if (isInvite) {
          return sendInvite(insertData).then(() => {
            setSnack({
              open: true,
              severity: "success",
              message: `${currentRole} invited successfully!`,
            });
            setData(getInitialState());
            setLoading(false);
            handleClose();
            if (refreshPage) refreshPage();
          });
        }
        return createUserProfile(insertData)
          .then(() => {
            return verify(insertData);
          })
          .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setSnack({
              open: true,
              severity: "error",
              message:
                error.message ||
                "Something went wrong! Please add correct data.",
            });
          });
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          open: true,
          severity: "error",
          message:
            error.message || "Something went wrong! Please add correct data.",
        });
      });
  };

  // const handleClickShowPassword = () => {
  //   setData((prevData) => { return { ...prevData, showPassword: !prevData.showPassword }; });
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  React.useEffect(() => {
    if (data.role === ROLES.STUDENT) {
      setStandardDisplay("block");
      if (!standards && !loadingStandards) fetchStandards();
    } else {
      setStandardDisplay("none");
    }
  }, [data.role, standards, fetchStandards, loadingStandards]);

  React.useEffect(() => {
    if (Object.values(ROLES).includes(paramRole)) {
      setData({ ...data, role: paramRole });
    }
  }, [paramRole]);

  return (
    <>
      {/* <Modal open={open} handleClose={close} title={`${data.role} Verification`}>
        <OTP data={data} verify={verify} />
      </Modal> */}
      <Typography variant="subtitle1" className={classes.text}>
        {text}
      </Typography>
      {isInvite ? null : (
        <Select
          title="Role"
          data={currentRole ? [currentRole] : [ROLES.TEACHER]}
          name="role"
          value={data.role}
          handleChange={handleChange}
          isArray
          isCapitalize
        />
      )}
      <InputField
        name="name"
        title={`${capitalizeFirstWord(data.role)} Name`}
        value={data.name}
        handleChange={handleChange}
      />
      <InputField
        name="phone"
        title={`${capitalizeFirstWord(data.role)} Phone Number`}
        type="number"
        value={data.phone}
        handleChange={handleChange}
      />
      <InputField
        name="email"
        title={`Email${isInvite ? " (Optional)" : ""}`}
        type="email"
        value={data.email}
        handleChange={handleChange}
      />
      <div style={{ display: standardDisplay }}>
        <AutoCompleteSelect
          title="Standard"
          data={standards}
          name="standard"
          value={data.standard}
          handleChange={handleStandardChange}
          disabled={!!standard}
        />
      </div>
      {/* <div style={{ display: passDisplay }}>
        <FormControl fullWidth className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            type={(data.showPassword || isInvite) ? 'text' : 'password'}
            value={data.password}
            onChange={handleChange}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {data.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
                )}
            labelWidth={70}
          />
        </FormControl>
      </div> */}
      {data.isStudentInvite ? (
        <>
          <Select
            title="Batch"
            data={BATCHES}
            name="batchId"
            value={data.batchId}
            handleChange={handleChange}
            disabled={!!batchId}
          />
          <Select
            title="Batch Year"
            data={getYears(5)}
            name="year"
            value={data.year}
            handleChange={handleChange}
            isArray
            disabled={!!year}
          />
        </>
      ) : null}
      {data.batch && data.role === ROLES.TEACHER ? (
        <FormControl fullWidth className={classes.formControl}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={data.isChecked}
                onChange={handleCheckboxClick}
                name="isChecked"
              />
            }
            label="Assign inviting teacher for this batch"
          />
        </FormControl>
      ) : null}
      <Grid container className={classes.submit} justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={sendProfileToBackend}
        >
          {text}
        </Button>
      </Grid>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    standards: state.standards.data,
    loadingStandards: state.standards.fetching,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInsertForm);
