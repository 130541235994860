import React from 'react';

// eslint-disable-next-line import/no-cycle
import SingleInput from './FunctionModels/SingleInput';

const Subscript = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const NAME = 'subscript';

  const getHTML = (data) => {
    const bottom = data && data.startsWith('<') ? 'bottom: -0.7em;' : '';
    const align = data && data.startsWith('<') ? 'vertical-align: bottom;' : '';
    const html = `<sub style="${align} position: relative; ${bottom}">${data}</sub>&nbsp;`;
    return html;
  };

  return (
    <>
      <SingleInput
        contentName={NAME}
        name={name}
        getHTML={getHTML}
        handleChange={handleChange}
        closeModal={closeModal}
        isAutoFocus
      />
    </>
  );
};

export default Subscript;
