import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { splitCamelCase } from '../../utils/util';

import AppButton from './AppButton';

const useStyles = makeStyles(() => {
  return {
    table: {
    },
    queType: {
      textTransform: 'capitalize',
    },
    textField: {
    },
    buttonContainer: {
      paddingTop: 15,
    },
  };
});

const MarkingSchema = (props) => {
  const { marksMapping, handleChange, handleClose } = props;
  const classes = useStyles();
  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Correct</TableCell>
              <TableCell>Incorrect</TableCell>
              <TableCell>Unattempted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {marksMapping && Object.keys(marksMapping).map((queType) => {
              const marks = marksMapping[queType];
              return (
                <TableRow key={queType}>
                  <TableCell className={classes.queType}>{splitCamelCase(queType)}</TableCell>
                  <TableCell>
                    <TextField
                      name={`${queType}_correct`}
                      value={marks.correct}
                      type="number"
                      variant="outlined"
                      size="small"
                      className={classes.textField}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name={`${queType}_incorrect`}
                      value={marks.incorrect}
                      type="number"
                      variant="outlined"
                      size="small"
                      className={classes.textField}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name={`${queType}_unattempted`}
                      value={marks.unattempted}
                      type="number"
                      variant="outlined"
                      size="small"
                      className={classes.textField}
                      onChange={handleChange}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="center" className={classes.buttonContainer}>
        <AppButton title="Done" onClick={handleClose} />
      </Grid>
    </>
  );
};

export default MarkingSchema;
