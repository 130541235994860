import React from 'react';

import ScheduleMeeting from '../../Display/ScheduleMeeting';

const Tab4 = (props) => {
  const batch = props.batch || {};
  return (
    <>
      <div style={{ marginTop: 30 }}>
        <ScheduleMeeting
          batch={batch._id}
          hideOtherMeetings
          hideTitleBar
          title="Classes"
        />
      </div>
    </>
  );
};

export default Tab4;
