import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

import Actions from '../../actions';

import { groupBy, ALL } from '../../utils/util';

import DialogBox from '../Dialog/DialogBox';
import Snack from '../Progress/Snack';
import ExamPaper from '../App/Paper';
import Filter from '../App/Filter';

const useStyles = makeStyles((theme) => {
  return {
    typography: {
      fontSize: 16,
      maxWidth: '80%',
    },
    paper: {
      marginBottom: 15,
      boxShadow: theme.boxShadow,
    },
    itemAligment: {
      '@media (max-width:460px)': {
        justifyContent: 'center',
      },
      marginBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
    },
    standard: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 15px',
      color: '#333',
      fontSize: 16,
    },
    titleIcon: {
      color: theme.primaryColor,
      marginRight: 8,
    },
    subtitle: {
      fontWeight: 'bold',
    },
  };
});

function MyPaper(props) {
  const {
    deletePaper, papers, loading, users, standard, fetchPapers,
  } = props;
  const classes = useStyles();
  const [deletePaperId, setDeletePaperId] = React.useState(undefined);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [snack, setSnack] = React.useState({});
  const [refresh, setRefresh] = React.useState(false);
  const [groupedPapers, setGroupedPapers] = React.useState({});
  const [standards, setStandards] = React.useState([]);
  const [filterStandard, setFilterStandard] = React.useState(localStorage.getItem('filterStandard') || ALL);
  const [filterSubject, setFilterSubject] = React.useState(ALL);
  const [filterYear, setFilterYear] = React.useState(ALL);
  const [years, setYears] = React.useState([]);

  const standardNames = (groupedPapers && Object.keys(groupedPapers)) || [];

  const closeSnack = () => {
    setSnack({});
  };

  const handleDelete = (_id) => {
    setDeletePaperId(_id);
    setOpenDialog(true);
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const onCloseDialog = () => {
    setDeletePaperId(undefined);
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    deletePaper({ _id: deletePaperId })
      .then(() => {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Paper Deleted Successfully!',
        });
        refreshPage();
        onCloseDialog();
      });
  };

  const getGroupedPapers = (paperData = []) => {
    const grouped = groupBy(paperData, (obj) => { return `${obj.standard?.name} (${obj.subjectName}) Papers`; });
    return grouped;
  };

  const setData = (currentPapers) => {
    let paperYears = currentPapers.map((paper) => { return paper.year; });
    paperYears = [...new Set(paperYears)];
    paperYears = paperYears.filter((year) => { return !!year; });
    setYears(paperYears.sort());
    setGroupedPapers(getGroupedPapers(currentPapers));
  };

  React.useEffect(() => {
    fetchPapers({ users, standard });
  }, [users, standard, refresh]);

  React.useEffect(() => {
    if (papers) {
      const unique = {};
      const uniqueStandards = [];
      papers.forEach((paper) => {
        const standardId = paper.standard?._id;
        if (!unique[standardId]) {
          unique[standardId] = standardId;
          uniqueStandards.push(paper.standard);
        }
      });
      setStandards(uniqueStandards);
    }
  }, [papers]);

  React.useEffect(() => {
    if (papers) {
      if (filterStandard && filterStandard !== ALL) {
        const filterPapers = papers.filter((paper) => {
          return (paper.standard?._id === filterStandard
            && (!filterSubject || filterSubject === ALL || paper.subjectName === filterSubject)
            && (!filterYear || filterYear === ALL || paper.year === parseInt(filterYear, 10)));
        });
        setData(filterPapers);
      } else {
        setData(papers);
      }
    }
  }, [filterStandard, filterSubject, filterYear, papers]);

  return (
    <>
      {/* <Loader loading={loading} /> */}
      <Snack
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <DialogBox open={openDialog} onClose={onCloseDialog} title="Delete My Paper" confirm={confirmDelete}>
        Are you sure want to delete this paper?
      </DialogBox>
      <Filter
        standards={standards}
        years={years}
        setStandard={setFilterStandard}
        setSubjectName={setFilterSubject}
        setYear={setFilterYear}
        standard={filterStandard}
        subjectName={filterSubject}
        year={filterYear}
        isFullTest
        isYears
      />
      {
        loading ? (
          <Grid container justifyContent="center" alignItems="center" className={classes.loadingContainer}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container className={classes.container}>
            {
                standardNames.map((title) => {
                  return (
                    <Grid item xs={12} key={title}>
                      <Paper elevation={0} className={classes.standard}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <DynamicFeedIcon className={classes.titleIcon} />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="subtitle1" className={classes.subtitle}>{title}</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Grid container className={classes.itemAligment} spacing={3}>
                        {
                          groupedPapers[title] && groupedPapers[title].map((obj) => {
                            return (
                              <ExamPaper
                                key={obj._id}
                                refreshPage={refreshPage}
                                handleDelete={handleDelete}
                                paper={obj}
                              />
                            );
                          })
                        }
                      </Grid>
                    </Grid>
                  );
                })
              }
            {
              !loading && standardNames.length === 0
                ? (
                  <Grid container justifyContent="center">
                    <Paper elevation={0} className={classes.standard}>
                      <Typography align="center">
                        No paper created yet. Please create a paper.
                      </Typography>
                    </Paper>
                  </Grid>
                ) : null
            }
          </Grid>
        )
      }
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    papers: state.paper.papers,
    loading: state.paper.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPaper));
