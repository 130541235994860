import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';

import PublishButton from './PublishButton';

const useStyles = makeStyles((theme) => {
  return {
    card: {
      minHeight: 0,
    },
    paper: {
      boxShadow: theme.boxShadow,
      margin: '0 15px 15px 15px',
      padding: 15,
    },
    notPublished: {
      background: theme.secondaryColor,
      paddingLeft: 15,
      paddingRight: 15,
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.successColor,
      },
    },
    published: {
      background: theme.successColor,
      paddingLeft: 15,
      paddingRight: 15,
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.successColor,
      },
    },
    edit: {
      fontSize: 14,
      fontWeight: 'bold',
      textTransform: 'none',
      padding: 0,
      marginLeft: 8,
    },
    name: {
      maxWidth: 280,
    },
  };
});

export default function PaperDetails(props) {
  let { paper } = props;
  const { handleEdit } = props;
  const { setSnack } = props;
  paper = paper || {};
  const classes = useStyles();

  const copy = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Link coppied successfully!',
        });
      }).catch((err) => {
        setSnack({
          open: true,
          severity: 'error',
          message: `Link copy Error! ${err.message}`,
        });
      });
  };

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Grid container justifyContent="center" className={classes.card} alignItems="center">
          <Grid container item md={4} sm={12} xs={12} alignItems="center">
            <Tooltip title={paper.name} arrow>
              <Typography className={classes.name} noWrap><b>{paper.name}</b></Typography>
            </Tooltip>
            <Tooltip title="Edit Paper Details" arrow>
              <IconButton className={classes.edit} onClick={handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Typography>
              <b>{paper.maxMarks}</b>
              {' '}
              marks
            </Typography>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Typography>
              <b>{paper.duration}</b>
              {' '}
              mins
            </Typography>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            {
              paper.day
                ? (
                  <Typography>
                    <b>{paper.day}</b>
                    {' '}
                    day
                  </Typography>
                ) : null
            }
          </Grid>
          <Grid item container md={2} sm={12} xs={12} justifyContent="flex-end">
            <PublishButton paper={paper} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {
              paper.appLink
                ? (
                  <>
                    <Link href={paper.appLink} data-tour="paper-link" target={window.innerWidth > 600 ? '_blank' : ''}>
                      {paper.appLink}
                      {' '}
                    </Link>
                    <IconButton size="small" onClick={() => { copy(paper.appLink); }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </>
                ) : null
            }
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
