import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Modal from '../Modal/Modal';

const useStyles = makeStyles((theme) => {
  return {
    card: {
      minHeight: 0,
    },
    paperContainer: {
      padding: '0 16px 16px 16px',
    },
    paper: {
      boxShadow: theme.boxShadow,
      padding: 16,
    },
    join: {
      background: theme.secondaryColor,
      boxShadow: theme.boxShadow,
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 'bold',
      fontSize: 14,
      color: '#fff',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      marginRight: 10,
    },
    time: {
      fontSize: 15,
    },
    frequencyText: {
      fontSize: 12,
    },
    button: {
      background: theme.secondaryColor,
      color: '#fff',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      paddingLeft: 15,
      paddingRight: 15,
      fontWeight: 'bold',
      boxShadow: theme.boxShadow,
      fontSize: 14,
      textTransform: 'none',
    },
    title: {
      textAlign: 'right',
      fontWeight: 'bold',
    },
  };
});

export default function Result(props) {
  const { result } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} handleClose={close} title="Test Result">
        <Paper elevation={0} className="container" style={{ padding: '15px 0' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.title}>Paper Name:</TableCell>
                <TableCell>{result.paperName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Standard:</TableCell>
                <TableCell>{result.standardName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Subject:</TableCell>
                <TableCell>{result.subjectName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Max Marks:</TableCell>
                <TableCell>{result.maxMarks}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Marks Obtained:</TableCell>
                <TableCell>{result.marksObtained}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Time Taken:</TableCell>
                <TableCell>
                  {`${Math.floor(result.timeTakenInSeconds / 60)} mins ${result.timeTakenInSeconds % 60} secs`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Total Questions:</TableCell>
                <TableCell>{result.numberOfQuestion}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Correct Questions:</TableCell>
                <TableCell>{result.correctCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Incorrect Questions:</TableCell>
                <TableCell>{result.incorrectCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Unattempted Questions:</TableCell>
                <TableCell>{result.unattemptedCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.title}>Test Created By:</TableCell>
                <TableCell>{result.createdBy && result.createdBy.name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Modal>
      <Grid className={classes.paperContainer}>
        <Paper elevation={0} className={classes.paper}>
          <Grid container justifyContent="space-between" className={classes.card} alignItems="center">
            <Grid item md={9} sm={9} xs={7}>
              <Typography><b>{result.paperName}</b></Typography>
            </Grid>
            <Grid container item md={3} sm={3} xs={5} justifyContent="flex-end">
              <Button onClick={onClick} className={classes.button}>View Result</Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
