import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      paddingLeft: 5,
      paddingRight: 5,
      margin: 0,
      minWidth: 30,
      fontFamily: 'Roboto',
      boxShadow: theme.boxShadow,
    },
    active: {
      background: theme.primaryColor,
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.primaryColor,
      },
    },
    inActive: {
      background: '#e0e0e0',
      '&:hover': {
        background: '#e0e0e0',
      },
    },
  };
});

const EditButton = (props) => {
  const {
    cmdData, isFocused, id,
  } = props;
  const classes = useStyles();
  const [active, setActive] = React.useState(false);
  let state = document.queryCommandState(cmdData.cmd);

  const handleClick = (evt) => {
    evt.preventDefault();
    if (isFocused) {
      document.execCommand(cmdData.cmd, false, cmdData.arg); // Send the command to the browser
      state = document.queryCommandState(cmdData.cmd);
      setActive(state);
    }
  };

  // React.useEffect(() => {
  //   setActive(state);
  // }, [state]);

  return (
    <Tooltip title={cmdData.cmd} arrow>
      <Button
        key={id}
        id={id}
        onMouseDown={handleClick}
        className={[
          classes.button,
          state === true && active ? classes.active : classes.inActive,
        ].join(' ')}
        size="small"
      >
        {
          cmdData.icon ? cmdData.icon
            : (
              <span
            // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: cmdData.html || cmdData.cmd }}
              />
            )
        }
      </Button>
    </Tooltip>
  );
};

export default EditButton;
