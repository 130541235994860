import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import Actions from '../../actions';

import { TOKEN } from '../../utils/constants';

import AppName from '../App/AppName';
import UserInsertForm from '../App/UserInsertForm';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: 350,
      display: 'flex',
      justifyContent: 'center',
      boxShadow: theme.boxShadow,
      padding: 30,
    },
    cardContent: {
      textAlign: 'center',
      minWidth: '100%',
    },
    formControl: {
      marginBottom: 15,
    },
    submit: {
      marginTop: 30,
      marginBottom: 45,
    },
    button: {
      background: theme.primaryColor,
      color: '#fff',
      '&:hover': {
        background: theme.primaryColor,
        color: '#fff',
      },
    },
  };
});

function Signup(props) {
  const { auth, location } = props;
  const urlParams = new URLSearchParams(location.search);
  const paramRole = urlParams.get('role');
  const roleString = paramRole ? `?role=${paramRole}` : '';
  const classes = useStyles();

  if (localStorage.getItem(TOKEN) || (auth && auth.isAuthenticated)) {
    return (<Redirect to="/home" />);
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <div>
            <AppName justifyContent="center" />
          </div>
          <UserInsertForm paramRole={paramRole} />
          <Grid container justifyContent="center" alignItems="center" className={classes.formControl}>
            <Typography>Already have an account?&nbsp;</Typography>
            <Typography><Link href={`/dashboard/login${roleString}`}>Login</Link></Typography>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="caption">
              <i>By continuing you will receive a one-time verification code to your phone number by SMS.</i>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
