import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ContentEditor from '../Editor/ContentEditor';
import AppButton from '../App/AppButton';

// eslint-disable-next-line import/no-cycle
import ShowFunctions from './Display/ShowFunction';

import { getCombineValue } from '../../utils/util';

const NAMES = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
};

const useStyles = makeStyles(() => {
  return {
    table: {
      margin: 0,
      padding: 0,
    },
    cell: {
      border: 'none',
      padding: 0,
      margin: 0,
      verticalAlign: 'bottom',
    },
    tends: {
      fontSize: 20,
      height: 50,
    },
    text: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
});

const ChemicalEquation = (props) => {
  const {
    handleChange, name, closeModal,
  } = props;
  const classes = useStyles();
  const [data, setData] = React.useState({
    [NAMES.UP]: '',
    [NAMES.DOWN]: '',
    [NAMES.LEFT]: '',
    [NAMES.RIGHT]: '',
  });

  const [focusedElement, setFocusedElement] = React.useState(NAMES.DATA);
  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const handleContentChange = (event) => {
    const dataName = event.target.name;
    const value = event.target.value;
    setData((preData) => {
      const newData = { ...preData };
      newData[dataName] = value;
      return newData;
    });
  };

  const handleSubmit = () => {
    const value = `<table cellspacing="0" cellpadding="0"
    style="display:inline-table; border-collapse:collapse; vertical-align:middle;">
    <tbody>
    <tr >
      <td rowspan="2" style="padding-right: 5px; padding-bottom: 3px;">${data[NAMES.LEFT]}</td>
      <td style="padding: 5px 5px 2px 10px; text-align: right;
        border-bottom: 2px solid; line-height: 0.5; vertical-align: bottom;">
        <div style="text-align: center;">&nbsp;${data[NAMES.UP]}&nbsp;&nbsp;</div>
        <div style="line-height: 0;">
          <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom: -8px; margin-right: -12px;"
            width="11" height="11" viewBox="0 0 24 24">
            <path d="M22 12l-20 12 5-12-5-12z"/>
          </svg>
        </div>
      </td>
      <td rowspan="2" style="padding-left: 6px; padding-bottom: 3px;">&nbsp;${data[NAMES.RIGHT]}&nbsp;</td>
    </tr>
    <tr>
      <td style="padding: 5px 5px 5px 10px; text-align: center; line-height: 0.8;">
        <span style="text-align: center; vertical-align: bottom;">&nbsp;${data[NAMES.DOWN]}&nbsp;&nbsp;</span>
      </td>
    </tr>
    </tbody>
    </table>&nbsp;`;
    closeModal();
    handleChange({ target: { name, value } });
  };

  const setFocus = (elementName) => {
    setFocusedElement(elementName);
  };

  const getData = (event) => {
    const elName = event.target.name;
    const value = event.target.value;
    setData((prevData) => {
      const newElement = getCombineValue(prevData[elName], value, position);
      return { ...prevData, [elName]: newElement };
    });
  };

  return (
    <>
      <ShowFunctions handleChange={getData} name={focusedElement} />
      <Grid>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell rowSpan="2" className={classes.cell} style={{ paddingRight: 5, paddingBottom: 56 }}>
                <Grid container>
                  <Grid item xs>
                    <ContentEditor
                      name={NAMES.LEFT}
                      html={data[NAMES.LEFT]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.cell} style={{ textAlign: 'right' }}>
                <div style={{ padding: '5px', borderBottom: '2px solid' }}>
                  <div style={{ textAlign: 'center' }}>
                    <ContentEditor
                      name={NAMES.UP}
                      html={data[NAMES.UP]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                      showTopCommands
                    />
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginBottom: -16, marginRight: -12 }}
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12l-20 12 5-12-5-12z" />
                  </svg>
                </div>
              </TableCell>
              <TableCell rowSpan="2" className={classes.cell} style={{ paddingLeft: 10, paddingBottom: 56 }}>
                <Grid container>
                  <Grid item xs>
                    <ContentEditor
                      name={NAMES.RIGHT}
                      html={data[NAMES.RIGHT]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell} style={{ textAlign: 'center', padding: '5px', paddingTop: 24 }}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs>
                    <ContentEditor
                      name={NAMES.DOWN}
                      html={data[NAMES.DOWN]}
                      handleChange={handleContentChange}
                      setParentFocus={setFocus}
                      setPosition={setPosition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid container justifyContent="center">
        <AppButton variant="contained" color="primary" onClick={handleSubmit} />
      </Grid>
    </>
  );
};

export default ChemicalEquation;
