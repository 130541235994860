import React from 'react';
import AppBar from '../components/AppBar';

const AllQuestions = () => {
  return (
    <>
      <AppBar>
        {/* <MyQuestions /> */}
      </AppBar>
    </>
  );
};

export default AllQuestions;
