import React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Divider,
} from '@material-ui/core';

import AppButton from '../App/AppButton';

function DialogBox(props) {
  const {
    onClose, open, title, confirm, data, children,
  } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions style={{ margin: '10px 20px' }}>
        <div style={{ marginRight: 15 }}>
          <AppButton onClick={onClose} title="No" />
        </div>
        <AppButton onClick={() => { return confirm(data); }} title="Yes" />
      </DialogActions>
    </Dialog>
  );
}

export default DialogBox;
