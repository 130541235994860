import React from 'react';

import Container from '@material-ui/core/Container';

import AppBar from '../../components/AppBar';
import ScheduleMeeting from '../../components/Display/ScheduleMeeting';

const ScheduleSchoolMeeting = () => {
  return (
    <>
      <AppBar>
        <Container maxWidth={false}>
          <ScheduleMeeting />
        </Container>
      </AppBar>
    </>
  );
};

export default (ScheduleSchoolMeeting);
