/* eslint-disable import/order */
/* eslint-disable comma-dangle */
/* eslint-disable max-len */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import ScheduleForm from "../Meeting/ScheduleForm";
import TitleBar from "../TitleBar";
import Loader from "../Progress/Loader";
import Snack from "../Progress/Snack";
import CircularLoader from "../Progress/CircularLoader";
import EmptyCard from "./EmptyCard";
import Meeting from "./Meeting";

import Actions from "../../actions";
import { fetchStandards } from "../../axios/axios";
import axios from "axios";

const useStyles = makeStyles(() => {
  return {
    container: {},
  };
});

const ScheduleMeeting = (props) => {
  const {
    insertOrUpdateMeeting,
    fetchMeetings,
    fetchOtherMeetings,
    meetings,
    fetchingMeetings,
    hideTitleBar,
    otherMeetings,
    fetchingOtherMeetings,
    batch,
    hideOtherMeetings,
    title,
    deleteMeeting,
    setSnack,
  } = props;
  const classes = useStyles();
  const [snackData, setSnackData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [meetingData, setMeetingData] = React.useState({});
  const [standards, setStandards] = React.useState("");

  const handleCloseSnack = () => {
    setSnackData({});
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const setEditMeetingData = (meeting) => {
    setMeetingData(meeting);
  };

  React.useEffect(() => {
    fetchMeetings({ batch });
    if (!hideOtherMeetings) fetchOtherMeetings();

    const getStandards = async () => {
      const data = await fetchStandards();
      setStandards(data);
      console.log(data);
    };

    getStandards();
  }, [refresh, hideOtherMeetings]);

  return (
    <>
      <Loader loading={loading} />
      <Snack
        open={snackData.open}
        severity={snackData.severity}
        message={snackData.message}
        handleClose={handleCloseSnack}
      />
      <>
        <div className={classes.container}>
          {hideTitleBar ? null : <TitleBar title="Schedule Class" />}
          <ScheduleForm
            loading={loading}
            setSnack={setSnackData}
            setLoading={setLoading}
            insertOrUpdateMeeting={insertOrUpdateMeeting}
            refreshPage={refreshPage}
            meetingData={meetingData}
            batch={batch}
            title={title}
            standards={standards}
          />
          <TitleBar
            size={hideTitleBar ? "body1" : "h6"}
            title={title ? `Scheduled ${title}` : "Scheduled Classes"}
          />
          {fetchingMeetings ? (
            <CircularLoader loading={fetchingMeetings} />
          ) : (
            <>
              {!meetings || meetings.length === 0 ? (
                <EmptyCard text="No class scheduled yet. Please schedule a class." />
              ) : null}
              {meetings &&
                meetings.map((meeting) => {
                  return (
                    <Meeting
                      key={meeting._id}
                      meetingData={meeting}
                      editMeeting={setEditMeetingData}
                      deleteMeeting={deleteMeeting}
                      setSnack={setSnack}
                      refreshPage={refreshPage}
                    />
                  );
                })}
            </>
          )}
          {hideOtherMeetings ? null : (
            <div>
              <TitleBar title="Other Classes" />
              {fetchingOtherMeetings ? (
                <CircularLoader loading={fetchingOtherMeetings} />
              ) : (
                <>
                  {!otherMeetings || otherMeetings.length === 0 ? (
                    <EmptyCard text="No other class scheduled yet." />
                  ) : null}
                  {otherMeetings &&
                    otherMeetings.map((meeting) => {
                      return (
                        <Meeting
                          noEdit
                          key={meeting._id}
                          meetingData={meeting}
                          editMeeting={setEditMeetingData}
                          deleteMeeting={deleteMeeting}
                          setSnack={setSnack}
                          refreshPage={refreshPage}
                        />
                      );
                    })}
                </>
              )}
            </div>
          )}
        </div>
      </>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    fetchingMeetings: state.meeting.fetchingMeetings,
    fetchingOtherMeetings: state.meeting.fetchingOtherMeetings,
    meetings: state.meeting.meetings,
    otherMeetings: state.meeting.otherMeetings,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMeeting);
