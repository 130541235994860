import React from 'react';

import AppBar from '../components/AppBar';
import ProfileComponent from '../components/Display/Profile';

const Profile = () => {
  return (
    <>
      <AppBar>
        <ProfileComponent />
      </AppBar>
    </>
  );
};

export default Profile;
