import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => {
  return {
    container: {
      minHeight: 200,
      width: '100%',
    },
  };
});

function Loader(props) {
  const {
    loading,
  } = props;
  const classes = useStyles();
  return (
    <>
      {
         loading ? (
           <Grid container className={classes.container} justifyContent="center" alignItems="center">
             <CircularProgress color="inherit" />
           </Grid>
         ) : null
      }
    </>
  );
}

export default Loader;
