import React from 'react';
import ContentEditable from 'react-contenteditable';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Commands from './Commands';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    editor: {
      border: '1px solid #e4e4e4',
      padding: '15px 8px',
      background: '#fff',
      color: '#000',
      fontSize: '16px !important',
      fontFamily: '"Times New Roman", Courier, Garamond, serif',
    },
    editorContainer: {
      boxShadow: theme.boxShadow,
      width: '100%',
    },
  };
});

function ContentEditor(props) {
  const {
    name, handleChange, hideCommands, setParentFocus, minHeight, width, showTopCommands, setPosition, isAutoFocus,
    isDisabled,
  } = props;
  const propsHTML = props.html;
  const placeholder = props.placeholder || `Add ${name}`;
  const classes = useStyles();
  const html = React.useRef('');
  // const ref = React.createRef();
  const [isFocused, setIsFocused] = React.useState(false);
  const [render, setRender] = React.useState(false);

  let saveSelection;
  let restoreSelection;

  if (window.getSelection && document.createRange) {
    saveSelection = function (containerEl) {
      const range = window.getSelection().getRangeAt(0);
      const preSelectionRange = range.cloneRange();
      preSelectionRange.selectNodeContents(containerEl);
      preSelectionRange.setEnd(range.startContainer, range.startOffset);
      const start = preSelectionRange.toString().length;

      return {
        start,
        end: start + range.toString().length,
      };
    };

    restoreSelection = function (containerEl, savedSel) {
      let charIndex = 0; const
        range = document.createRange();
      range.setStart(containerEl, 0);
      range.collapse(true);
      const nodeStack = [containerEl]; let node; let foundStart = false; let
        stop = false;

      // eslint-disable-next-line no-cond-assign
      while (!stop && (node = nodeStack.pop())) {
        if (node.nodeType === 3) {
          const nextCharIndex = charIndex + node.length;
          if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
            range.setStart(node, savedSel.start - charIndex);
            foundStart = true;
          }
          if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
            range.setEnd(node, savedSel.end - charIndex);
            stop = true;
          }
          charIndex = nextCharIndex;
        } else {
          let i = node.childNodes.length;
          // eslint-disable-next-line no-plusplus
          while (i--) {
            nodeStack.push(node.childNodes[i]);
          }
        }
      }

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    };
  } else if (document.selection && document.body.createTextRange) {
    saveSelection = function (containerEl) {
      const selectedTextRange = document.selection.createRange();
      const preSelectionTextRange = document.body.createTextRange();
      preSelectionTextRange.moveToElementText(containerEl);
      preSelectionTextRange.setEndPoint('EndToStart', selectedTextRange);
      const start = preSelectionTextRange.text.length;

      return {
        start,
        end: start + selectedTextRange.text.length,
      };
    };

    restoreSelection = function (containerEl, savedSel) {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(containerEl);
      textRange.collapse(true);
      textRange.moveEnd('character', savedSel.end);
      textRange.moveStart('character', savedSel.start);
      textRange.select();
    };
  }

  let savedSelection;

  function doSave() {
    savedSelection = saveSelection(document.getElementById(name));
    // console.log('####', savedSelection);
  }

  function doRestore() {
    if (savedSelection) {
      restoreSelection(document.getElementById(name), savedSelection);
    }
  }

  const setPlaceHolder = (data) => {
    html.current = data;
    setRender((prevReander) => { return !prevReander; });
  };

  const handleContentChange = (evt) => {
    html.current = evt.target.value;
    doSave();
    handleChange({ target: { name, value: html.current } });
    setPosition(savedSelection);
  };

  const handleFocus = () => {
    if (setParentFocus) setParentFocus(name);
    setIsFocused(true);
    if (html.current.includes(placeholder)) setPlaceHolder('');
    doRestore();
  };

  const handleBlur = () => {
    if (setParentFocus) setParentFocus(name);
    setIsFocused(false);
    if (!html.current) setPlaceHolder(placeholder);
  };

  const handleKeyUp = () => {
    doSave();
    setPosition(savedSelection);
  };

  const handleClick = () => {
    doSave();
    setPosition(savedSelection);
  };

  React.useEffect(() => {
    if (propsHTML && (propsHTML !== html.current)) {
      setPlaceHolder(propsHTML);
    }
    // eslint-disable-next-line
  }, [propsHTML]);

  React.useEffect(() => {
    if (name && !html.current) setPlaceHolder(placeholder);
    if (isAutoFocus) document.getElementById(name).focus();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {
        showTopCommands
          ? (
            <Grid className={classes.container}>
              <Commands name={name} isFocused={isFocused} render={render} />
            </Grid>
          ) : null

      }
      <Grid className={classes.editorContainer}>
        <ContentEditable
          id={name}
          name={name}
          html={html.current}
          // innerRef={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleContentChange}
          className={classes.editor}
          onKeyUp={handleKeyUp}
          onClick={handleClick}
          tagName="div"
          style={{ minHeight: minHeight || 50, width: width || '100%' }}
          disabled={isDisabled || false}
        />
      </Grid>
      {
        (hideCommands || showTopCommands) ? null
          : (
            <Grid className={classes.container}>
              <Commands name={name} isFocused={isFocused} render={render} />
            </Grid>
          )

      }
      {/* <Grid className={classes.container}>
        <textarea disabled value={html.current} />
      </Grid> */}
    </>
  );
}

export default ContentEditor;
