import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';

import PdfContainer from '../PDF/PdfContainer';
import PublishButton from '../Display/PublishButton';

import Actions from '../../actions';

const useStyles = makeStyles((theme) => {
  return {
    deviceItem: {
      width: '33.33%',
      '@media (max-width:460px)': {
        width: '100%',
      },
    },
    card: {
      minHeight: 100,
      textAlign: 'left',
      width: '100%',
      height: '100%',
      boxShadow: theme.boxShadow,
      borderRadius: 3,
      color: '#555',
      padding: 15,
    },
    published: {
      color: theme.successColor,
    },
    unPublished: {
      color: theme.warningColor,
    },
    subtitle: {
      fontWeight: 'bold',
      maxWidth: '300px',
    },
    text: { textTransform: 'capitalize' },
    title: {
      marginLeft: 10,
    },
  };
});

const ExamPaper = (props) => {
  const {
    paper, handleDelete, refreshPage, noDelete, title, resetFetchedPaper,
  } = props;
  const classes = useStyles();
  const [paperId, setPaperId] = React.useState(undefined);
  const status = paper.isActive ? 'Published' : 'Unpublished';
  const lastPublishedDate = (paper.publishedDate && new Date(paper.publishedDate).toLocaleTimeString('en-US',
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }))
  || 'None';

  const handleEdit = (id) => {
    resetFetchedPaper();
    setPaperId(id);
  };

  if (paperId !== undefined) {
    return (
      <Redirect push to={{ pathname: '/add-paper', state: { paperId, noEdit: true } }} />
    );
  }
  return (
    <>
      <Grid key={paper._id} item className={classes.deviceItem}>
        <Paper className={classes.card}>
          <Grid container>
            <Grid item container justifyContent="space-between">
              <Grid item xs container alignItems="center">
                <Tooltip title={status} arrow>
                  <IconButton size="small">
                    {
                        paper.isActive
                          ? <DoneIcon className={classes.published} />
                          : <WarningIcon className={classes.unPublished} />
                    }
                  </IconButton>
                </Tooltip>
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => { handleEdit(paper._id); }}>
                  <Edit fontSize="small" />
                </IconButton>
                {
                  noDelete ? null
                    : (
                      <IconButton size="small" onClick={() => { handleDelete(paper._id); }}>
                        <Delete fontSize="small" />
                      </IconButton>
                    )
                }
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={paper.name} arrow>
                <Typography variant="subtitle1" className={classes.subtitle} noWrap>
                  {paper.name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid>
            <Grid container>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={paper.standardName} placement="left-start" arrow>
                  <Typography variant="caption" display="block" noWrap>
                    <b>Standard</b>
                    {': '}
                    {paper.standardName}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={paper.subjectName} placement="left-start" arrow>
                  <Typography variant="caption" display="block" noWrap>
                    <b>Subject</b>
                    {': '}
                    {paper.subjectName}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={paper.type || 'None'} placement="left-start" arrow>
                  <Typography className={classes.text} variant="caption" display="block" noWrap>
                    <b>Paper Type</b>
                    {': '}
                    {paper.type || 'None'}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={status} placement="left-start" arrow>
                  <Typography variant="caption" display="block" noWrap>
                    <b>Status</b>
                    {': '}
                    {status}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={lastPublishedDate} placement="left-start" arrow>
                  <Typography variant="caption" display="block" noWrap>
                    <b>Last Published Date</b>
                    {': '}
                    {lastPublishedDate}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={paper.createdBy && paper.createdBy.name} placement="left-start" arrow>
                  <Typography className={classes.text} variant="caption" display="block" noWrap>
                    <b>Created by</b>
                    {': '}
                    {paper.createdBy && paper.createdBy.name}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={paper.numberOfQuestion || 'No question yet'} placement="left-start" arrow>
                  <Typography className={classes.text} variant="caption" display="block" noWrap>
                    <b>Number of questions</b>
                    {': '}
                    {paper.numberOfQuestion || 0}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={paper.year} placement="left-start" arrow>
                  <Typography className={classes.text} variant="caption" display="block" noWrap>
                    <b>Year</b>
                    {': '}
                    {paper.year}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <Tooltip title={paper.appLink} placement="left-start" arrow>
                  <Typography variant="caption" display="block" noWrap>
                    <b>Exam Link</b>
                    {': '}
                    <Link href={paper.appLink} target={window.innerWidth > 600 ? '_blank' : ''}>
                      {paper.appLink}
                    </Link>
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 15 }} justifyContent="space-between">
            <Grid item>
              <PublishButton refreshPage={refreshPage} paper={paper} />
            </Grid>
            <Grid item>
              <PdfContainer
                text="Print"
                paperId={paper._id}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    papers: state.paper.papers,
    loading: state.paper.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamPaper));
