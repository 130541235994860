import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import Actions from '../../../actions';

import Select from '../../Select';
import AutoCompleteSelect from '../../App/AutoCompleteSelect';
import InputField from '../../InputField';
import Modal from '../../Modal/Modal';
import MarkingSchema from '../../App/MarkingSchema';
import IconButton from '../../App/IconButton';

import Snack from '../../Progress/Snack';
import Loader from '../../Progress/Loader';

import {
  paperValidationSchema, getYears, getDays, FULL_TEST,
} from '../../../utils/util';
import { MARKS_MAPPINGS, STANDARDS, PAPER_TYPES } from '../../../utils/constants';
import AppButton from '../../App/AppButton';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: 32,
      width: 460,
      padding: 25,
      paddingTop: 10,
      [theme.breakpoints.down('sm')]: {
        width: 360,
      },
      boxShadow: theme.boxShadow,
    },
    submit: {
      marginTop: 30,
      marginBottom: 30,
    },
    heading: {
      fontWeight: 600,
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 15,
    },
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    button: {
      background: theme.primaryColor,
      color: '#fff',
      fontWeight: 'bold',
      marginTop: 8,
    },
    markingButton: {
      width: '100%',
      textTransform: 'none',
      marginBottom: 8,
      marginTop: 8,
      fontWeight: 'bold',
      padding: 0,
      paddingLeft: 16,
      paddingRight: 16,
      background: '#efefef',
      color: '#555',
      boxShadow: 'none',
      '&:hover': {
        background: theme.secondaryColor,
        color: '#fff',
      },
    },
  };
});

function Step1(props) {
  const {
    fetchStandards, standards, handleNext, insertOrUpdatePaper, setSnackData,
    fetchPaper, location, paper, history, loadingStandards,
  } = props;
  const classes = useStyles();
  const paperObjId = paper && paper._id;
  const state = location.state;
  const paperId = state && state.paperId;

  const initializeData = (obj) => {
    const dataObj = obj || {};
    const standardObj = standards && standards.find((o) => { return o._id === dataObj.standard; });
    const subjects = (standardObj && standardObj.subjects);
    return {
      _id: dataObj._id,
      standard: dataObj.standard || '',
      subject: dataObj.subject || '',
      subjectName: dataObj.subjectName || '',
      standardName: dataObj.standardName || '',
      year: dataObj.year || '',
      marksMapping: dataObj.marksMapping || '',
      name: dataObj.name || '',
      numberOfQuestion: dataObj.numberOfQuestion || '',
      maxMarks: dataObj.maxMarks || 0,
      duration: dataObj.duration || '',
      instruction: dataObj.instruction || '',
      type: dataObj.type || PAPER_TYPES.MOCK,
      subjects: subjects || [],
      day: dataObj.day || '',
    };
  };
  const [snack, setSnack] = React.useState({});
  const [data, setData] = React.useState(initializeData());
  const [loading, setLoading] = React.useState(false);
  const [openMarkingSchema, setOpenMarkingSchema] = React.useState(false);
  const [noEdit, setNoEdit] = React.useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleStandardChange = (e, newObj) => {
    const standardName = (newObj && newObj.name) || '';
    setData({
      ...data,
      standard: (newObj && newObj._id) || '',
      standardName,
      marksMapping: MARKS_MAPPINGS[standardName] || MARKS_MAPPINGS[STANDARDS.OTHER],
      subjects: (newObj && newObj.subjects) || [],
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    paperValidationSchema.validate(data)
      .then((validatedData) => { return insertOrUpdatePaper(validatedData); })
      .then(() => {
        setSnackData({
          open: true,
          severity: 'success',
          message: data._id ? 'Paper Updated Successfully!' : 'Paper Created Successfully!',
        });
        setLoading(false);
        handleNext();
      })
      .catch((err) => {
        setLoading(false);
        setSnack({ open: true, severity: 'error', message: err.message || 'Something went wrong' });
      });
  };

  const closeSnack = () => {
    setSnack({});
  };

  const closeMarkingSchema = () => {
    setOpenMarkingSchema(false);
  };

  const handleMarksChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prevData) => {
      const marksMapping = prevData.marksMapping;
      const keys = name.split('_');
      marksMapping[keys[0]][keys[1]] = (value === undefined || value === '') ? '' : parseFloat(value);
      return { ...prevData, marksMapping };
    });
  };

  React.useEffect(() => {
    if (!standards && !loadingStandards) fetchStandards();
  }, [standards]);

  React.useEffect(() => {
    if (paperId) {
      fetchPaper({ _id: paperId })
        .then(() => { history.replace({ ...location, state: { ...state, paperId: undefined } }); });
    }
  }, [paperId]);

  React.useEffect(() => {
    if (paperObjId !== undefined && standards) {
      setData(initializeData(paper));
      setNoEdit((state && state.noEdit) || false);
    }
  }, [paperObjId, paper, standards]);

  React.useEffect(() => {
    if (noEdit && data._id) {
      handleSubmit();
      history.replace({ ...location, state: { ...state, noEdit: false } });
      setNoEdit(false);
    }
  }, [noEdit, data]);

  React.useEffect(() => {
    if (data.year && data.subjectName && data.standardName && !data.name && !data.duration) {
      setData({ ...data, name: `${data.standardName} ${data.subjectName} ${data.year}`, duration: 60 });
    }
  }, [data]);

  return (
    <>
      <Snack
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={closeSnack}
      />
      <Loader
        loading={loading}
      />
      <Modal open={openMarkingSchema} handleClose={closeMarkingSchema} title="Marking Schema">
        <MarkingSchema
          marksMapping={data.marksMapping}
          handleChange={handleMarksChange}
          handleClose={closeMarkingSchema}
        />
      </Modal>
      <div className={classes.container}>
        <Grid container justifyContent="center" alignItems="center">
          <Paper elevation={0} className={classes.paper}>
            <Typography
              variant="h6"
              className={classes.heading}
            >
              Enter Paper Details
            </Typography>
            <Grid>
              <AutoCompleteSelect
                title="Standard"
                data={standards}
                name="standard"
                value={data.standard}
                handleChange={handleStandardChange}
              />
            </Grid>
            <Grid>
              <Select
                title="Subject"
                data={[FULL_TEST, ...data.subjects]}
                name="subjectName"
                value={data.subjectName}
                handleChange={handleChange}
                isArray
              />
            </Grid>
            <Grid>
              <Select
                title="Year"
                data={getYears(15)}
                name="year"
                value={data.year}
                handleChange={handleChange}
                isArray
              />
            </Grid>
            {
              data.standard && data.subjectName && data.year
                ? (
                  <>
                    <Grid>
                      <InputField
                        title="Enter Paper Name"
                        name="name"
                        value={data.name}
                        handleChange={handleChange}
                      />
                    </Grid>
                    <Grid>
                      <InputField
                        title="Enter Duration (minutes)"
                        name="duration"
                        value={data.duration}
                        type="number"
                        handleChange={handleChange}
                      />
                    </Grid>
                    <Grid>
                      <Select
                        title="Paper Type"
                        data={Object.values(PAPER_TYPES)}
                        name="type"
                        value={data.type}
                        handleChange={handleChange}
                        isArray
                        isCapitalize
                      />
                    </Grid>
                    <Grid>
                      <Select
                        title="Practice Day (optional)"
                        data={getDays()}
                        name="day"
                        value={data.day}
                        handleChange={handleChange}
                        prefix="Day"
                        isArray
                      />
                    </Grid>
                    <Grid container justifyContent="flex-end">
                      <Button
                        variant="contained"
                        onClick={() => { setOpenMarkingSchema(true); }}
                        className={classes.markingButton}
                        disabled={!data.marksMapping}
                      >
                        View or Modify Marking Pattern
                      </Button>
                    </Grid>
                  </>
                ) : null
            }
            <Grid container justifyContent="center">
              <AppButton
                onClick={handleSubmit}
              >
                Next
              </AppButton>
            </Grid>
          </Paper>
        </Grid>
        <br />
        <br />
        <Grid container justifyContent="flex-end">
          <IconButton href="/dashboard/all-papers" title="My All Test Papers" icon={<FolderOpenIcon />} />
        </Grid>
        <br />
      </div>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    standards: state.standards.data,
    paper: state.paper.data,
    loadingStandards: state.standards.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1));
