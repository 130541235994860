import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ContentEditor from '../Editor/ContentEditor';
import AppButton from '../App/AppButton';

// eslint-disable-next-line import/no-cycle
import ShowFunctions from './Display/ShowFunction';

import { getCombineValue } from '../../utils/util';

const NAMES = ['numerator', 'denominator'];

const useStyles = makeStyles(() => {
  return {
    divider: {
      background: '#555',
      height: 2,
      marginBottom: 40,
    },
  };
});

const Fraction = (props) => {
  const {
    numerator, denominator, handleChange, name, closeModal,
  } = props;
  const classes = useStyles();
  const [data, setData] = React.useState({
    [NAMES[0]]: numerator || '',
    [NAMES[1]]: denominator || '',
  });

  const [focusedElement, setFocusedElement] = React.useState(NAMES[0]);
  const [position] = React.useState({ start: 0, end: 0 });

  const setPosition = (newPosition) => {
    position.start = newPosition.start;
    position.end = newPosition.end;
  };

  const handleContentChange = (event) => {
    const dataName = event.target.name;
    const value = event.target.value;
    setData((preData) => {
      const newData = { ...preData };
      newData[dataName] = value;
      return newData;
    });
  };

  const handleSubmit = () => {
    const value = `<table style="display:inline-table; border-collapse:collapse; vertical-align:middle">
    <tr>
      <td style="border-bottom: solid 1px; text-align:center; line-height: 1.3;">
        ${data[NAMES[0]]}
      </td>
    </tr>
    <tr>
      <td style="text-align:center; line-height: 1.3;">
        ${data[NAMES[1]]}
      </td>
    </tr>
    </table>&nbsp;`;
    closeModal();
    handleChange({ target: { name, value } });
  };

  const setFocus = (elementName) => {
    setFocusedElement(elementName);
  };

  const getData = (event) => {
    const elName = event.target.name;
    const value = event.target.value;
    setData((prevData) => {
      const newElement = getCombineValue(prevData[elName], value, position);
      return { ...prevData, [elName]: newElement };
    });
  };

  return (
    <>
      <ShowFunctions handleChange={getData} name={focusedElement} />
      <ContentEditor
        name={NAMES[0]}
        html={data[NAMES[0]]}
        handleChange={handleContentChange}
        setParentFocus={setFocus}
        setPosition={setPosition}
      />
      <Divider className={classes.divider} />
      <ContentEditor
        name={NAMES[1]}
        html={data[NAMES[1]]}
        handleChange={handleContentChange}
        setParentFocus={setFocus}
        setPosition={setPosition}
      />
      <Grid container justifyContent="center">
        <AppButton onClick={handleSubmit} />
      </Grid>
    </>
  );
};

export default Fraction;
