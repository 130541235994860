import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import TitleBar from '../TitleBar';
import DailyActivity from '../App/DailyActivity';
import Note from '../Note/Note';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: 30,
      marginBottom: 30,
    },
    profileContainer: {
      boxShadow: theme.boxShadow,
      background: '#fff',
      padding: 15,
    },
    header: {
      marginBottom: 15,
    },
    avatar: {
      marginRight: 16,
      width: 60,
      height: 60,
      [theme.breakpoints.down('sm')]: {
        marginRight: 8,
        width: 40,
        height: 40,
      },
    },
    name: {
      fontSize: 28,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    noteContainer: {
      paddingLeft: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    empty: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 0px 10px 17px',
      color: '#555',
    },
    text: {
      textAlign: 'center',
    },
  };
});

function TeacherProfile(props) {
  const {
    teacher,
  } = props;
  const classes = useStyles();

  if (!teacher) {
    return (<Redirect to="/home" />);
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item md={8} sm={8} xs={12}>
          <Grid item xs={12} className={classes.profileContainer}>
            <Grid container alignItems="center" className={classes.header}>
              <Avatar className={classes.avatar} />
              <Typography className={classes.name}>{teacher.name}</Typography>
            </Grid>
            <Grid>
              <Typography variant="caption" display="block" noWrap>{teacher.phone}</Typography>
              <Typography variant="caption" display="block" noWrap>{teacher.email}</Typography>
              <Typography variant="caption" display="block" noWrap>{teacher.role}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TitleBar title="Teacher Daily Activity" />
            <DailyActivity userId={teacher._id} placeholder="No Activity" isDisabled />
          </Grid>
        </Grid>
        <Grid item md={4} sm={4} xs={12} className={classes.noteContainer}>
          <Note user={teacher._id} title="Add Teacher Notes" />
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(TeacherProfile);
