import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import Actions from '../../actions';

import Loader from '../Progress/Loader';
import DialogBox from '../Dialog/DialogBox';
import Select from '../Select';
import AppButton from '../App/AppButton';

const useStyles = makeStyles((theme) => {
  return {
    notPublished: {
      background: theme.secondaryColor,
      paddingLeft: 15,
      paddingRight: 15,
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.successColor,
      },
    },
    published: {
      background: theme.successColor,
      paddingLeft: 15,
      paddingRight: 15,
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.successColor,
      },
    },
    margin: {
      marginTop: 30,
      zIndex: -1,
    },
    textButton: {
      fontSize: 15,
    },
    calButton2: {
      color: '#fff',
      background: theme.secondaryColor,
      boxShadow: theme.boxShadow,
      '&:hover': {
        background: theme.secondaryColor,
      },
    },
  };
});

function PublishButton(props) {
  const {
    paper, publishPaper, loading, paperData, refreshPage,
  } = props;
  const classes = useStyles();

  const getInitialState = (obj = {}) => {
    const isActive = (obj.isActive === undefined || obj.isActive === null) ? false : obj.isActive;
    return {
      active: isActive,
      isActive: true,
      publishedDate: (obj.publishedDate && new Date(obj.publishedDate)) || new Date(),
      isOneTime: obj.isOneTime || false,
      _id: obj._id,
    };
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const [data, setData] = React.useState(getInitialState(paper));
  const title = data.isActive ? 'Are you sure want to publish paper at scheduled date time?'
    : 'Are you sure want to unpublish paper?';
  const buttonTitle = 'Publish paper at scheduled date time';

  const handleClick = () => {
    setOpenDialog(true);
  };

  const confirm = () => {
    if (data && data._id) {
      publishPaper(data)
        .then(() => { if (refreshPage) refreshPage(); });
    }
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  React.useEffect(() => {
    if (paperData && paper && paper._id === paperData._id) {
      setData(getInitialState(paperData));
    }
  }, [paperData]);

  const CustomInputDate = React.forwardRef(
    ({ value, onClick }, ref) => {
      return (
        <Button
          variant="contained"
          onClick={onClick}
          endIcon={<CalendarTodayIcon />}
          ref={ref}
          className={classes.calButton2}
        >
          <Typography className={classes.textButton}><b>{value}</b></Typography>
        </Button>
      );
    },
  );

  return (
    <>
      <DialogBox onClose={() => { setOpenDialog(false); }} open={openDialog} title={title} confirm={confirm}>
        <Paper style={{ minHeight: 320 }} elevation={0}>
          <Grid container alignItems="center" justifyContent="center">
            <Typography align="center" variant="subtitle2"><b>Set Date Time: &nbsp;</b></Typography>
            <Grid>
              <DatePicker
                selected={data.publishedDate}
                onChange={(date) => { return setData({ ...data, publishedDate: date }); }}
                timeInputLabel="Time:"
                dateFormat="EEE, MMM d, yyyy h:mm aa"
                customInput={<CustomInputDate />}
                showTimeInput
              />
            </Grid>
          </Grid>
          <div className={classes.margin}>
            <Select
              headTitle="Is Published?"
              data={[{ _id: true, name: 'Yes' }, { _id: false, name: 'No' }]}
              name="isActive"
              value={data.isActive}
              handleChange={handleChange}
              noDefault
            />
          </div>
          <div className={classes.margin}>
            <Select
              headTitle="Select Paper Occurrence"
              data={[{ _id: false, name: 'Permanent' }, { _id: true, name: 'One Time' }]}
              name="isOneTime"
              value={data.isOneTime}
              handleChange={handleChange}
              noDefault
            />
          </div>
        </Paper>
      </DialogBox>
      <Loader loading={loading || false} />
      <AppButton
        data-tour="publish"
        className={data.active ? classes.published : classes.notPublished}
        startIcon={data.active ? <CheckCircleOutlineIcon /> : null}
        endIcon={
          !data.active ? (
            <Tooltip title={buttonTitle} arrow>
              <InfoIcon />
            </Tooltip>
          ) : null
        }
        onClick={handleClick}
      >
        {data.active ? 'Published' : 'Publish'}
      </AppButton>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    loading: state.paper.fetching,
    paperData: state.paper.paperData,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublishButton));
