import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => {
  return {
    typography: {
      fontSize: 16,
      fontFamily: '"Times New Roman", Courier, Garamond, serif',
    },
  };
});

const Html = ({ html }) => {
  const classes = useStyles();

  return (
    <Typography
      dangerouslySetInnerHTML={{ __html: html }}
      className={classes.typography}
    />
  );
};

export default Html;
