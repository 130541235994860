import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import PostAddIcon from '@material-ui/icons/PostAdd';

import AppBar from '../components/AppBar';
import MyPaper from '../components/Display/MyPaper';
import TitleBar from '../components/TitleBar';
import AppButton from '../components/App/AppButton';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    buttonContainer: {
      paddingTop: 30,
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      background: theme.secondaryColor,
      color: '#fff',
      '&:hover': {
        background: theme.darkSecondaryColor,
      },
      paddingLeft: 20,
      paddingRight: 20,
      fontWeight: 'bold',
      boxShadow: theme.boxShadow,
    },
    paperContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  };
});

const AllPapers = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar>
        <div className={classes.container}>
          <div className={classes.buttonContainer}>
            <AppButton href="/dashboard/add-paper" className={classes.button} startIcon={<PostAddIcon />}>
              Create New Paper
            </AppButton>
          </div>
          <TitleBar title="My papers" />
          <div className={classes.paperContainer}>
            <MyPaper />
          </div>
        </div>
      </AppBar>
    </>
  );
};

export default (AllPapers);
