import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import Actions from '../actions';

import AppBar from '../components/AppBar';
import StudentProfile from '../components/Display/StudentProfile';

const Student = (props) => {
  const { student } = props;
  return (
    <>
      <AppBar>
        <Container maxWidth={false}>
          <StudentProfile student={student} />
        </Container>
      </AppBar>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    student: state.user.student,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Student));
