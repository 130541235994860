import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Actions from '../../actions';

const logout = (props) => {
  const logoutAction = () => {
    props.logout();
  };

  return (
    <>
      <Button variant="contained" onClick={logoutAction} endIcon={<ExitToAppIcon />}>
        Logout
      </Button>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(null, mapDispatchToProps)(logout);
