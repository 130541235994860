import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import IconButton from '@material-ui/core/IconButton';
// import InputLabel from '@material-ui/core/InputLabel';

import Actions from '../../actions';

import { TOKEN, ROLES } from '../../utils/constants';
import { userLoginValidationSchema, checkAndGetNumber, validateEmail } from '../../utils/util';

import Snack from '../Progress/Snack';
import AppName from '../App/AppName';
import Select from '../Select';
import InputField from '../InputField';
import Modal from '../Modal/Modal';
import OTP from './OTP';
import Loader from '../Progress/Loader';
import AppButton from '../App/AppButton';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: 350,
      display: 'flex',
      justifyContent: 'center',
      boxShadow: theme.boxShadow,
      padding: 30,
    },
    cardContent: {
      textAlign: 'center',
      minWidth: '100%',
    },
    formControl: {
      marginBottom: 15,
    },
    submit: {
      marginTop: 15,
      marginBottom: 30,
    },
    button: {
      background: theme.primaryColor,
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.primaryColor,
        color: '#fff',
      },
    },
    text: {
      textTransform: 'capitalize',
      marginTop: 15,
      fontWeight: 'bold',
    },
    otpContainer: {
      marginTop: 30,
      marginBottom: 30,
    },
    verify: {
      background: theme.secondaryColor,
      color: '#fff',
      fontWeight: 'bold',
      '&:hover': {
        background: theme.secondaryColor,
        color: '#fff',
      },
    },
  };
});

function Login(props) {
  const {
    auth, verifyOTP, location, verify, sendOTP,
  } = props;
  const urlParams = new URLSearchParams(location.search);
  const paramRole = urlParams.get('role') || '';
  // const roleString = paramRole ? `?role=${paramRole}` : '';
  const token = urlParams.get('token');
  const classes = useStyles();
  const [data, setData] = React.useState({
    field: '', email: '', phone: '', showPassword: false, role: ROLES.TEACHER,
  });
  const [snack, setSnack] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const verifyCode = (code) => {
    if (!code || code.length !== 4) {
      setSnack({
        open: true,
        severity: 'warning',
        message: 'Please enter valid code!',
      });
      return;
    }
    setLoading(true);
    setOpen(false);
    verifyOTP({ ...data, code: parseInt(code, 10) })
      .then(() => { setLoading(false); })
      .catch((err) => {
        setLoading(false);
        setOpen(true);
        setSnack({
          open: true,
          severity: 'error',
          message: `${err.message}` || 'Invalid OTP!',
        });
      });
  };

  const sendProfileToBackend = () => {
    userLoginValidationSchema.validate(data)
      .then(() => {
        const loginData = { ...data };
        const field = (data.field).trim();
        const isEmail = validateEmail(field);
        if (isEmail) {
          loginData.email = field;
          loginData.phone = '';
        } else {
          loginData.phone = checkAndGetNumber(field);
          loginData.email = '';
        }
        const newData = { ...data, ...loginData };
        setData(newData);
        setLoading(true);
        return sendOTP(newData)
          .then(() => {
            setLoading(false);
            setOpen(true);
          });
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          open: true,
          severity: 'error',
          message: error.message || 'Account does not exist! Please check data or do sign-up.',
        });
      });
  };

  const handleSnackClose = () => {
    setSnack({});
  };

  // const handleClickShowPassword = () => {
  //   setData((prevData) => { return { ...prevData, showPassword: !prevData.showPassword }; });
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const close = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (paramRole && Object.values(ROLES).includes(paramRole)) {
      setData({ ...data, role: paramRole });
    }
  }, [paramRole]);

  React.useEffect(() => {
    if (token) verify({ token });
  }, [token]);

  if ((!token && localStorage.getItem(TOKEN)) || (auth && auth.isAuthenticated)) {
    return (<Redirect to="/home" />);
  }

  return (
    <>
      <Loader loading={loading} />
      <Snack open={snack.open} handleClose={handleSnackClose} severity={snack.severity} message={snack.message} />
      <Modal open={open} handleClose={close} title={`${data.role} Verification`}>
        <OTP data={data} verify={verifyCode} />
      </Modal>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <div>
            <AppName justifyContent="center" />
          </div>
          <Typography variant="subtitle1" className={classes.text}>
            Login
          </Typography>
          <Select
            title="Role"
            data={Object.values([ROLES.TEACHER])}
            name="role"
            value={data.role}
            handleChange={handleChange}
            isArray
            isCapitalize
          />
          <InputField
            name="field"
            title="Phone / Email"
            type="text"
            value={data.field}
            handleChange={handleChange}
          />
          {/* <FormControl fullWidth className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              type={data.showPassword ? 'text' : 'password'}
              value={data.password}
              onChange={handleChange}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {data.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            )}
              labelWidth={70}
            />
          </FormControl> */}
          <Grid container className={classes.submit} justifyContent="center">
            <AppButton
              onClick={sendProfileToBackend}
            >
              Login
            </AppButton>
          </Grid>
          {/* <Grid container justifyContent="center" alignItems="center" className={classes.formControl}>
            <Typography>Do not have an account?&nbsp;</Typography>
            <Typography><Link href={`/dashboard/sign-up${roleString}`}>Sign Up</Link></Typography>
          </Grid> */}
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="caption">
              <i>By continuing you will receive a one-time verification code by SMS or EMAIL.</i>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    loading: state.auth.loading,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
