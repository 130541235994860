import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => {
  return {
    formControl: {
      minWidth: 120,
      marginTop: 8,
      marginBottom: 8,
    },
    select: {
      background: '#fff',
      textAlign: 'left',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    default: {},
  };
});

export default function SimpleSelect(props) {
  const {
    name, value, handleChange, data, title, selectValue, selectName, isArray, disabled, handleBlur, noDefault,
    headTitle, editItem, showEditIcon, dataTour, isCapitalize, prefix,
  } = props;
  const classes = useStyles();

  const _id = selectValue || '_id';
  const displayName = selectName || 'name';

  const onBlur = () => {
    if (handleBlur) handleBlur();
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined" className={classes.formControl} data-tour={dataTour}>
        <InputLabel id="select-outlined-label">{headTitle || `Select ${title}`}</InputLabel>
        <Select
          labelId="select-outlined-label"
          id="select-outlined"
          name={name}
          value={value}
          onChange={handleChange}
          label={headTitle || `Select ${title}`}
          disabled={disabled || false}
          className={isCapitalize ? [classes.select, classes.capitalize].join(' ') : classes.select}
          onBlur={onBlur}
        >
          {
            noDefault ? null
              : (
                <MenuItem value="">
                  <em>{`Select ${title}`}</em>
                </MenuItem>
              )
          }
          {
            isArray ? data && data.map((item) => {
              return (
                <MenuItem
                  key={item}
                  value={item}
                  className={isCapitalize ? classes.capitalize : classes.default}
                >
                  {prefix ? `${prefix} ${item}` : item}
                </MenuItem>
              );
            })
              : data && data.map((item) => {
                return (
                  <MenuItem key={item[_id]} value={item[_id]}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        {item[displayName]}
                      </Grid>
                      <Grid item>
                        { showEditIcon ? (
                          <IconButton size="small" onClick={() => { editItem(item); }}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        ) : null }
                      </Grid>
                    </Grid>
                  </MenuItem>
                );
              })
          }
        </Select>
      </FormControl>
    </div>
  );
}
