import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import Edit from '@material-ui/icons/Edit';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

import Actions from '../../actions';
import { groupBy, ALL } from '../../utils/util';

import TopicAccordion from '../App/TopicAccordion';
import Filter from '../App/Filter';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginBottom: 30,
      width: '100%',
      boxShadow: theme.boxShadow,
      padding: '10px 15px',
      color: '#333',
      fontSize: 16,
    },
    subtitle: {
      fontWeight: 'bold',
    },
    titleIcon: {
      color: theme.primaryColor,
      marginRight: 8,
    },
  };
});

const MyContent = (props) => {
  const {
    contents, loading, users, standard, getAllTopics, setSnack,
  } = props;
  const classes = useStyles();

  const [editData, setEditData] = React.useState(null);
  const [groupedTopics, setGroupedTopics] = React.useState({});
  const [standards, setStandards] = React.useState([]);
  const [filterStandard, setFilterStandard] = React.useState(localStorage.getItem('filterStandard') || ALL);
  const [filterSubject, setFilterSubject] = React.useState(ALL);

  const getGroupedTopics = (topicsData = []) => {
    return groupBy(topicsData, (obj) => {
      return `${obj.standard && obj.standard.name} (${obj.subjectName}) Content`;
    });
  };

  const handleEdit = (topic) => {
    if (!topic) return;
    const data = {
      standard: topic.standard._id,
      standardName: topic.standard.name,
      subjectName: topic.subjectName,
    };
    setEditData(data);
  };

  React.useEffect(() => {
    getAllTopics({ users, standard });
  }, []);

  React.useEffect(() => {
    if (contents) {
      const unique = {};
      const uniqueStandards = [];
      contents.forEach((topic) => {
        const standardId = topic.standard?._id;
        if (!unique[standardId]) {
          unique[standardId] = standardId;
          uniqueStandards.push(topic.standard);
        }
      });
      setStandards(uniqueStandards);
    }
  }, [contents]);

  React.useEffect(() => {
    if (contents) {
      if (filterStandard && filterStandard !== ALL) {
        const filterTopics = contents.filter((topic) => {
          return (topic.standard?._id === filterStandard
            && (!filterSubject || filterSubject === ALL || topic.subjectName === filterSubject));
        });
        setGroupedTopics(getGroupedTopics(filterTopics));
      } else {
        setGroupedTopics(getGroupedTopics(contents));
      }
    }
  }, [filterStandard, filterSubject, contents]);

  if (editData) {
    return (
      <Redirect push to={{ pathname: '/add-content', state: { topic: editData } }} />
    );
  }

  return (
    <>
      <Filter
        standard={filterStandard}
        setStandard={setFilterStandard}
        subjectName={filterSubject}
        setSubjectName={setFilterSubject}
        standards={standards}
      />
      {
          loading ? (
            <Grid container justifyContent="center" alignItems="center" className={classes.loadingContainer}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid>
              {
                  !contents || Object.keys(groupedTopics).length === 0
                    ? (
                      <Paper elevation={0} className={classes.title}>
                        <Grid container justifyContent="center" alignItems="center" className={classes.noque}>
                          <Typography>No content added yet. Please add study materials content.</Typography>
                        </Grid>
                      </Paper>
                    )
                    : null
                }
              {
                contents && Object.keys(groupedTopics).map((title) => {
                  const topics = groupBy(groupedTopics[title], (doc) => {
                    return (doc.chapter && doc.chapter.name) || doc.chapterName;
                  });
                  return (
                    <Grid key={title}>
                      <Paper elevation={0} className={classes.title}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid container item xs={10}>
                            <Grid item>
                              <DynamicFeedIcon className={classes.titleIcon} />
                            </Grid>
                            <Grid item xs>
                              <Typography variant="subtitle1" className={classes.subtitle}>{title}</Typography>
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} justifyContent="flex-end">
                            <IconButton size="small" onClick={() => { handleEdit(topics && Object.values(topics)[0][0]); }}>
                              <Edit fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Paper>
                      <TopicAccordion topics={topics} isDisabledEdit setSnackData={setSnack} />
                    </Grid>
                  );
                })
                }
            </Grid>
          )
      }
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => {
  return {
    contents: state.content.contents,
    loading: state.content.fetching,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyContent));
