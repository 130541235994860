/* eslint-disable operator-linebreak */
/* eslint-disable curly */
/* eslint-disable max-len */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable comma-dangle */
import * as Yup from "yup";

import { WEEK_DAYS_MAPPINGS, WEEK_DAYS_INTEGER_MAPPINGS } from "./constants";

export const ALL = "All";

export const FULL_TEST = "Full Test";

export const currencyFormat = (amount, currency) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    maximumSignificantDigits: 3,
  }).format(amount);
};

export const paperValidationSchema = Yup.object().shape({
  standard: Yup.string("Standard must be a String").required(
    "Standard is required"
  ),
  // subject: Yup.string('Subject must be a String').required('Subject is required'),
  subjectName: Yup.string("Subject Name must be a String").required(
    "Subject Name is required"
  ),
  standardName: Yup.string("Standard Name must be a String").required(
    "Standard Name is required"
  ),
  year: Yup.number("Year must be a String").required("Year is required"),
  name: Yup.string("Exam Name must be a String").required(
    "Exam Name is required"
  ),
  duration: Yup.number("Duration must be a Number").required(
    "Duration is required"
  ),
  type: Yup.string("Paper type must be a String").required(
    "Paper type is required"
  ),
  marksMapping: Yup.object().required("Marks mapping is required"),
});

export const questionValidationSchema = Yup.object().shape({
  question: Yup.string("Question must be a String").required(
    "Question is required"
  ),
  standard: Yup.string("Standard must be a String").required(
    "Standard is required"
  ),
  // subject: Yup.string('Subject must be a String').required('Subject is required'),
  subjectName: Yup.string("Subject Name must be a String").required(
    "Subject Name is required"
  ),
  standardName: Yup.string("Standard Name must be a String").required(
    "Standard Name is required"
  ),
  questionType: Yup.string("Question Type must be a String").required(
    "Question Type is required"
  ),
  year: Yup.number("Year must be a String").required("Year is required"),
  paperId: Yup.string("Paper Id must be a String").required(
    "Paper Id is required"
  ),
});

export const userSignupValidationSchema = Yup.object().shape({
  name: Yup.string("Name must be a String").required("Name is required!"),
  phone: Yup.string("Phone Number must be a String")
    .length(13, "Phone has to be exactly 10 characters!")
    .required("Phone Number is required!"),
  // password: Yup.string('Password must be a String').required('Password is required!'),
  role: Yup.string("Role must be a String").required("Role is required!"),
  batchId: Yup.number().when("isStudentInvite", {
    is: true,
    then: Yup.number().min(1).required("Please select batch."),
    otherwise: Yup.number(),
  }),
  year: Yup.number().when("isStudentInvite", {
    is: true,
    then: Yup.number().min(1).required("Please select batch year."),
    otherwise: Yup.number(),
  }),
  email: Yup.string()
    .email()
    .when("isInvite", {
      is: true,
      then: Yup.string("Email must be String").nullable().email(),
      otherwise: Yup.string().email().required("Email address is required!"),
    }),
});

export const userLoginValidationSchema = Yup.object().shape({
  field: Yup.string("Email/phone must be a String").required(
    "Email/phone is required!"
  ),
  role: Yup.string("Role must be a String").required("Role is required!"),
  // password: Yup.string('Password must be a String').required('Password is required!'),
});

export const topicValidationSchema = Yup.object().shape({
  name: Yup.string().required("Title is required!"),
  chapterName: Yup.string().required("Chapter Name is required!"),
  standardName: Yup.string().required("Standard Name is required!"),
  subjectName: Yup.string().required("Subject Name is required!"),
  type: Yup.string().required("Content type is required!"),
  order: Yup.string().required("Content order is required!"),
});

export const contentValidationSchema = Yup.object().shape({
  standardName: Yup.string().required("Standard Name is required!"),
  subjectName: Yup.string().required("Subject Name is required!"),
});

export const meetingValidationSchema = Yup.object().shape({
  name: Yup.string("Title must be String").required(
    "Meeting title is required"
  ),
  startTime: Yup.date().required("Start time is required"),
  standardName: Yup.string("Standard must be a String").required(
    "Standard is required"
  ),
  endTime: Yup.date().required("End time is required"),
  isRepeat: Yup.boolean(),
  days: Yup.array().when("isRepeat", {
    is: true,
    then: Yup.array()
      .min(1)
      .required("Please atleast one weekDay or uncheck isRepeat."),
    otherwise: Yup.array(),
  }),
});

export const insertAt = (str = "", sub, pos) => {
  return `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
};

export const getPosition = (str = "", pos) => {
  let realPos = 0;
  let imgPos = 0;
  let isFound = false;
  let count = 0;
  let char = "";
  for (let i = 0; i < str.length; i += 1) {
    if (!isFound && str[i] === "&") {
      imgPos += 1;
      isFound = true;
      count += 1;
      char = "&";
    }
    if (imgPos >= pos && !isFound) break;
    if (isFound && str[i] === "<") {
      imgPos += count;
      if (imgPos >= pos) {
        realPos += imgPos - pos;
        break;
      }
    }
    realPos += 1;
    if (str[i] === "<") {
      isFound = true;
      count += 1;
    }
    if (!isFound) {
      imgPos += 1;
    }
    if (isFound && (str[i] === ">" || (char === "&" && str[i] === ";"))) {
      count = 0;
      isFound = false;
      char = "";
    }
  }
  return realPos;
};

export const getCombineValue = (preValue, value, position) => {
  let close = false;
  if (preValue && preValue.endsWith("<br></div>")) {
    const lastIndex = preValue.lastIndexOf("<br></div>");
    preValue.slice(0, lastIndex);
    // eslint-disable-next-line no-param-reassign
    preValue = preValue.slice(0, lastIndex);
    close = true;
  } else if (preValue && preValue.endsWith("</div>")) {
    const lastIndex = preValue.lastIndexOf("</div>");
    preValue.slice(0, lastIndex);
    // eslint-disable-next-line no-param-reassign
    preValue = preValue.slice(0, lastIndex);
    close = true;
  }
  const pos = getPosition(preValue, position && position.start);
  const data = insertAt(preValue, value, pos);
  return `${data}${close ? "</div>" : ""}`;
};

export const checkAndGetNumber = (number) => {
  if (!number) return number;
  let newNumber = String(number);
  if (newNumber.startsWith("0")) newNumber = newNumber.slice(1);
  if (newNumber.length > 10 && !newNumber.startsWith("+"))
    return `+${newNumber}`;
  if (newNumber.length === 10) return `+91${newNumber}`;
  return newNumber;
};

export function validateEmail(email) {
  // eslint-disable-next-line max-len
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function capitalize(word) {
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

const getDayStr = (day) => {
  return WEEK_DAYS_MAPPINGS[day];
};

export const getFrequencyText = (weekDayIntegers = [], startTime) => {
  if (!weekDayIntegers || weekDayIntegers.length === 0) {
    return new Date(startTime).toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    });
  }
  // eslint-disable-next-line no-param-reassign
  weekDayIntegers = weekDayIntegers.sort();
  let continueos = true;
  let currentDay = weekDayIntegers[0];
  const firstDay = WEEK_DAYS_INTEGER_MAPPINGS[currentDay];
  let dayString = capitalize(firstDay);
  for (let i = 1; i < weekDayIntegers.length; i += 1) {
    const nextDay = weekDayIntegers[i];
    if (continueos && currentDay + 1 !== nextDay) {
      continueos = false;
    }
    dayString += `, ${capitalize(WEEK_DAYS_INTEGER_MAPPINGS[nextDay])}`;
    currentDay = nextDay;
  }
  if (weekDayIntegers.length === 1) return getDayStr(firstDay);
  if (weekDayIntegers.length === 7 && continueos) return "Everyday";
  if (continueos)
    return `${getDayStr(firstDay)} - ${getDayStr(
      WEEK_DAYS_INTEGER_MAPPINGS[currentDay]
    )}`;
  return dayString;
};

export const getYears = (number = 5) => {
  const years = [];
  const year = new Date().getFullYear();
  for (let i = -1; i < number - 1; i += 1) {
    years.push(year - i);
  }
  return years;
};

export const getDays = (number = 180) => {
  const days = [];
  for (let i = 1; i <= number; i += 1) {
    days.push(i);
  }
  return days;
};

export const splitCamelCase = (str = "") => {
  return str.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
};

const compareDay = (date) => {
  const day = new Date(date).getDay();
  const currentDay = new Date().getDay();
  return day === currentDay;
};

export const filterMeeting = (meetings = []) => {
  const filteredMeetings = meetings.filter((obj) => {
    if (!obj.isRepeat) {
      return compareDay(obj.startTime);
    }
    let isToday = false;
    obj.days.forEach((date) => {
      isToday = isToday || compareDay(date);
    });
    return isToday;
  });

  return filteredMeetings;
};

export const capitalizeFirstWord = (str = "") => {
  return str && str[0].toUpperCase() + str.slice(1);
};

export const groupBy = (objects, getKey) => {
  const response = {};
  objects.forEach((obj) => {
    if (response[getKey(obj)]) response[getKey(obj)].push(obj);
    else response[getKey(obj)] = [obj];
  });
  return response;
};
