import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Options from '../Display/Options';
import Html from '../Display/Html';

const Accordion = withStyles({
  root: {
    border: '0px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    minHeight: 16,
    '&$expanded': {
      minHeight: 16,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => {
  return {
    root: {
      // background: 'red',
      paddingLeft: 36,
    },
  };
})(MuiAccordionDetails);

const useStyles = makeStyles(() => {
  return {
    container: {
      width: '100%',
    },
    checkBoxContainer: {
      paddingTop: 3,
    },
  };
});

const QuestionAccordion = (props) => {
  const {
    questions, addOrRemoveQuestion, newQuetions,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.container}>
        {
          questions.map((question, index) => {
            const questionId = question._id;
            return (
              <Grid key={questionId} container alignItems="flex-start">
                <Grid item className={classes.checkBoxContainer}>
                  <Checkbox
                    checked={newQuetions.includes(questionId)}
                    onChange={() => { return addOrRemoveQuestion(questionId); }}
                  />
                </Grid>
                <Grid item xs>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container spacing={1}>
                        <Grid item>
                          <Typography className={classes.title}>
                            {index + 1}
                            .
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Html html={question.question} />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Options question={question} noDivider />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            );
          })
        }
      </Grid>
    </>
  );
};

export default QuestionAccordion;
